import * as React from 'react';
import { Snackbar as MUISnackbar } from '@material-ui/core';
import { Alert, Color } from '@material-ui/lab';
import { UIContext } from '../../contexts/UI';

export interface SnackbarOptions {
  message: string;
  type: Color;
}

export interface SnackbarState {
  open: boolean;
  options?: SnackbarOptions;
}

const Snackbar: React.FunctionComponent = () => {
  const { snackbar } = React.useContext(UIContext);

  return (
    <MUISnackbar
      open={snackbar.open}
      autoHideDuration={5000}
      onClose={snackbar.close}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert severity={snackbar.options?.type} elevation={6} variant="filled">
        {snackbar.options?.message}
      </Alert>
    </MUISnackbar>
  );
};

export default Snackbar;
