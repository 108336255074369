import React from 'react'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import styled from 'styled-components'
import get from 'lodash/get'

import { AddModification } from './types'

const StyledAddedRow = styled(TableRow)`
  background-color: #dff3df;
  .added-row {
    &__action {
      font-weight: 600;
    }
  }
`

interface AddedRowProps extends AddModification {
  properties: string[]
  transformDisplay?: (value: any) => any
}

export const AddedRow: React.FunctionComponent<AddedRowProps> = ({
  properties,
  newData,
  transformDisplay = (value) => value,
}) => {
  const displayNewData = transformDisplay(newData)
  return (
    <StyledAddedRow key={newData.id}>
      <TableCell className="added-row__action">Added</TableCell>
      {properties.map((property) => (
        <TableCell key={property}>{get(displayNewData, property)}</TableCell>
      ))}
    </StyledAddedRow>
  )
}
