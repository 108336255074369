import React, { useContext, useState } from 'react';
import firebase from 'firebase';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import Modal from '@material-ui/core/Modal';
import round from 'lodash/round';
import {
  DashboardPricing,
  Manufacturer,
  DashboardProduct,
} from '@tiary-inc/tiary-shared';

import { getPricingForProduct } from '../../utils/getPricingForProduct';
import { LocaleContext } from '../../contexts/Locale';
import { PricingContext } from '../../contexts/Pricing';
import { MaterialsContext } from '../../contexts/Materials';
import { getConsumerProduct } from '../../utils/getConsumerProduct';
import { Box, Typography, LinearProgress } from '@material-ui/core';

export const StyledProductBulkUpdateModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;

  .bulk-update-modal {
    &__wrapper {
      max-width: 70%;
      width: 600px;
      padding: 48px;
      background: white;
    }
    &__log {
      margin-top: 44px;
      height: 300px;
      overflow: auto;
      background: #efefef;
      font-family: monospace;
      font-size: 14px;
      padding: 12px;
    }
  }
`;

interface ProductBulkUpdateModalProps {
  onClose: () => void;
}

export const ProductBulkUpdateModal: React.FunctionComponent<ProductBulkUpdateModalProps> = ({
  onClose,
}) => {
  const [deployProgress, setDeployProgress] = useState<number>();
  const [deployLog, setDeployLog] = useState<string[]>([]);
  const { materials = [], groupings = [] } = useContext(MaterialsContext);
  const { localize } = useContext(LocaleContext);
  const { pricing } = useContext(PricingContext);
  const bag = { materials, groupings, localize, pricing };

  const bulkUpdateProducts = async () => {
    setDeployProgress(0);

    let nextDeployLog: string[] = [];
    setDeployLog(nextDeployLog);

    const dashboardRef = firebase.firestore().collection('dashboard');
    const productsRef = dashboardRef.doc('data').collection('products');
    const manufacturersRef = dashboardRef
      .doc('data')
      .collection('manufacturers');

    const { manufacturerOfReference } = (
      await dashboardRef.doc('pricing').get()
    ).data() as DashboardPricing;
    const manufacturer = (
      await manufacturersRef.doc(manufacturerOfReference).get()
    ).data() as Manufacturer;

    const allErrors: string[] = [];
    const dashboardProductQuery = await productsRef.get();
    for (let index = 0; index < dashboardProductQuery.size; index++) {
      const doc = dashboardProductQuery.docs[index];
      const progress = (index / dashboardProductQuery.size) * 100;
      setDeployProgress(progress);
      nextDeployLog = [`Deploying Product: ${doc.id}`, ...nextDeployLog];
      setDeployLog(nextDeployLog);
      try {
        const product = { ...doc.data(), id: doc.id } as DashboardProduct;
        const [basePrice, pricing] = getPricingForProduct(
          { product, manufacturer },
          bag
        );
        await productsRef.doc(doc.id).update({ basePrice, pricing });
        const consumerProduct = getConsumerProduct({
          ...(doc.data() as DashboardProduct),
          basePrice,
          pricing,
        });
        await firebase
          .firestore()
          .collection('products')
          .doc(product.id)
          .set(consumerProduct, { merge: true });
        await productsRef
          .doc(doc.id)
          .update({ lastDeployed: firebase.firestore.Timestamp.now() });
      } catch (error) {
        nextDeployLog = [error.message, ...nextDeployLog];
        setDeployLog(nextDeployLog);
        console.error(`Error on ${doc.id}: ${error}`);
        allErrors.push(`${doc.id}: ${error.message}`);
      }
    }
    console.log(allErrors.join(',\n'));
    setDeployProgress(100);
  };

  return (
    <StyledProductBulkUpdateModal open onClose={onClose}>
      <div className="bulk-update-modal__wrapper">
        <Button onClick={bulkUpdateProducts}>Bulk Update</Button>
        {!!deployProgress && (
          <Box paddingTop={2}>
            <Typography gutterBottom>
              {round(deployProgress)}% deployed
            </Typography>
            <LinearProgress variant="determinate" value={deployProgress} />
          </Box>
        )}
        <Box paddingTop={2} className="bulk-update-modal__log">
          {deployLog.map((message, index) => (
            <div key={`${message}-${index}`}>{message}</div>
          ))}
        </Box>
      </div>
    </StyledProductBulkUpdateModal>
  );
};
