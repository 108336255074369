import React, { FunctionComponent } from 'react'

import { EditorFormField, getFormField } from './getFormField'

interface EditorFormFieldsProps {
  formSchema: EditorFormField[]
}

export const EditorFormFields: FunctionComponent<EditorFormFieldsProps> = ({
  formSchema,
}) => <>{formSchema.map(getFormField)}</>
