import get from 'lodash/get'
import {
  Manufacturer,
  MaterialType,
  ChainMaterial,
  FindingMaterial,
  DashboardUtilsBag,
} from '@tiary-inc/tiary-shared'

const getMaterialCost = (id: string, manufacturer: Manufacturer) => {
  const value = get(manufacturer, `costs.materials.${id}`)
  return typeof value === 'number' ? value : undefined
}

export const getMissingChainValues = (
  { manufacturer }: { manufacturer: Manufacturer },
  { materials }: DashboardUtilsBag,
) => {
  const chainMaterials = materials.filter(
    ({ material }) => material === MaterialType.Chain,
  ) as ChainMaterial[]
  return chainMaterials.filter(
    ({ id }) => getMaterialCost(id, manufacturer) === undefined,
  )
}

export const getMissingFindingValues = (
  manufacturer: Manufacturer,
  { materials }: DashboardUtilsBag,
) => {
  const findingMaterials = materials.filter(
    ({ material }) => material === MaterialType.Finding,
  ) as FindingMaterial[]
  return findingMaterials.filter(
    ({ id }) => getMaterialCost(id, manufacturer) === undefined,
  )
}

export const validateManufacturer = (manufacturer: Manufacturer) => {}
