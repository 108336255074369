import React from 'react'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import styled from 'styled-components'
import get from 'lodash/get'

import { RemoveModification } from './types'

const StyledRemovedRow = styled(TableRow)`
  background-color: #f3dfdf;
  .removed-row {
    &__action {
      font-weight: 600;
    }
  }
`

interface RemovedRowProps extends RemoveModification {
  properties: string[]
  transformDisplay?: (value: any) => any
}

export const RemovedRow: React.FunctionComponent<RemovedRowProps> = ({
  properties,
  oldData,
  transformDisplay = (value) => value,
}) => {
  const displayOldData = transformDisplay(oldData)
  return (
    <StyledRemovedRow key={oldData?.id}>
      <TableCell className="removed-row__action">Removed</TableCell>
      {properties.map((property) => (
        <TableCell key={property}>{get(displayOldData, property)}</TableCell>
      ))}
    </StyledRemovedRow>
  )
}
