import React from 'react'
import { Field, FieldProps } from 'formik'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import {
  FormControlProps,
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
} from '@material-ui/core'
import firebase from 'firebase/app'
import DateFnsUtils from '@date-io/date-fns'
import styled from 'styled-components'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

interface FormikDateTimeProps {
  name: string
  label?: string
  showTime?: boolean
  dateRequired?: boolean
}

const StyledFormikDateTime = styled.div`
  .formik-date-time {
    &__control {
      display: flex;
      flex-direction: row;
    }
    &__date-input,
    &__time-input {
      flex: 1;
    }
    &__time-input {
      margin-left: 16px;
    }
  }
`

export const FormikDateTime: React.FunctionComponent<
  FormikDateTimeProps & FormControlProps
> = ({ name, label, className, showTime, dateRequired = false, ...rest }) => (
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <StyledFormikDateTime className={className}>
      <Field name={name}>
        {({ form, field }: FieldProps) => {
          const handleChange = (value: MaterialUiPickersDate) => {
            if (!value) {
              return
            }
            const timestamp = firebase.firestore.Timestamp.fromDate(value)
            form.setFieldValue(name, timestamp)
          }
          let dateValue = field.value && field.value.toDate()
          if (!dateValue && dateRequired) {
            form.setFieldValue(name, firebase.firestore.Timestamp.now())
          }
          // if theres a date, convert it to date format, and display it. if there is no date (ex new sale), an activated switch will add the current date as the date value.
          // add a parameter here like dateRequired = true or false as a prop
          return (
            <>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={!!form.values[name]}
                      onChange={(e) =>
                        form.setFieldValue(
                          name,
                          e.target.checked
                            ? firebase.firestore.Timestamp.now()
                            : undefined,
                        )
                      }
                    />
                  }
                  label={label}
                />
              </FormGroup>
              {dateValue && (
                <FormControl {...rest} className="formik-date-time__control">
                  <KeyboardDatePicker
                    className="formik-date-time__date-input"
                    margin="normal"
                    label={label}
                    format="MM/dd/yyyy"
                    value={dateValue}
                    onChange={handleChange || null}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                  {showTime && (
                    <KeyboardTimePicker
                      className="formik-date-time__time-input"
                      margin="normal"
                      label={`${label} - Time`}
                      value={dateValue}
                      onChange={handleChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change time',
                      }}
                    />
                  )}
                </FormControl>
              )}
            </>
          )
        }}
      </Field>
    </StyledFormikDateTime>
  </MuiPickersUtilsProvider>
)
