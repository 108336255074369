import {
    EditorFormFieldType,
} from '../../components/EditorForm/Fields/getFormField';
import { GoldmanPricingForm } from '../../../components/Product/forms/Pricing/GoldmanPricing/GoldmanPricingForm';
import { ProductRendersForm } from '../../../components/Product/forms/Renders';

export const GoldmanSchema = (product:string)=>{
    return(
        [
            {
              type: EditorFormFieldType.Heading,
              label: 'Retail Pricing',
            },
            {
              type: EditorFormFieldType.FieldComponent,
              FieldComponent: GoldmanPricingForm,
              name: 'pricing',
            },
           
            { type: EditorFormFieldType.Divider },
            {
              type: EditorFormFieldType.Heading,
              label: 'Renders',
              description:
                'Upload renders below for processing. Renders will be automatically recognized by the standard Tiary naming convention.',
            },
            {
              type: EditorFormFieldType.FieldComponent,
              FieldComponent: ProductRendersForm,
              name: 'renders',
              sku: product,
            }
          ]
        
    )
}
  