import React, { useContext } from 'react';
import get from 'lodash/get';
import find from 'lodash/find';
import isMatch from 'lodash/isMatch';
import filter from 'lodash/filter';
import styled from 'styled-components';
import { Field, FieldProps } from 'formik';
import { StoneMaterial } from '@tiary-inc/tiary-shared';

import { MaterialsContext } from '../../../../contexts/Materials';
import { ProductStoneTypesForm } from './StoneTypes';
import Loading from '../../../../lib/components/Loading';
import { ProductStoneSizesForm } from './StoneSizes';

interface ProductStoneValuesFormProps {
  name: string;
}

const StyledProductStoneValuesForm = styled.div`
  .product-stone-values-form {
    &__label {
      font-size: 16px;
      margin-top: 44px;
      text-transform: uppercase;
      letter-spacing: 0.6px;
    }
  }
`;

// The goal of this component is to allow managers to safely select stone
// type and size combinations in a way that: makes sense to them, can be done
// safely, and can be expanded to a set of value IDs.

// Some of this may seem convoluted, but it's important to consider that the
// platform goal is to move this type of complexity to this very tool rather
// than have all of these assumptions live in the data model.

// THE STONE TYPE
// --------------

// Here we'll introduce a stone "type" which is a triad of quality, color, and
// stone. For example, Genuine White Diamond.

export interface StoneType {
  stone: string;
  color?: string;
  quality: string;
}

export interface StoneSize {
  id: string;
  quantity: number;
  size: string;
  setting: string;
}

// The type can be hashed into a simple string for easy comparrison.

export const ProductStoneValuesForm: React.FunctionComponent<ProductStoneValuesFormProps> = ({
  name,
}) => {
  const { stoneMaterials, stoneGroupings, getMaterialsForGroup } = useContext(
    MaterialsContext
  );

  const getMaterialsForTypes = (types: StoneType[]) =>
    filter(stoneMaterials, (m) => !!find(types, (t) => isMatch(m, t)));

  return stoneMaterials && stoneGroupings ? (
    <Field name={name} key={name}>
      {({ form }: FieldProps) => {
        const types: string | StoneType[] =
          get(form.values, `${name}.types`) || stoneGroupings[0].id;

        const onChangeTypes = (nextTypes: string | StoneType[]) =>
          form.setFieldValue(`${name}.types`, nextTypes);

        // get all of the materials that match the "type" values
        const typeMaterials =
          typeof types === 'string'
            ? (getMaterialsForGroup(types) as StoneMaterial[])
            : getMaterialsForTypes(types as StoneType[]);

        const sizes: StoneSize[] = get(form.values, `${name}.sizes`, []);
        const onChangeSizes = (nextSizes: StoneSize[]) =>
          form.setFieldValue(`${name}.sizes`, nextSizes);

        return (
          <StyledProductStoneValuesForm>
            <h2 className="product-stone-values-form__label">Types</h2>
            <ProductStoneTypesForm value={types} onChange={onChangeTypes} />
            <h2 className="product-stone-values-form__label">Sizes</h2>
            <ProductStoneSizesForm
              value={sizes}
              onChange={onChangeSizes}
              materials={typeMaterials}
            />
          </StyledProductStoneValuesForm>
        );
      }}
    </Field>
  ) : (
    <Loading />
  );
};
