import React, { FunctionComponent, useContext } from 'react'
import InputAdornment from '@material-ui/core/InputAdornment'

import { ChainMaterial } from '@tiary-inc/tiary-shared'
import { FormikInput } from '../../../../lib/components/FormikInput'
import { LocaleContext } from '../../../../contexts/Locale'
import { StyledManufacturerChainsModal } from './style'

interface ManufacturerChainsModalProps {
  name: string
  onClose: () => void
  values?: ChainMaterial[]
}

export const ManufacturerChainsModal: FunctionComponent<ManufacturerChainsModalProps> = ({
  name,
  onClose,
  values = [],
}) => {
  const { localize } = useContext(LocaleContext)
  return (
    <StyledManufacturerChainsModal open onClose={onClose}>
      <div className="chain-cost-modal__wrapper">
        {values.map(({ id, quality, metal }) => (
          <FormikInput
            className="editor-form__input"
            key={id}
            name={[name, id].join('.')}
            label={`${localize(quality)} ${localize(metal)}`}
            type="number"
            helperText={`The cost of this chain in ${localize(
              quality,
            )} ${localize(metal)}.`}
            fullWidth
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            endAdornment={<InputAdornment position="end">USD</InputAdornment>}
          />
        ))}
      </div>
    </StyledManufacturerChainsModal>
  )
}
