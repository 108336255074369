import React from 'react'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'

interface ManufacturerChainRowProps {
  label: string
  missingCount: number
  onEdit: () => void
}

export const ManufacturerChainRow: React.FunctionComponent<ManufacturerChainRowProps> = ({
  label,
  missingCount,
  onEdit,
}) => {
  const descriptor = missingCount === 1 ? 'value' : 'values'
  const missingLabel =
    missingCount > 0 && `missing ${missingCount} ${descriptor}`
  return (
    <div className="manufacturer-chains__chain-length">
      <div className="manufacturer-chains__chain-length__label">
        <strong>{label}</strong>
      </div>
      {missingLabel && (
        <Chip
          color="secondary"
          size="small"
          variant="outlined"
          label={missingLabel}
        />
      )}
      <Button color="primary" size="small" onClick={onEdit}>
        Edit
      </Button>
    </div>
  )
}
