import React from 'react';
import firebase from 'firebase';
import sortBy from 'lodash/sortBy';

import { Order } from '@tiary-inc/tiary-shared';
import { saveAs } from 'file-saver';
import { useAuthedCollection } from '@humancollective/human-hooks-firebase';
import { Button } from '@material-ui/core';
import { csvFromOrders } from './transform/order';

export const DownloadOrdersButton: React.FunctionComponent = () => {
  const orders: Order[] = useAuthedCollection({
    getQueryRef: () => firebase.firestore().collection('orders'),
    includeIds: true,
  });

  const saveOrders = () => {
    // Sort the orders by date
    const dateSortedOrders = sortBy(orders, (tempOrder) =>
      tempOrder.dateCreated.toDate()
    );
    // reverse the orders so the newest ones are first
    dateSortedOrders.reverse();
    console.log(dateSortedOrders);
    const csv = csvFromOrders(dateSortedOrders);
    const ordersBlob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    saveAs(ordersBlob, 'orders.csv');
  };

  return <Button onClick={saveOrders}>Export CSV</Button>;
};
