import * as React from 'react';
import { ProductRowView } from '../Product/RowView';
import { ProductInfo } from '../SalesSearch';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;

`

const SelectedProductsTitle = styled.div`
  height: 64px;
  & h3 {

  }
`

interface SelectedProductProps {
  selectedProducts: ProductInfo[];
  handleRemoveProduct: (sku: string) => void;
  updateSaleDetails: (sku: string, newSaleDetails: number) => void;
}

export const SelectedProducts: React.FC<SelectedProductProps> = ({selectedProducts, handleRemoveProduct, updateSaleDetails}) => {


  return(
    <Container>
      <SelectedProductsTitle>
        <h3>{selectedProducts.length === 0 ? '' 
          : `Total Selected: ${selectedProducts.length}`}
        </h3>
      </SelectedProductsTitle>
      {selectedProducts.map(item => {
        return (
          <ProductRowView
            key={item.sku}
            sku={item.sku}
            product={item.product}
            metadata={item.metadata}
            percentage={item.percentage}
            onSelect={()=>{}}
            isSales
            handleRemoveProduct={handleRemoveProduct}
            updateSaleDetails={updateSaleDetails}
          />
        )
      })}
    </Container>
  )

}