import find from 'lodash/find';
import uniq from 'lodash/uniq';
import {
  DashboardUtilsBag,
  TiaryMaterial,
  ChainMaterial,
} from '@tiary-inc/tiary-shared';

export const getGroupById = (id: string, { groupings }: DashboardUtilsBag) => {
  const group = find(groupings, { id });
  if (!group) {
    throw new Error(`group ${id} not found`);
  }
  return group;
};

export const expandOptionValues = (
  values: string | string[],
  bag: DashboardUtilsBag,
  transform: (material: TiaryMaterial) => any = (m) => m
) => {
  const materialIds =
    typeof values === 'string' ? getGroupById(values, bag).materials : values;
  return materialIds
    .map((id) => {
      const material = find(bag.materials, { id });
      if (!material) {
        throw new Error(`material ${id} not found`);
      }
      return material;
    })
    .map(transform);
};

export const expandChainLengths = (
  values: string | string[],
  bag: DashboardUtilsBag
): string[] => {
  const expandedLengths: string[] =
    typeof values === 'string'
      ? uniq(
          expandOptionValues(
            values,
            bag,
            (chain) => (chain as ChainMaterial).size
          )
        )
      : values;
  return expandedLengths;
};
