import React from 'react'
import parse from 'csv-parse/lib/sync'

import { UploadFile } from '../UploadFile'

const readFileAsText = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = e => {
      resolve((e.target?.result as string) || '')
    }
    reader.onerror = err => {
      reject(err)
    }
    reader.readAsText(file)
    return
  })

interface ImportCsvProps {
  buttonLabel?: string
  onSuccess: (values: any[]) => void
}

export const ImportCsv: React.FunctionComponent<ImportCsvProps> = ({
  buttonLabel,
  onSuccess,
}) => {
  const handleSelectFile = async (file?: File) => {
    if (!file) {
      throw new Error('no file selected')
    }
    const contents = await readFileAsText(file)
    const resultObj = parse(contents, {
      columns: true,
      skip_empty_lines: true,
      cast: true,
    })
    onSuccess(resultObj)
  }

  return (
    <UploadFile buttonLabel={buttonLabel} onSelectFile={handleSelectFile} />
  )
}
