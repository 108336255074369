import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Insight } from '@tiary-inc/tiary-shared';
import flattenDeep from 'lodash/flattenDeep';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import sortBy from 'lodash/sortBy';

interface PopularConfigurationsCardProps {
  insights: Insight[];
}

const useStyles = makeStyles((theme) => ({
  rootCard: {
    minWidth: 375,
  },
  cardContent: {
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
  },
  headingBox: {
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: 0,
  },
  chartContent: {
    height: 310,
    margin: theme.spacing(1),
  },
}));

type PopularConfigurationData = { name: string; amount: number };

const PopularConfigurationsCard: React.FunctionComponent<PopularConfigurationsCardProps> = ({
  insights,
}) => {
  const [popularMetals, setPopularMetals] = React.useState<
    PopularConfigurationData[]
  >([]);
  const [popularStones, setPopularStones] = React.useState<
    PopularConfigurationData[]
  >([]);
  const styles = useStyles();

  React.useEffect(() => {
    // get all the popular configs
    const allPopularConfigs = insights.map(
      (insight) => insight.data.products.popularConfigurations
    );

    const flatMetalConfigs = flattenDeep(
      allPopularConfigs.map((configInsight) => configInsight.metal)
    );
    const flatStoneConfigs = flattenDeep(
      allPopularConfigs.map((configInsight) => configInsight.stone)
    );

    const talliedPopularMetals: PopularConfigurationData[] = flatMetalConfigs.reduce<
      PopularConfigurationData[]
    >((prev, curr) => {
      // Check if the item exists already in the tally
      const existingData = prev.find((prev) => prev.name === curr.name);
      let previousValues = prev;

      // if it exists, update it
      if (existingData) {
        const popularMetalConfigsWithoutDuplicate = previousValues.filter(
          (tempPopularConfig) => tempPopularConfig.name !== existingData.name
        );

        previousValues = [
          ...popularMetalConfigsWithoutDuplicate,
          { name: curr.name, amount: existingData.amount + curr.amount },
        ];
      } else {
        // If it doesn't exist, add it
        previousValues.push({ name: curr.name, amount: curr.amount });
      }

      return previousValues;
    }, []);

    const talliedPopularStones: PopularConfigurationData[] = flatStoneConfigs.reduce<
      PopularConfigurationData[]
    >((prev, curr) => {
      // Check if the item exists already in the tally
      const existingData = prev.find((prev) => prev.name === curr.name);
      let previousValues = prev;

      // if it exists, update it
      if (existingData) {
        const popularStoneConfigsWithoutDuplicate = previousValues.filter(
          (tempPopularConfig) => tempPopularConfig.name !== existingData.name
        );

        previousValues = [
          ...popularStoneConfigsWithoutDuplicate,
          { name: curr.name, amount: existingData.amount + curr.amount },
        ];
      } else {
        // If it doesn't exist, add it
        previousValues.push({ name: curr.name, amount: curr.amount });
      }

      return previousValues;
    }, []);

    const sortedPopularStoneConfigs = sortBy(
      talliedPopularStones,
      'amount'
    ).reverse();
    const sortedPopularMetalConfigs = sortBy(
      talliedPopularMetals,
      'amount'
    ).reverse();

    setPopularStones(sortedPopularStoneConfigs);
    setPopularMetals(sortedPopularMetalConfigs);
  }, [insights]);

  return (
    <Card variant="outlined" className={styles.rootCard}>
      <CardContent className={styles.cardContent}>
        <Paper className={styles.headingBox}>
          <Typography variant="h6">Popular Configurations</Typography>
        </Paper>
        <List subheader={<ListSubheader>Stones</ListSubheader>}>
          {popularStones.slice(0, 7).map((popularStone) => (
            <ListItem key={popularStone.name}>
              <ListItemText
                primary={popularStone.name}
                secondary={`Quantity: ${popularStone.amount}`}
              />
            </ListItem>
          ))}
        </List>
        <List subheader={<ListSubheader>Metals</ListSubheader>}>
          {popularMetals.slice(0, 7).map((popularMetal) => (
            <ListItem key={popularMetal.name}>
              <ListItemText
                primary={popularMetal.name}
                secondary={`Quantity: ${popularMetal.amount}`}
              />
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default PopularConfigurationsCard;
