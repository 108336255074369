import React from 'react';
import firebase from 'firebase';
import { useParams } from 'react-router-dom';
import { FirestoreCollections } from '@tiary-inc/tiary-shared';

import { EditorFormFieldType } from '../../lib/components/EditorForm/Fields/getFormField';
import { UIContext } from '../../contexts/UI';
import EditorTemplate from '../../lib/templates/EditorTemplate';

import { ALL_TABS_LIST } from '..';
/**
 * Edit Dashboard User Page
 * ---
 *
 */

export const EditDashboardUserPage: React.FunctionComponent = () => {
  const { user } = useParams();
  const ui = React.useContext(UIContext);

  return (
    <EditorTemplate
      breadcrumbs={[{ label: 'Users', to: '/users' }, { label: 'Edit User' }]}
      onSubmitSuccess={() => {
        ui.snackbar.show({ message: 'User saved!', type: 'success' });
      }}
      getQueryRef={() =>
        firebase
          .firestore()
          .collection(FirestoreCollections.DASHBOARD)
          .doc('users')
          .collection('preferences')
          .doc(user)
      }
      formSchema={[
        {
          name: 'name',
          label: 'Name',
          type: EditorFormFieldType.Text,
        },
        {
          type: EditorFormFieldType.Heading,
          label: 'Select access',
          description: 'Choose which areas of the dashboard the user can view',
        },
        {
          name: 'tabs',
          label: 'tabs',
          type: EditorFormFieldType.SelectChip,

          options: ALL_TABS_LIST.map((tabName) => ({
            label: `${tabName.slice(0, 1).toUpperCase()}${tabName.slice(1)}`,
            value: tabName,
          })),
        },
      ]}
    />
  );
};
