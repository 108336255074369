import React, {useState,useCallback} from 'react'
import { useFormikContext } from 'formik';
import * as _ from 'lodash';
import EditableLevel from "./EditableLevel";
import './GoldmanPricingForm.css';

export const GoldmanPricingForm = () => {
  interface productValuesType {
    options:any[];
  }

  
  //let's try using formik context here, so the values over here have the actual product data structure that I am looking for.
  const { values } = useFormikContext();
  const [ currentProduct, setCurrentProduct ] = useState<any>(values)

  //ringSize 
  const generateRingSizeValues= useCallback(()=>currentProduct.pricing['RING_SIZE'].map(({price, type})=>{
        return({
            config_name:`${type}`,
            type:"RING_SIZE",
            component_price:price
        })
    }),[currentProduct]);
 
    //carat weight options
   const generateCaratValues = useCallback(()=>currentProduct.pricing['CARAT_WEIGHT'].map(({price,type})=>{
       return({
           config_name:`${type}`,
           type:"CARAT_WEIGHT",
           component_price:price,
           children:currentProduct.pricing['RING_SIZE'] ?
                    generateRingSizeValues()
                    :null
       })
   }),[currentProduct,generateRingSizeValues]);
 
   //metal options
   const generateMetalValues = useCallback(()=>currentProduct.pricing['METAL_1'].map(({price,quality,type})=>{
       return({
           config_name:`${quality}-${type}`,
           type: type,
           component_price: price,
           children: currentProduct.pricing['CARAT_WEIGHT'] ? 
                     generateCaratValues()
                     :  currentProduct.pricing['RING_SIZE'] ?
                        generateRingSizeValues()
                        : null
                    ,
           quality: quality,
       })
   })
  ,[currentProduct,generateCaratValues,generateRingSizeValues])

   
   
  //update the offsets array
  const updateOffsets = (changedOffset: any) => {
    const {configuration,offsetPrice} = changedOffset;
    const modifiedProduct = {...currentProduct}
    const existingOffset = _.find(modifiedProduct.offsets,{configuration:configuration});

        if(existingOffset){
            existingOffset.offsetPrice = offsetPrice;
        }

        else {
            modifiedProduct.offsets.push(changedOffset);
        }
    console.log('this should be the product with updated offsets:',modifiedProduct)
    setCurrentProduct(modifiedProduct);
  }

  //change the product state using this
  const updateComponentPrice = (configComponent:any) => {
    interface optionToUpdate{
        type:string;
        quality?:string;
        price:Number;
      
    }
    const key = Object.keys(configComponent)[0];
    const {type,quality,component_price} = configComponent[key]
    const modifiedProduct = {...currentProduct}
    const pricingOptions = modifiedProduct.pricing[key]; //[{},{}]
    let optionToUpdate:optionToUpdate | undefined= undefined
    if(key==="METAL_1"){
      optionToUpdate = _.find(pricingOptions,{type:type,quality:quality})
    }else{
      optionToUpdate = _.find(pricingOptions,{type:type})
    }
    if(optionToUpdate){
      optionToUpdate.price = parseInt(component_price)
    }
    
    setCurrentProduct(modifiedProduct)
  }
  
  return (
    <>
      {
        generateMetalValues().map((entry,index)=>{
          return(
              <div className='fade'>
                  <EditableLevel 
                      key={index} 
                      levelValues={entry}  
                      modifyOffsets={updateOffsets} 
                      product={currentProduct} 
                      modifyComponentPrice={updateComponentPrice} 
                  />
              </div>
              )
          })
      }
    </>
  )
}

