import uniqWith from 'lodash/uniqWith';
import isEqual from 'lodash/isEqual';
import {
  ProductConfiguration,
  DashboardProduct,
  DashboardUtilsBag,
  DashboardProductStoneOption,
  ProductOptionType,
} from '@tiary-inc/tiary-shared';

import { expandStoneTypeQualities } from '../../../../utils/stoneUtils';
import { expandChainLengths } from '../../../../utils/expandValues';
import { isProductEarring } from '../../../../utils/productHelpers';

const previews = [
  {
    name: 'Silver & Genuine',
    TEXT: 'XXXXXX',
    METAL: {
      type: 'SILVER',
      quality: 'STERLING',
    },
    STONE: {
      type: 'DIAMOND_WHITE',
      quality: 'GENUINE',
    },
  },
  {
    name: '14K Gold & Genuine',
    TEXT: 'XXXXXX',
    METAL: {
      type: 'GOLD_YELLOW',
      quality: 'KARAT_14',
    },
    STONE: {
      type: 'DIAMOND_WHITE',
      quality: 'GENUINE',
    },
  },
  {
    name: '18K Gold & Genuine',
    TEXT: 'XXXXXX',
    METAL: {
      type: 'GOLD_YELLOW',
      quality: 'KARAT_18',
    },
    STONE: {
      type: 'DIAMOND_WHITE',
      quality: 'GENUINE',
    },
  },
];

export const getPreviewConfigurations = (
  product: DashboardProduct,
  bag: DashboardUtilsBag
) => {
  let allPreviews = previews.map((preview) => {
    const configuration: ProductConfiguration = {};

    product.options.forEach((option: any) => {
      switch (option.type) {
        case 'METAL':
          configuration[option.name] = preview.METAL;
          break;
        case 'STONE':
          const stoneOption = option as DashboardProductStoneOption;
          const values = expandStoneTypeQualities(
            stoneOption.values.types,
            bag
          ) as { type: string; quality: string }[];
          const valueExists = !!values.find(
            ({ type, quality }) =>
              type === preview.STONE.type && quality === preview.STONE.quality
          );

          // if the value exists, set it to the preview value
          // if it doesnt exist, set the config stone to the first matching stone of the same quality
          if (valueExists) {
            configuration[option.name] = preview.STONE;
          } else {
            const itemsWithMatchingQuality = values.filter(
              (value) => value.quality === preview.STONE.quality
            );
            // if we have an item with a matchi g quality
            if (itemsWithMatchingQuality.length > 0) {
              configuration[option.name] = itemsWithMatchingQuality[0];
            } else {
              // other wise just use an available value, idk
              configuration[option.name] = values[0];
            }
          }
          break;
        case 'TEXT':
          const maxLength = option.maxLength || 9;
          configuration[option.name] = preview.TEXT.slice(0, maxLength);
          break;
        case 'CHAIN':
          const expandedLengths = expandChainLengths(option.values, bag);
          configuration[option.name] = {
            quality: preview.METAL.quality,
            type: expandedLengths.length
              ? (expandedLengths[0] as string).replace('_INCHES', '')
              : '16', // we remove '_INCHES' for legacy config reasons
          };
          break;
        case ProductOptionType.Quantity:
          // if the product is an earring, the quanitity should default be 2
          configuration[option.name] = isProductEarring(product.id) ? '2' : '1';
          break;
        default:
          break;
      }
    });
    return {
      name: preview.name,
      configuration,
    };
  });

  // if the product has no options, show "none" as the configuration preview
  if (product.options.length === 0) {
    allPreviews = [{ name: 'None', configuration: {} }];
  }

  return uniqWith(allPreviews, (a, b) =>
    isEqual(a.configuration, b.configuration)
  );
};
