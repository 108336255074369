import React from 'react';
import { CardProps } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Chip from '@material-ui/core/Chip';
import ColorHash from 'color-hash';
import styled from 'styled-components';
import {
  Order,
  describeOrderStatus,
  ManufacturerCode,
  OrderStatusCode,
} from '@tiary-inc/tiary-shared';
import { addBusinessDays } from 'date-fns/esm';
import { useHistory } from 'react-router-dom';
import { format } from 'fastis';

import {
  InventoryManufacturer,
  UnassignedManufacturer,
  MasterCastingManufacturer,
} from '../Manufacturer/ListDecoratorView';

const StyledOrderRowView = styled(TableRow)`
  cursor: pointer;

  .is--good {
    color: #28af60;
    border-color: #28af60;
  }
  .is--bad {
    color: #c03a2b;
    border-color: #c03a2b;
  }
  .is--normal {
    color: rgba(0, 0, 0, 0.87);
    border-color: rgba(0, 0, 0, 0.87);
  }

  .order-row-view {
    &__order-id {
      font-family: monospace;
      font-size: 18px;
    }
  }
`;

interface OrderRowViewProps {
  order: Order;
  onSelect: () => void;
  selected?: boolean;
}

export const OrderRowView: React.FunctionComponent<
  OrderRowViewProps & CardProps
> = ({ order, style, selected }) => {
  const history = useHistory();
  const colorHash = new ColorHash();

  const manufacturerCode = order.manufacturer || order.manufacturing?.provider;

  let manufacturing = <UnassignedManufacturer order={order} />;
  if (manufacturerCode === ManufacturerCode.MasterCasting) {
    manufacturing = <MasterCastingManufacturer order={order} />;
  } else if (manufacturerCode === ManufacturerCode.Inventory) {
    manufacturing = <InventoryManufacturer order={order} />;
  }

  const dateCreated = order.dateCreated.toDate();
  const completionDate = addBusinessDays(dateCreated, 10);

  const avatarInitials = [
    order?.billingAddress?.firstName || '',
    order?.billingAddress?.lastName || '',
  ]
    .map((part) => (part[0] || '').toUpperCase())
    .join('');

  let completionClass = 'is--good';
  if (order.status === OrderStatusCode.Shipped) {
    completionClass = 'is--good';
  } else if (order.status === OrderStatusCode.Cancelled) {
    completionClass = 'is--normal';
  } else if (new Date() > completionDate) {
    completionClass = 'is--bad';
  }

  return (
    <StyledOrderRowView
      hover
      style={style}
      selected={selected}
      onClick={() => history.push(`/orders/order/${order.id}`)}
    >
      <TableCell>
        <ListItem>
          <ListItemAvatar>
            <Avatar
              style={{
                backgroundColor: colorHash.hex(order?.email),
              }}
            >
              {avatarInitials}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <span className="order-row-view__order-id">{order?.id}</span>
            }
            secondary={`${order?.billingAddress?.firstName} ${order?.billingAddress?.lastName}`}
          />
        </ListItem>
      </TableCell>
      <TableCell>
        <ListItem>
          <ListItemText
            primary={`$${order.payment.amount / 100} ${order.payment.currency}`}
          />
        </ListItem>
      </TableCell>
      <TableCell>
        {format({
          // @ts-ignore
          timestamp: order.dateCreated,
          format: `MMM do yyyy @ p`,
        })}
        <br />
        <div className={completionClass}>{completionDate.toDateString()}</div>
      </TableCell>
      <TableCell>{manufacturing}</TableCell>
      <TableCell align="right">
        <Chip
          className={completionClass}
          variant="outlined"
          label={describeOrderStatus(order.status)}
        />
      </TableCell>
    </StyledOrderRowView>
  );
};
