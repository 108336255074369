import {
  ProductOptionType,
  Manufacturer,
  DashboardProduct,
  DashboardUtilsBag,
} from '@tiary-inc/tiary-shared';
import getPricingForMetal from './options/getPricingForMetal';
import getPricingForStone from './options/getPricingForStone';
import { ProductPricing, PriceCalculator } from './types';
import getPricingForChain from './options/getPricingForChain';
import { getBaseCosts } from '../getBaseCosts';
import getPricingForNone from './options/getPricingForNone';

interface GetPricingForProductArgs {
  product: DashboardProduct;
  manufacturer: Manufacturer;
}

const priceCalculators: { [type: string]: PriceCalculator } = {
  [ProductOptionType.Metal]: getPricingForMetal,
  [ProductOptionType.Stone]: getPricingForStone,
  [ProductOptionType.Chain]: getPricingForChain,
};

export const adjustMargins = (margins: any, adjustment?: number) => {
  const nextMargins = { ...margins };
  if (typeof adjustment === 'number') {
    Object.keys(nextMargins).forEach((key) => {
      if (typeof nextMargins[key] === 'number') {
        nextMargins[key] = (nextMargins[key] as number) + adjustment;
      } else {
        nextMargins[key] = adjustMargins(nextMargins[key], adjustment);
      }
    });
  }
  return nextMargins;
};

export const getPricingForProduct = (
  { product, manufacturer }: GetPricingForProductArgs,
  bag: DashboardUtilsBag
): [number, ProductPricing] => {
  const pricing: ProductPricing = {};
  const margins = adjustMargins(bag.pricing.margin, product?.margins?.base);
  product.options.forEach((option) => {
    if (priceCalculators[option.type]) {
      pricing[option.name] = priceCalculators[option.type](
        { product, manufacturer, margins, option },
        bag
      );
    }
  });

  // part of the pricing calculator above
  if (product.options.length === 0) {
    const pricingForNone = getPricingForNone(
      { product, manufacturer, margins, option: undefined as any },
      bag
    );

    // if pricing for none is an empty object, ignore it
    if (Object.keys(pricingForNone).length !== 0) {
      pricing['NONE'] = pricingForNone;
    }
  }

  // get the base costs as a default
  const currentBaseCosts = product.basePrice || 0;
  console.log(currentBaseCosts);
  let baseCosts = getBaseCosts(bag);

  // if the current set base cost on the product is higher than the default base cost
  // then use that for the base cost
  // hint: we're manually overriding the base cost so we can have a custom price
  // if (currentBaseCosts !== baseCosts) {
  //   baseCosts = Number(currentBaseCosts);
  // }

  return [baseCosts, pricing];
};
