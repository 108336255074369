import { Timestamp } from "@firebase/firestore-types"
import json2csv from "json2csv"
import { get } from "lodash"

interface mutatedTableValueOptions {
  mutate?: (value: any) => any
  defaultValue?: string
}

export const mutatedTableValue = (
  path: string,
  { mutate = (v: any) => v, defaultValue = "" }: mutatedTableValueOptions = {}
) => (obj: any) => {
  const value = get(obj, path)
  return value ? mutate(value) : defaultValue
}

export const tableValueDate = (path: string) =>
  mutatedTableValue(path, { mutate: (v: Date) => v.toLocaleString() })

export const tableValueDateFromFirebase = (path: string) =>
  mutatedTableValue(path, {
    mutate: (v: Timestamp) => new Date(v.seconds * 1000).toLocaleString()
  })

export const tableValuePercent = (path: string) =>
  mutatedTableValue(path, {
    mutate: (p: number) => (p ? `${p * 100}%` : "0%"),
    defaultValue: "0%"
  })

export const tableValuePrice = (path: string) =>
  mutatedTableValue(path, {
    mutate: (p: number) => (p ? `$${p.toFixed(2)}` : "$0.00"),
    defaultValue: "$0.00"
  })

export const tableValuePriceFromStripe = (path: string) =>
  mutatedTableValue(path, {
    mutate: (p: number) => (p ? `$${(p / 100).toFixed(2)}` : "$0.00"),
    defaultValue: "$0.00"
  })

export const tableRowData = (obj: any, fields: Array<json2csv.FieldInfo<any>>) => {
  const rowData: { [key: string]: string } = {}
  fields.forEach(({ label, value }) => {
    if (typeof value === "string") { rowData[label] = get(obj, value) }
    else if (typeof value === "function") { rowData[label] = value(obj, "") }
    else { rowData[label] = "" }
  })
  return rowData
}
