import React from 'react';
import styled from 'styled-components';
import { FieldArray, FieldArrayRenderProps } from 'formik';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import { Container, Draggable } from 'react-smooth-dnd';

import { EditorFormField } from '../getFormField';
import { EmptyState } from '../../../EmptyState';
import { ControlMore } from './ControlMore';
import { ControlAdd } from './ControlAdd';
import { ListRow } from './ListRow';

const StyledEditorFormDynamicListField = styled.div`
  margin-bottom: 36px;

  .dynamic-form-list {
    &__controls {
      display: flex;
      align-items: flex-end;
      margin-top: 16px;
      margin-bottom: 16px;
    }
    &__button {
      margin-left: 24px;
    }
    &__row {
      background: white;
      padding: 24px;
      border-radius: 4px;
      border-radius: 4px;
      box-shadow: 0 10px 15px -12px #e0e0e0;
      border: 1px solid #ececec;
      margin-top: 16px;
    }
    &__item-label {
      display: flex;
      align-items: center;
      width: 100%;

      &__name {
        flex: 1;
      }
    }
  }
`;

export interface EditorFormDynamicListFieldType {
  id: string;
  label: string;
  defaults?: any;
  fields: EditorFormField[];
}

interface EditorFormDynamicListFieldProps {
  name: string;
  label: string;
  types: EditorFormDynamicListFieldType[];
  getItemLabel?: (
    spec: EditorFormDynamicListFieldType | null,
    value: any
  ) => string | React.ReactNode;
  oneOfEach?: boolean;
  options?: Array<{ label: string; action: () => void }>;
}

export const EditorFormDynamicListField: React.FunctionComponent<EditorFormDynamicListFieldProps> = ({
  name,
  label,
  types,
  getItemLabel,
  oneOfEach,
  options,
}) => {
  const getType = (id?: string) => types.find((t) => id === t.id) || null;

  return (
    <FieldArray name={name}>
      {({ form, push, remove, move }: FieldArrayRenderProps) => {
        const values = form.values[name] || [];

        const availableTypes = oneOfEach
          ? types.filter(
              ({ id }) =>
                findIndex(values, { type: id } as {
                  type: any;
                }) === -1
            )
          : types;

        const onDrop = ({ removedIndex, addedIndex }) => {
          // we subtract one from the indexes given as they
          // dont think the start of an array is 0 -.-
          move(removedIndex - 1, addedIndex - 1);
        };

        return (
          <StyledEditorFormDynamicListField>
            <Container
              dragHandleSelector=".drag-handle"
              lockAxis="y"
              onDrop={onDrop}
            >
              <div className="dynamic-form-list__controls">
                <ControlAdd
                  types={availableTypes}
                  onAdd={(initialValue) => push(initialValue)}
                />
                {options && <ControlMore options={options} />}
              </div>
              {values.length === 0 ? (
                <EmptyState
                  label={`No ${label.toLowerCase()} found, add one using the form above.`}
                />
              ) : (
                values.map((field, index) => {
                  const spec = getType(field.type);
                  const value = get(form.values, `${name}[${index}]`);
                  const itemLabel = getItemLabel
                    ? getItemLabel(spec, value)
                    : spec?.label;

                  return (
                    <Draggable key={`list-item-${index}`}>
                      <ListRow
                        label={itemLabel}
                        onRemove={() => remove(index)}
                        pathPrefix={[name, index].join('.')}
                        fields={spec?.fields}
                      />
                    </Draggable>
                  );
                })
              )}
            </Container>
          </StyledEditorFormDynamicListField>
        );
      }}
    </FieldArray>
  );
};
