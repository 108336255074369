import { applyMargin } from '@tiary-inc/tiary-shared';
import { ceil } from 'lodash';
import { PriceCalculator } from '../types';

const getPricingForNone: PriceCalculator = ({ product, margins }, bag) => {
  if (product.options.length === 0) {
    const productFindings = product.findings || [];
    const matchingFinding = productFindings.find(
      (finding) => finding.type === 'CUSTOM_PRICE'
    );

    if (matchingFinding) {
      const marginToUse = margins.base || 0;
      // @ts-ignore
      const customPriceToUse = matchingFinding.customPrice || 0;
      // we set the custom price
      return {
        // price: round(applyMargin(customPriceToUse, marginToUse), 2),
        price: ceil(applyMargin(customPriceToUse, marginToUse) / 5) * 5,
        quality: '',
        type: '',
      } as any;
    }
  }

  return {};
};

export default getPricingForNone;
