import React, { useState } from 'react';
import firebase from 'firebase';
import { FirestoreCollections } from '@tiary-inc/tiary-shared';

import { CollectionListTemplate } from '../../lib/templates/ListTemplate/CollectionList';
import { DashboardUserRowView } from '../../components/Users/RowView';
import Loading from '../../lib/components/Loading';
import { DashboardTab } from '..';

/**
 * Users/Admins Page
 * ---
 *
 * This page displays a list of the dashboard admins/users
 */

export interface DashboardUser {
  name: string;
  id: string;
  tabs: DashboardTab[];
}

export const UsersPage: React.FunctionComponent = () => {
  const [loading] = useState(false);

  return !loading ? (
    <CollectionListTemplate
      title="Users/Admins"
      renderItem={(user: DashboardUser) => (
        <DashboardUserRowView key={user.id} dashboardUser={user} />
      )}
      getQueryRef={() =>
        firebase
          .firestore()
          .collection(FirestoreCollections.DASHBOARD)
          .doc('users')
          .collection('preferences')
      }
      searchKeys={['name']}
      breadcrumbs={[{ label: 'Home', to: '/' }, { label: 'Users' }]}
      includeIds
    />
  ) : (
    <Loading />
  );
};
