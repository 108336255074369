import React, { FunctionComponent, useMemo } from 'react'
import * as Yup from 'yup';
import { Formik, FormikHelpers } from 'formik'
import isEmpty from 'lodash/isEmpty'
import {
  useAuth,
  useAuthedDocument,
  UseAuthedDocumentOptions,
} from '@humancollective/human-hooks-firebase'
import { Prompt } from 'react-router-dom'
import { createTimestamp } from 'fastis'
import Loading from '../../../components/Loading'

interface FormikEditorFormSpecificProps {
  validationSchema?: any
  defaultValues?: any
  className?: string
  onSubmitSuccess?: (values:any) => void
}

export type FormikEditorFormProps = FormikEditorFormSpecificProps &
  UseAuthedDocumentOptions

export const FormikEditorForm: FunctionComponent<FormikEditorFormProps> = ({
  validationSchema,
  getQueryRef,
  className,
  children,
  defaultValues,
  onSubmitSuccess,
  ...rest
}) => {



  const data = useAuthedDocument({
    getQueryRef,
    ...rest,
  })
  const { firebaseUser } = useAuth()
  const initialValues = useMemo(
    () => (data ? { ...defaultValues, ...data } : null),
    [data, defaultValues],
  )

  const onSubmit = async (values: any, helpers: FormikHelpers<any>) => {

    if (!firebaseUser) {
      throw new Error('tried to submit form without a firebase user')
    }

    //* this is too specific. should not be on the generic template. it should be in edit.tsx probably that runs when validation  occurs
    if(validationSchema) {
      try {
        console.log(validationSchema, 'schema')
        await validationSchema.validate(values)
        console.log('validated')
        onSubmitSuccess && onSubmitSuccess(values)
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          console.log(err)

        }
      }
    }

    helpers.setTouched({})
    values.dateAdded = createTimestamp()
    //*write to firebase regardless of any edit form on dashboard. each edit form should have its specific validation logic
    // getqueryref returns a reference to a very specific place in firebase. depending on what you want to do at that place, you will need or not need a uid. for example, deleting stuff you probably need a uid. reading you probably wont, writing you probably will
    await getQueryRef(firebaseUser.uid).set(values)
    // await firebase.firestore().collection('ongoing-sales').doc(sale).set(values)
    onSubmitSuccess && onSubmitSuccess(values)
  }

  return data ? (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ touched }) => (
        <>
          <Prompt
            when={!isEmpty(touched)}
            message="You have unsaved changes. Are you sure you want to leave this page?"
          />
          {children}
        </>
      )}
    </Formik>
  ) : (
    <Loading />
  )
}
