import React from 'react'
import { CardProps } from '@material-ui/core'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import styled from 'styled-components'
import { PriceRule } from '@tiary-inc/tiary-shared'
import { useHistory } from 'react-router-dom'

const StyledPromotionRowView = styled(TableRow)`
  cursor: pointer;

  .product-row-view {
    &__renderer {
      margin-right: 24px;
    }
  }
`

interface PromotionRowViewProps {
  promotion: PriceRule & { id: string }
}

export const PromotionRowView: React.FunctionComponent<
  PromotionRowViewProps & CardProps
> = ({ promotion }) => {
  const history = useHistory()
  return (
    <StyledPromotionRowView
      hover
      onClick={() => history.push(`/promotions/edit/${promotion.id}`)}
    >
      <TableCell>
        <ListItem>
          <ListItemText
            primary={promotion?.code}
            secondary={promotion.description}
          />
        </ListItem>
      </TableCell>
    </StyledPromotionRowView>
  )
}
