import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { OrderProduct } from '@tiary-inc/tiary-shared';
import { makeStyles, Avatar } from '@material-ui/core';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import RemoveShoppingCartIcon from '@material-ui/icons/RemoveShoppingCart';

import { getProductMetadata } from '../../../../utils/firebase/products';

import { describeConfiguration } from '@tiary-inc/tiary-shared';

interface OrderProductCardProps {
  product: OrderProduct;
  extraActions?: React.ReactNode;
  extraContent?: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    '& > .MuiCardHeader-action': {
      backgroundColor: 'red',
    },
  },
  productAvatar: {
    height: 75,
    width: 75,
  },
  headerAction: {
    alignSelf: 'center',
  },
  spacedContent: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

const OrderProductCard: React.FunctionComponent<OrderProductCardProps> = ({
  product,
  extraActions,
  extraContent,
}) => {
  const styles = useStyles();

  const [productName, setProductName] = React.useState<string>('...');

  React.useEffect(() => {
    getProductMetadata(product.sku).then((meta) => {
      setProductName(meta.name);
    });
  }, [product.sku]);

  return (
    <Card raised={true} className={styles.root}>
      <CardHeader
        classes={{
          action: styles.headerAction,
        }}
        avatar={
          <Avatar
            className={styles.productAvatar}
            src={`https://tiary.imgix.net/marketing-renders/${product.sku}.jpg?fit=crop`}
          />
        }
        title={productName}
        subheader={product.sku}
        action={<Chip label={`$${product.price} USD`} />}
      />

      <CardContent>
        {/* Describes the product configuration */}
        {describeConfiguration(product.configuration, (values) =>
          values.map((val) => (
            <div key={val.label}>
              <strong>{val.label}</strong>: {val.value}
            </div>
          ))
        )}

        {/* If the package is marked for individual packaging */}
        {product.packIndividually && (
          <div className={styles.spacedContent}>
            <strong>Notes:</strong> {`Package this item individually`}
          </div>
        )}

        {/* If the package is marked for individual packaging */}
        {product.isInventoryItem && (
          <div className={styles.spacedContent}>
            <Chip
              label={`Inventory item`}
              color="primary"
              icon={<RemoveShoppingCartIcon />}
            />
          </div>
        )}

        {/* if the card has extra content as a prop, show it */}
        {extraContent && (
          <div className={styles.spacedContent}>{extraContent}</div>
        )}
      </CardContent>
      <CardActions>
        <Button size="small" color="primary">
          Refund
        </Button>
        {extraActions}
      </CardActions>
    </Card>
  );
};

export default OrderProductCard;
