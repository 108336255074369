import React from 'react'
import firebase from 'firebase'

import { GalleryTemplate } from '../../lib/templates/GalleryTemplate'
import { LocaleCardView } from '../../components/Locale/CardView'

/**
 * Locales Page
 * ---
 *
 * This page displays a list of locales that are available for editing.
 *
 * Each locale is a collection of terms used to render material strings into
 * the local language. This is mostly done using the "localize" function in
 * the "LocaleContext".
 */

export const LocalesPage: React.FunctionComponent = () => (
  <GalleryTemplate
    title="Locales"
    renderItem={(locale) => <LocaleCardView locale={locale} />}
    getQueryRef={() =>
      firebase
        .firestore()
        .collection('dashboard')
        .doc('data')
        .collection('locales')
    }
    includeIds
  />
)
