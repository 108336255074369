import firebase from 'firebase';
import { FirestoreCollections, ProductMetadata } from '@tiary-inc/tiary-shared';

export const getProductMetadata = async (
  sku: string
): Promise<ProductMetadata> => {
  try {
    const metadataDoc = await firebase
      .firestore()
      .collection(FirestoreCollections.PRODUCT_METADATA)
      .doc(sku)
      .get();

    if (!metadataDoc.exists) {
      throw Error('Product metadata does not exist');
    }

    const productMetadata: ProductMetadata = metadataDoc.data() as ProductMetadata;

    return productMetadata;
  } catch (error) {
    console.log('Error fetching product metadata for sku', sku, error);
    throw error;
  }
};
