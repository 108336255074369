export enum ModificationType {
  Add = 'ADD',
  Change = 'CHANGE',
  Remove = 'REMOVE',
}

export interface AddModification<T = any> {
  type: ModificationType.Add
  newData: T
  id?: string
}

export interface RemoveModification<T = any> {
  type: ModificationType.Remove
  oldData: T
}

export interface ChangeModification<T = any> {
  type: ModificationType.Change
  changedProperties: string[]
  oldData: T
  newData: T
}

export type Modification<T = any> =
  | AddModification<T>
  | RemoveModification<T>
  | ChangeModification<T>
