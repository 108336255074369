import * as React from 'react';
import TableRow from '@material-ui/core/TableRow';

import { Typography } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import styled from 'styled-components';

const StyledContainer = styled(Container)`
  margin-top: 24px;
`;

export const OrderRowSection: React.FunctionComponent = ({ children }) => (
  <TableRow>
    <StyledContainer>
      <Typography variant="h5">{children}</Typography>
    </StyledContainer>
  </TableRow>
);

OrderRowSection.displayName = 'OrderRowSection';
