import React from 'react';
import { Field, FieldProps } from 'formik';
import { FormControlProps } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import get from 'lodash/get';

interface FormikSwitchProps {
  name: string;
  label?: string;
  inverted?: boolean;
}

export const FormikSwitch: React.FunctionComponent<
  FormikSwitchProps & FormControlProps
> = ({ name, label, className, inverted }) => (
  <div className={className}>
    <Field name={name}>
      {({ form }: FieldProps) => {
        const error = form.errors[name] as string | undefined;

        const checked = get(form.values, name);

        return (
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  id={name}
                  checked={inverted ? !checked : checked}
                  onChange={(e) =>
                    form.setFieldValue(
                      name,
                      inverted ? !e.target.checked : e.target.checked
                    )
                  }
                />
              }
              label={label}
            />
            {form.touched && error && <FormHelperText>{error}</FormHelperText>}
          </FormGroup>
        );
      }}
    </Field>
  </div>
);
