import styled from 'styled-components'
import Modal from '@material-ui/core/Modal'

export const StyledManufacturerFinishings = styled.div`
  .manufacturer-finishings {
    &__finishing {
      display: flex;
      align-items: center;
      padding: 4px 12px;

      &:nth-of-type(2n) {
        background-color: #fafafa;
      }

      &__label {
        flex: 1;
      }
    }
    &__finishing-length {
      display: flex;
      align-items: center;
      padding: 8px 12px;

      &:nth-of-type(2n) {
        background-color: #fafafa;
      }

      &__label {
        flex: 1;
      }
    }
  }
`

export const StyledManufacturerFinishingsModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;

  .finishing-cost-modal {
    &__wrapper {
      max-width: 70%;
      width: 600px;
      padding: 48px;
      background: white;
    }
    &__value {
      display: flex;
      align-items: center;
    }
    &__weight-input,
    &__cost-input {
      flex: 1;
      margin-right: 16px;
    }
  }
`
