import React, { useMemo } from 'react'
import firebase from 'firebase'
import compact from 'lodash/compact'
import uniq from 'lodash/uniq'
import flatten from 'lodash/flatten'
import { useAuthedCollection } from '@humancollective/human-hooks-firebase'
import { useParams } from 'react-router-dom'
import { TiaryMaterial } from '@tiary-inc/tiary-shared'

import EditorTemplate from '../../lib/templates/EditorTemplate'
import { EditorFormFieldType } from '../../lib/components/EditorForm/Fields/getFormField'

/**
 * Edit Locale Page
 * ---
 *
 * This page is for editing a single locale. It provides the user with a list
 * of terms generated from the materials collection and allows them to enter
 * a localized string to represent each term.
 *
 * This is used in the "localize" function in the "LocaleContext".
 */

export const EditLocalePage: React.FunctionComponent = () => {
  const { locale } = useParams()

  const materials: TiaryMaterial[] = useAuthedCollection({
    getQueryRef: () =>
      firebase
        .firestore()
        .collection('dashboard')
        .doc('data')
        .collection('materials'),
    defaultValue: [],
  })

  const terms = useMemo(() => {
    const groupedTerms = materials
      .map(({ id, ...rest }) => rest)
      .map(Object.values)
    return uniq(compact(flatten(groupedTerms))).sort()
  }, [materials])

  return (
    <EditorTemplate
      breadcrumbs={[
        { label: 'Locales', to: '/locales' },
        { label: 'Edit Locale' },
      ]}
      getQueryRef={() =>
        firebase
          .firestore()
          .collection('dashboard')
          .doc('data')
          .collection('locales')
          .doc(locale)
      }
      formSchema={[
        // {
        //   filename: `locale-${locale}`,
        //   type: EditorFormFieldType.ImportExport,
        // },
        {
          name: 'name',
          label: 'Locale Name',
          type: EditorFormFieldType.Text,
        },
        ...terms.map(
          (term) =>
            ({
              name: `materials.${term}`,
              label: term,
              type: EditorFormFieldType.Text,
            } as {
              name: string
              label: string
              type: EditorFormFieldType.Text
            }),
        ),
      ]}
    />
  )
}
