import React from 'react';
import firebase from 'firebase';
import { useParams } from 'react-router-dom';

import EditorTemplate from '../../lib/templates/EditorTemplate';
import { ManufacturerMetals } from '../../components/Manufacturer/forms/Metals';
import { ManufacturerFindings } from '../../components/Manufacturer/forms/Findings';
import { EditorFormFieldType } from '../../lib/components/EditorForm/Fields/getFormField';
import { ManufacturerStones } from '../../components/Manufacturer/forms/Stones';
import { ManufacturerChains } from '../../components/Manufacturer/forms/Chains';
import { ManufacturerFinishings } from '../../components/Manufacturer/forms/Finishings';
import { ManufacturerRhodium } from '../../components/Manufacturer/forms/Rhodium';

/**
 * Edit Manufacturer Page
 * ---
 *
 * This page is for editing a single manufacturer. Especially the costs related
 * to the production of a product. These raw costs are used in conjunction with
 * product data to calculate manufacturing costs and consumer pricing.
 */

export const EditManufacturerPage: React.FunctionComponent = () => {
  const { manufacturer } = useParams();
  return (
    <EditorTemplate
      breadcrumbs={[
        { label: 'Manufacturers', to: '/manufacturers' },
        { label: 'Edit Manufacturer' },
      ]}
      getQueryRef={() =>
        firebase
          .firestore()
          .collection('dashboard')
          .doc('data')
          .collection('manufacturers')
          .doc(manufacturer)
      }
      formSchema={[
        // {
        //   filename: `manufacturer-${manufacturer}`,
        //   type: EditorFormFieldType.ImportExport,
        // },
        {
          name: 'name',
          label: 'Manufacturer Name',
          type: EditorFormFieldType.Text,
        },
        {
          label: 'Materials',
          type: EditorFormFieldType.Heading,
        },
        {
          label: 'Metals',
          description: 'The pennyweight cost of raw metals.',
          type: EditorFormFieldType.Group,
          fields: [
            {
              type: EditorFormFieldType.FieldComponent,
              FieldComponent: ManufacturerMetals,
              name: 'costs.materials',
            },
          ],
        },
        {
          label: 'Stones',
          description: 'The raw cost of each stone by size.',
          type: EditorFormFieldType.Group,
          fields: [
            {
              type: EditorFormFieldType.FieldComponent,
              FieldComponent: ManufacturerStones,
              name: 'costs.materials',
            },
          ],
        },
        {
          label: 'Chains',
          type: EditorFormFieldType.Group,
          description: 'The raw cost of each chain by type.',
          fields: [
            {
              type: EditorFormFieldType.FieldComponent,
              FieldComponent: ManufacturerChains,
              name: 'costs.materials',
            },
          ],
        },
        {
          label: 'Findings',
          description: 'The cost of finishing a piece by metal weight.',
          type: EditorFormFieldType.Group,
          fields: [
            {
              type: EditorFormFieldType.FieldComponent,
              FieldComponent: ManufacturerFindings,
              name: 'costs.materials',
            },
          ],
        },
        {
          label: 'Labor',
          type: EditorFormFieldType.Heading,
        },
        {
          label: 'Assembly',
          name: 'assembly',
          description: 'The labor costs associated with production.',
          type: EditorFormFieldType.Group,
          fields: [
            {
              label: 'Assembly (per step)',
              description: 'The fixed cost of a single assembly step.',
              name: 'costs.assembly',
              type: EditorFormFieldType.Money,
            },
          ],
        },
        {
          label: 'Stone Settings',
          name: 'stoneSettings',
          description: 'The labor costs associated with stone setting.',
          type: EditorFormFieldType.Group,
          fields: [
            {
              label: 'Prong',
              description: 'The fixed cost of a single prong setting.',
              name: 'costs.stoneSettings.PRONG',
              type: EditorFormFieldType.Money,
            },
            {
              label: 'Bezel',
              description: 'The fixed cost of a single bezel setting.',
              name: 'costs.stoneSettings.BEZEL',
              type: EditorFormFieldType.Money,
            },
            {
              label: 'Opaque',
              description: 'The fixed cost of a single opaque setting.',
              name: 'costs.stoneSettings.OPAQUE',
              type: EditorFormFieldType.Money,
            },
          ],
        },
        {
          label: 'Rhodium',
          name: 'rhodium',
          description:
            'The cost of rhodium plating a white gold piece by metal weight.',
          type: EditorFormFieldType.Group,
          fields: [
            {
              type: EditorFormFieldType.FieldComponent,
              FieldComponent: ManufacturerRhodium,
              name: 'costs.rhodium',
            },
          ],
        },
        {
          name: 'finishings',
          label: 'Finishings',
          description: 'The cost of finishing a piece by metal weight.',
          type: EditorFormFieldType.Group,
          fields: [
            {
              type: EditorFormFieldType.FieldComponent,
              FieldComponent: ManufacturerFinishings,
              name: 'costs.finishings',
            },
          ],
        },
      ]}
    />
  );
};
