import React from 'react';
import {
  CardProps,
  ListItem,
  ListItemText,
  TableRow,
  TableCell,
  Chip,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';

import styled from 'styled-components';
import {
  ProductSpec,
  RendererLayoutMode,
  Inventory,
} from '@tiary-inc/tiary-shared';
import { useHistory } from 'react-router-dom';
import { TiaryRenderer } from '@tiary-inc/tiary-components';

const StyledInventoryRowView = styled(TableRow)`
  cursor: pointer;

  .inventory-row-view {
    &__renderer {
      margin-right: 24px;
    }
  }
`;

interface InventoryRowViewProps {
  sku: string;
  product?: ProductSpec;
  inventory: Inventory;
  onSelect: () => void;
  selected?: boolean;
  error?: boolean;
}

const INITIAL_CONFIG = {
  STONE_1: { quality: 'SIMULATED', type: 'DIAMOND_WHITE' },
  STONE_2: { quality: 'SIMULATED', type: 'DIAMOND_WHITE' },
  METAL_1: { quality: 'KARAT_14', type: 'GOLD_YELLOW' },
};

export const InventoryRowView: React.FunctionComponent<
  InventoryRowViewProps & CardProps
> = ({ sku, product, inventory, selected, error }) => {
  const history = useHistory();

  return (
    <StyledInventoryRowView
      hover
      onClick={() => history.push(`/inventory/edit/${sku}`)}
      selected={selected}
    >
      <TableCell>
        <ListItem>
          {product && (
            <TiaryRenderer
              className="inventory-row-view__renderer"
              productSpec={product}
              configuration={INITIAL_CONFIG}
              layout={{
                mode: RendererLayoutMode.Fixed,
                height: 72,
                width: 72,
                focalPointY: 0.8,
              }}
            />
          )}
          <ListItemText primary={inventory?.product} />
        </ListItem>
      </TableCell>
      <TableCell>
        <ListItem>
          <ListItemText primary={inventory.count} />
        </ListItem>
      </TableCell>
      <TableCell>
        <ListItem>
          {error && (
            <Chip label="error" icon={<ErrorIcon />} color="secondary" />
          )}
        </ListItem>
      </TableCell>
    </StyledInventoryRowView>
  );
};
