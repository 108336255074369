import React from 'react'
import { useAuthedDocument } from '@humancollective/human-hooks-firebase'
import startCase from 'lodash/startCase'
import get from 'lodash/get'
import firebase from 'firebase/app'

/**
 * Locale Context
 * ---
 * This context is used for rendering content in a localized way. The exported
 * function "localize" will take a string from the "materials" collection and
 * return a localized representation of it.
 */

// Currently there is just one locale (en_US).
const DEFAULT_LOCALE = 'en_US'

// the default localizer simply start-cases the string
// e.g., "HELLO" becomes "Hello"
export type Localizer = (term: string) => string
const defaultLocalizer: Localizer = (term = '') => startCase(term.toLowerCase())

const defaultValue = {
  locale: {},
  localize: defaultLocalizer,
}

export const LocaleContext = React.createContext(defaultValue)
export const LocaleContextProvider: React.FunctionComponent = ({
  children,
}) => {
  // Connect to the default locale
  const locale = useAuthedDocument({
    getQueryRef: () =>
      firebase
        .firestore()
        .collection('dashboard')
        .doc('data')
        .collection('locales')
        .doc(DEFAULT_LOCALE),
  })

  // The localizer looks up the term in the locale
  const localize: Localizer = (term: string = '') =>
    get(locale, `materials.${term}`, defaultLocalizer(term))

  return (
    <LocaleContext.Provider value={{ locale, localize }}>
      {children}
    </LocaleContext.Provider>
  )
}
