import {
  uniqWith,
  isEqual,
  round,
  ceil,
  groupBy,
  sum,
} from 'lodash';
import {
  DashboardProductMetalOption,
  getCostForMetalOption,
  MetalMaterial,
  applyMargin,
  DashboardUtilsBag,
} from '@tiary-inc/tiary-shared';

import { expandOptionValues } from '../../expandValues';
import { PriceCalculator } from '../types';

export const getAllConfigsForMetalOption = (
  option: DashboardProductMetalOption,
  bag: DashboardUtilsBag
) => {
  const values = expandOptionValues(option.values, bag) as MetalMaterial[];
  return uniqWith(
    values.map(({ type, quality }) => ({ type, quality })),
    isEqual
  );
};

const flattenMetalCosts = (
  costs: {
    price: number;
    letterPrice?: number;
    type: string;
    quality: string;
  }[]
) => {
  const costsByQuality = groupBy(costs, 'quality');
  const flatCostByQuality: { [quality: string]: number } = {};
  // gets the average cost of each metal & quality for an even rhodium amount.
  // that why white gold doesn't cost more than the others
  Object.keys(costsByQuality).forEach((quality) => {
    flatCostByQuality[quality] =
      ceil(sum(costsByQuality[quality].map(({ price }) => price)) /
      costsByQuality[quality].length / 5) * 5;
  });
  return costs.map(({ type, quality, letterPrice }) => ({
    type,
    quality,
    price: flatCostByQuality[quality],
    ...(typeof letterPrice === 'number' && { letterPrice }),
  }));
};

const PRICING_ITEMS_TO_IGNORE = ['FAKE_RHODIUM'];

const getPricingForMetal: PriceCalculator = (
  { product, manufacturer, margins, option },
  bag
) => {
  // If the text option name includes 'CUSTOM', ignore it
  const hasNameOption = !!product.options.find(
    (option) =>
      option.type === 'TEXT' &&
      option.maxLength &&
      option.maxLength > 1 &&
      !option.name.includes('CUSTOM')
  );

  const allPrices = getAllConfigsForMetalOption(
    option as DashboardProductMetalOption,
    bag
  ).map(({ type, quality }) => {
    const configuration = {
      [option.name]: { type, quality },
    };
    const costs = getCostForMetalOption(
      {
        configuration,
        option,
        product,
        manufacturer,
      },
      bag
    );

    let total = 0;
    let letterCost = 0;

    for (const cost of costs) {
      if (cost.type === 'METAL') {
        total += (cost as any).cost;
        letterCost += (cost as any).cost;
      } else if (cost.type === 'RHODIUM') {
        // Presumably the rhodium factor will be divided by 3 when we average it
        // out because there are three gold colors.
        total += (cost as any).cost;
      } else if (
        // ignore the items that don't affect pricing and are visual only
        // i.e fake rhodium
        !PRICING_ITEMS_TO_IGNORE.includes(cost.type) &&
        cost.type !== 'ERROR'
      ) {
        total += (cost as any).cost;
      }
    }
    const margin =
      (margins.metalQuality && margins.metalQuality[quality]) || margins.base;
    return {
      ...(hasNameOption
        ? {
            // default cost is for 6 letters, but the metal cost is for one letter
            price: round(applyMargin(total + letterCost * 5, margin), 2),
            letterPrice: round(applyMargin(letterCost, margin), 2),
          }
        : {
            price: round(applyMargin(total, margin), 2),
          }),
      type,
      quality,
    };
  });

  return flattenMetalCosts(allPrices);
};

export default getPricingForMetal;
