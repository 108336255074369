import * as React from 'react';
import { SnackbarOptions, SnackbarState } from '../../components/Snackbar';

export interface UIContext {
  snackbar: {
    open: boolean;
    options?: SnackbarOptions;
    show: (options: SnackbarOptions) => void;
    close: () => void;
  };
}

export const UIContext = React.createContext<UIContext>({
  snackbar: { open: false, show: () => {}, close: () => {} },
});

export const UIContextProvider: React.FunctionComponent = ({ children }) => {
  const [snackbar, setSnackbar] = React.useState<SnackbarState>({
    open: false,
  });

  const showSnackbar = (options: SnackbarOptions): void => {
    setSnackbar({ open: true, options });
  };

  const closeSnackbar = (): void => {
    setSnackbar({ open: false });
  };

  return (
    <UIContext.Provider
      value={{
        snackbar: {
          ...snackbar,
          show: showSnackbar,
          close: closeSnackbar,
        },
      }}
    >
      {children}
    </UIContext.Provider>
  );
};
