import React, { FunctionComponent, useContext } from 'react'
import get from 'lodash/get'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import { FieldArrayRenderProps, FieldArray } from 'formik'

import { LocaleContext } from '../../../../contexts/Locale'
import { StyledManufacturerFinishingsModal } from './style'
import { ManufacturerFinishingsModalRow } from './ModalRow'

interface ManufacturerFinishingsModalProps {
  name: string
  onClose: () => void
}

export const ManufacturerFinishingsModal: FunctionComponent<ManufacturerFinishingsModalProps> = ({
  name,
  onClose,
}) => {
  const { localize } = useContext(LocaleContext)
  return (
    <FieldArray name={name}>
      {({ form, push, remove }: FieldArrayRenderProps) => {
        const values = get(form.values, name) || []
        const addRow = () => push({ minWeight: 0, cost: 0 })
        const removeRow = (index: number) => () => remove(index)
        return (
          <StyledManufacturerFinishingsModal open onClose={onClose}>
            <List className="finishing-cost-modal__wrapper">
              {values.map((value: any, index: number) => (
                <ManufacturerFinishingsModalRow
                  key={`finishing-cost-${index}`}
                  name={name}
                  localize={localize}
                  removeRow={removeRow}
                  index={index}
                  {...value}
                />
              ))}
              <Button onClick={addRow}>Add Row</Button>
            </List>
          </StyledManufacturerFinishingsModal>
        )
      }}
    </FieldArray>
  )
}
