import { useState, useMemo } from 'react'
import Fuse from 'fuse.js'

interface UseSearchArgs {
  data: any[] | null
  keys: string[]
}

export const useSearch = ({ data, keys }: UseSearchArgs) => {
  const [query, setQuery] = useState('')

  const fuse = useMemo(() => {
    if (data !== null && keys) {
      const index = Fuse.createIndex<any>(keys, data)
      return new Fuse(data || [], { keys }, index)
    } else {
      return undefined
    }
  }, [data, keys])

  let results = data || []
  if (fuse && query) {
    const fuseResults = fuse.search(query)
    results = fuseResults.map(({ item }) => item)
  }

  return {
    results,
    query,
    setQuery,
    isReady: !!keys,
  }
}
