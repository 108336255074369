import React, { ReactNode } from 'react'
import { Field, FieldProps } from 'formik'
import { FormControlProps } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import FormHelperText from '@material-ui/core/FormHelperText'
import get from 'lodash/get'

export interface FormikInputProps {
  name: string
  label?: string
  type?: string
  placeholder?: string
  required?: boolean
  multiline?: boolean
  helperText?: string
  startAdornment?: ReactNode
  endAdornment?: ReactNode
  transformInput?: (
    value: string | number | undefined,
  ) => string | number | undefined
  transformOutput?: (
    value: string | number | undefined,
  ) => string | number | undefined
}

export const FormikInput: React.FunctionComponent<
  FormikInputProps & FormControlProps
> = ({
  name,
  type = 'text',
  label,
  className,
  placeholder,
  multiline,
  helperText,
  startAdornment,
  endAdornment,
  transformInput,
  transformOutput,
  ...rest
}) => (
  <div className={className}>
    <Field name={name}>
      {({ field, form }: FieldProps) => {
        let value, onChange
        if (transformOutput) {
          value = transformOutput(get(form.values, name))
        }
        if (transformInput) {
          onChange = (e: any) => {
            let nextValue: string | number = e.target.value
            if (type === 'number') {
              nextValue = Number(nextValue)
            }
            form.setFieldValue(name, transformInput(nextValue))
          }
        }
        return (
          <FormControl {...rest}>
            {label && <InputLabel htmlFor={name}>{label}</InputLabel>}
            <Input
              type={type}
              id={name}
              placeholder={placeholder}
              startAdornment={startAdornment}
              endAdornment={endAdornment}
              multiline={multiline}
              {...field}
              {...(value && { value })}
              {...(onChange && { onChange })}
            />
            {form.touched && form.errors[name] ? (
              <FormHelperText>{form.errors[name]}</FormHelperText>
            ) : helperText ? (
              <FormHelperText>{helperText}</FormHelperText>
            ) : null}
          </FormControl>
        )
      }}
    </Field>
  </div>
)
