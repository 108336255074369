import React from 'react'
import styled from 'styled-components'
import Box from '@material-ui/core/Box'

import SignIn from '../../components/SignInForm'

const StyledLoginTemplate = styled.div<{ backgroundColor?: string }>`
  height: 100vh;
  width: 100vw;
  display: flex;
  ${({ backgroundColor }) => `background-color: ${backgroundColor};` || ''}

  .login-page {
    &__form-container {
      display: flex;
      flex-direction: column;
      padding-left: 5%;
      padding-right: 5%;
      height: 100vh;
      justify-content: center;
      flex: 1;
      max-width: 450px;
    }

    &__summary {
      margin-top: 12px;
      margin-bottom: 48px;
    }
  }
`

interface LoginTemplateProps {
  backgroundColor?: string
  redirectUrl?: string
}

const LoginTemplate: React.FunctionComponent<LoginTemplateProps> = ({
  children,
  backgroundColor,
  redirectUrl,
}) => (
  <StyledLoginTemplate backgroundColor={backgroundColor}>
    <div className="login-page__form-container">
      {children ? (
        children
      ) : (
        <Box paddingBottom={3}>
          <h1 className="login-page__title">Log In.</h1>
        </Box>
      )}
      <SignIn redirectUrl={redirectUrl} />
    </div>
  </StyledLoginTemplate>
)

export default LoginTemplate
