import uniq from 'lodash/uniq'
import sortBy from 'lodash/sortBy'
import groupBy from 'lodash/groupBy'

import { ChainMaterial, MaterialType } from '@tiary-inc/tiary-shared'

interface StructuredChainMaterials {
  [style: string]: {
    size: string
    materials: ChainMaterial[]
  }[]
}

export const groupChains = (materials: ChainMaterial[]) => {
  const chains: StructuredChainMaterials = {}

  const chainMaterials = materials.filter(
    ({ material }) => material === MaterialType.Chain,
  ) as ChainMaterial[]

  const styles = uniq(chainMaterials.map(({ style }) => style)).sort()
  styles.forEach((style) => {
    const allMatches = chainMaterials.filter((c) => c.style === style)
    const matchesBySize = groupBy(allMatches, 'size')

    const lengthGroups = Object.values(matchesBySize).map((matches) => ({
      size: matches[0].size,
      materials: sortBy(matches, 'quality'),
    }))

    chains[style] = sortBy(lengthGroups, 'size')
  })
  return chains
}
