import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Insight } from '@tiary-inc/tiary-shared';

interface SummaryCardProps {
  insights: Insight[];
}

const useStyles = makeStyles((theme) => ({
  rootCard: {
    minWidth: 375,
  },
  cardContent: {
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
  },
  headingBox: {
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: 0,
  },
  list: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
  listItemValue: {
    marginLeft: 'auto',
  },
  listItemIcon: {
    marginRight: theme.spacing(2),
  },
}));

type SummaryRow = {
  label: string;
  value: number;
  isCurrency?: boolean;
};

const SummaryCard: React.FunctionComponent<SummaryCardProps> = ({
  insights,
}) => {
  const [rows, setRows] = React.useState<SummaryRow[]>([]);
  const styles = useStyles();

  const generateRows = (insights: Insight[]): void => {
    const newRows = [
      {
        label: 'Orders',
        value: insights.reduce((accumulator, currentInsight) => {
          return accumulator + currentInsight.data.orders.numberOfOrders;
        }, 0),
      },
      {
        label: 'Sales',
        value: insights.reduce((accumulator, currentInsight) => {
          return accumulator + currentInsight.data.orders.total;
        }, 0),
        isCurrency: true,
      },
      {
        label: 'Average Order Value',
        value:
          insights.reduce((accumulator, currentInsight) => {
            return accumulator + currentInsight.data.orders.averageOrderAmount;
          }, 0) / insights.length,
        isCurrency: true,
      },
      {
        label: 'Items Ordered',
        value: insights.reduce((accumulator, currentInsight) => {
          return accumulator + currentInsight.data.orders.numberOfItemsOrdered;
        }, 0),
      },
      {
        label: 'Add To Cart Value',
        value: insights.reduce((accumulator, currentInsight) => {
          return accumulator + currentInsight.data.cart.total;
        }, 0),
        isCurrency: true,
      },
    ];

    setRows(newRows);
  };

  React.useEffect(() => {
    generateRows(insights);
  }, [insights]);

  return (
    <Card variant="outlined" className={styles.rootCard}>
      <CardContent className={styles.cardContent}>
        <Paper className={styles.headingBox}>
          <Typography variant="h6">Summary</Typography>
        </Paper>
        <ul className={styles.list}>
          {rows.map(({ label, value, isCurrency }) => (
            <li key={label} className={styles.listItem}>
              <FilterListIcon className={styles.listItemIcon} />
              <Typography variant="body2">{label}</Typography>
              <Typography variant="h6" className={styles.listItemValue}>
                {isCurrency
                  ? new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(value)
                  : value}
              </Typography>
            </li>
          ))}
        </ul>
      </CardContent>
    </Card>
  );
};

export default SummaryCard;
