import React, { ReactNode } from 'react'
import { Field, FieldProps } from 'formik'
import { FormControlProps } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import FormHelperText from '@material-ui/core/FormHelperText'
import get from 'lodash/get'

interface FormikMultiInputProps {
  names: string[]
  label?: string
  type?: string
  placeholder?: string
  required?: boolean
  helperText?: string
  startAdornment?: ReactNode
  endAdornment?: ReactNode
}

export const FormikMultiInput: React.FunctionComponent<
  FormikMultiInputProps & FormControlProps
> = ({
  names,
  type = 'text',
  label,
  className,
  helperText,
  startAdornment,
  endAdornment,
  ...rest
}) => (
  <div className={className}>
    <Field>
      {({ form }: FieldProps) => (
        <FormControl {...rest}>
          {label && <InputLabel>{label}</InputLabel>}
          <Input
            type={type}
            value={get(form.values, names[0])}
            startAdornment={startAdornment}
            endAdornment={endAdornment}
            onChange={(e) => {
              let value: string | number = e.target.value
              if (type === 'number') {
                value = Number(value)
              }
              names.forEach((name) => form.setFieldValue(name, value))
            }}
          />
          {form.touched && form.errors[names[0]] ? (
            <FormHelperText>{form.errors[names[0]]}</FormHelperText>
          ) : helperText ? (
            <FormHelperText>{helperText}</FormHelperText>
          ) : null}
        </FormControl>
      )}
    </Field>
  </div>
)
