import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { createGlobalStyle } from 'styled-components';
import { ThemeProvider } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import * as Sentry from '@sentry/browser';
import { GlobalContextProvider } from '@tiary-inc/tiary-components';
import theme from './configuration/theme';
import { HelmetFonts } from './lib/components/HelmetFonts';
import { HelmetViewport } from './lib/components/HelmetViewport';
import Navbar from './components/Navbar';
import Snackbar from './components/Snackbar';
import { LocaleContextProvider } from './contexts/Locale';
import { MaterialsContextProvider } from './contexts/Materials';
import { AppRoutes } from './routes';
import { PricingContextProvider } from './contexts/Pricing';
import { UIContextProvider } from './contexts/UI';
import { initialize as initailizeFastis } from 'fastis';

// Sentry.io for bug tracking
Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });

// Firebase
firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
});

initailizeFastis(firebase);

const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    padding: 0;
    margin: 0;
    color: ${theme.palette.text.primary};
    background-color: ${theme.palette.background.default};
  }
  .page-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
`;

const UIElements: React.FunctionComponent = () => (
  <>
    <Navbar title="Tiary" />
    <Snackbar />
  </>
);

function App() {
  React.useEffect(() => {
    firebase.onLog((idk) => {
      console.log('Log', idk);
    });
  }, []);

  return (
    <Router>
      <LocaleContextProvider>
        <MaterialsContextProvider>
          <PricingContextProvider>
            <UIContextProvider>
              <GlobalContextProvider
                defaults={{
                  imgixBaseUrl: `https://${process.env.REACT_APP_IMGIX_URL}`,
                }}
              >
                <HelmetFonts />
                <HelmetViewport />
                <GlobalStyle />
                <ThemeProvider theme={theme}>
                  <Box
                    className="page-container"
                    fontWeight="fontWeightRegular"
                    fontSize="fontSize"
                    fontFamily="fontFamily"
                    lineHeight="normal"
                  >
                    <UIElements />
                    <AppRoutes />
                  </Box>
                </ThemeProvider>
              </GlobalContextProvider>
            </UIContextProvider>
          </PricingContextProvider>
        </MaterialsContextProvider>
      </LocaleContextProvider>
    </Router>
  );
}

export default App;
