import uniqWith from 'lodash/uniqWith'
import isEqual from 'lodash/isEqual'
import uniqBy from 'lodash/uniqBy'
import {
  StoneMaterial,
  DashboardStoneType,
  DashboardUtilsBag,
} from '@tiary-inc/tiary-shared'

import { StoneType } from '../components/Product/forms/StoneValues'
import { expandOptionValues } from './expandValues'

export const stoneTypeFromMaterial = ({
  stone,
  color,
  quality,
}: StoneMaterial): StoneType => ({
  stone,
  color,
  quality,
})

export const hashStoneType = ({
  quality,
  color = '',
  stone,
}: StoneType): string => `STONE:${quality}.${color}.${stone}`

export const expandStoneHash = (hash: string): StoneType => {
  if (hash.slice(0, 6) !== 'STONE:') {
    throw new Error(`value is not a stone hash: ${hash}`)
  }
  const parts = hash.replace('STONE:', '')
  const [quality, color, stone] = parts.split('.')
  return { quality, stone, ...(color && { color }) }
}

export const stoneTypesFromMaterials = (materials: StoneMaterial[]) =>
  uniqBy(materials.map(stoneTypeFromMaterial), hashStoneType)

export const expandStoneTypeQualities = (
  valueTypes: DashboardStoneType[] | string,
  bag: DashboardUtilsBag,
): { type: string; quality: string }[] => {
  if (typeof valueTypes === 'string') {
    const materials = expandOptionValues(valueTypes, bag) as StoneMaterial[]
    return uniqWith(
      materials.map(({ type, quality }) => ({ type, quality })),
      isEqual,
    )
  } else {
    return (valueTypes || []).map(({ stone, color, quality }) => ({
      type: color ? `${stone}_${color}` : stone,
      quality,
    }))
  }
}
