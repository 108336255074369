import React from 'react';
import Button from '@material-ui/core/Button';
import DragHandleIcon from '@material-ui/icons/DragHandle';

import { EditorFormField } from '../getFormField';
import { EditorFormGroupField } from '../GroupField';

export type ListRowItemSpec = EditorFormField & { id: string };

interface ListRowProps {
  label: string | React.ReactNode;
  onRemove: () => void;
  pathPrefix: string;
  fields?: EditorFormField[];
}

const nestFields = (fields: EditorFormField[], prefix: string) =>
  fields?.map(({ name, ...rest }) => ({
    ...rest,
    name: [prefix, name].join('.'),
  })) || [];

export const ListRow: React.FunctionComponent<ListRowProps> = ({
  label,
  onRemove,
  pathPrefix,
  fields,
}) => (
  <EditorFormGroupField
    label={
      <div className="dynamic-form-list__item-label">
        <DragHandleIcon className="drag-handle" />

        {label}
        <Button color="primary" onClick={onRemove}>
          Remove
        </Button>
      </div>
    }
    fields={nestFields(fields || [], pathPrefix)}
  />
);
