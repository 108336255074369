import React, { FunctionComponent } from 'react'
import InputAdornment from '@material-ui/core/InputAdornment'
import DeleteIcon from '@material-ui/icons/Delete'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'

import { FormikInput } from '../../../../lib/components/FormikInput'

export const ManufacturerRhodiumModalRow: FunctionComponent<any> = ({
  id,
  quality,
  metal,
  localize,
  name,
  removeRow,
  index,
}) => {
  const description = [quality, metal].map(localize).join(' ')
  return (
    <ListItem className="finishing-cost-modal__value">
      <FormikInput
        className="finishing-cost-modal__weight-input"
        key={`${id}-minWeight`}
        label={description}
        name={`${name}[${index}].minWeight`}
        type="number"
        fullWidth
        endAdornment={<InputAdornment position="end">dwt</InputAdornment>}
      />
      <FormikInput
        className="finishing-cost-modal__cost-input"
        key={`${id}-cost`}
        label={description}
        name={`${name}[${index}].cost`}
        type="number"
        fullWidth
        startAdornment={<InputAdornment position="start">$</InputAdornment>}
        endAdornment={<InputAdornment position="end">USD</InputAdornment>}
      />
      <ListItemSecondaryAction>
        <IconButton edge="end" aria-label="delete">
          <DeleteIcon onClick={removeRow(index)} />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  )
}
