import React, { useState, useContext, useMemo } from 'react';
import Button from '@material-ui/core/Button';
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';
import get from 'lodash/get';
import filter from 'lodash/filter';
import styled from 'styled-components';
import { Field, FieldProps } from 'formik';
import { StoneMaterial } from '@tiary-inc/tiary-shared';

import Loading from '../../../../lib/components/Loading';
import { ManufacturerStonesModal } from './Modal';
import { LocaleContext } from '../../../../contexts/Locale';
import { MaterialsContext } from '../../../../contexts/Materials';
import { Chip } from '@material-ui/core';

interface ManufacturerStonesProps {
  name: string;
}

const StyledManufacturerStones = styled.div`
  .manufacturer-stones {
    &__stone {
      display: flex;
      align-items: center;
      padding: 8px 12px;

      &:nth-of-type(2n) {
        background-color: #fafafa;
      }

      &__label {
        flex: 1;
      }
    }
  }
`;

export const ManufacturerStones: React.FunctionComponent<ManufacturerStonesProps> = ({
  name,
}) => {
  const { stoneMaterials } = useContext(MaterialsContext);
  const { localize } = useContext(LocaleContext);
  const [modalValues, setModalValues] = useState<StoneMaterial[]>();

  // We use this hash to create a single value that refers to a unique stone.
  // For example "WHITE.DIAMOND.GENUINE". Technically we could just use
  // the label but that feels messy.

  const hashValues = (...values: string[]) => values.join('.');

  // We'll pull all of the stone types to create a list.

  const uniqeStones = useMemo(() => {
    const allStones = stoneMaterials.map(({ stone, color, quality }) => ({
      color,
      stone,
      quality,
      label: `${localize(color)} ${localize(stone)} (${localize(quality)})`,
      hash: hashValues(color, stone, quality),
    }));
    return sortBy(uniqBy(allStones, 'hash'), 'label');
  }, [localize, stoneMaterials]);

  const valuesByHash = useMemo(() => {
    const values: { [hash: string]: StoneMaterial[] } = {};
    uniqeStones.forEach(({ hash, color, stone, quality }) => {
      values[hash] = filter(stoneMaterials, { color, stone, quality });
    });
    return values;
  }, [uniqeStones, stoneMaterials]);

  return valuesByHash ? (
    <Field name={name} key={name}>
      {({ form }: FieldProps) => {
        const getPath = (value: { id: string }) => [name, value.id].join('.');

        const valueCountForHash = (hash: string) => {
          let count = 0;
          valuesByHash[hash].forEach(({ id }) => {
            const cost = get(form.values, getPath({ id }));
            if (typeof cost === 'number') {
              count += 1;
            }
          });
          return count;
        };

        return (
          <>
            <ManufacturerStonesModal
              name={name}
              open={modalValues !== undefined}
              onClose={() => setModalValues(undefined)}
              values={modalValues}
            />
            <StyledManufacturerStones>
              {uniqeStones.map(({ label, hash }) => {
                const valuesCount = valueCountForHash(hash);
                const totalValues = valuesByHash[hash].length;
                const missingValues = totalValues - valuesCount;
                return (
                  <div className="manufacturer-stones__stone" key={hash}>
                    <div className="manufacturer-stones__stone__label">
                      <strong>{label}</strong>
                      {` `}
                      {missingValues > 0 && (
                        <Chip
                          color="secondary"
                          size="small"
                          variant="outlined"
                          label={`missing ${missingValues} ${
                            missingValues > 1 ? 'values' : 'value'
                          }`}
                        />
                      )}
                    </div>
                    <Button
                      color="primary"
                      size="small"
                      onClick={() => {
                        const values = valuesByHash[hash];
                        setModalValues(values);
                      }}
                    >
                      Edit
                    </Button>
                  </div>
                );
              })}
            </StyledManufacturerStones>
          </>
        );
      }}
    </Field>
  ) : (
    <Loading />
  );
};
