export const firebaseAuthErrors: { [code: string]: any } = {
  'auth/invalid-email': {
    email: 'Sorry, that email is invalid.',
  },
  'auth/user-disabled': {
    email: 'Sorry, that account is disabled.',
  },
  'auth/user-not-found': {
    email: "There isn't a user with that email.",
  },
  'auth/wrong-password': {
    password: "You've entered an incorrect password.",
  },
  'auth/email-already-in-use': {
    email: 'This email address is already in use by another account.',
  },
  unknown: {
    email: 'Sorry, something went wrong.',
  },
}
