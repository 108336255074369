import React from 'react';
import firebase from 'firebase';
import { createTimestamp } from 'fastis';
import {
  Box,
  CircularProgress,
  Typography,
  Button,
  Modal,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
  makeStyles,
  TextField,
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import { InventoryTransaction } from '@tiary-inc/tiary-shared';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalWrapper: {
    maxWidth: '70%',
    width: 350,
    padding: theme.spacing(3),
    background: 'white',
    borderRadius: 4,
  },
  formControl: {
    marginBottom: theme.spacing(2),
  },
  textField: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));

interface AddInventoryTransactionModalProps {
  onClose: () => void;
  products: string[];
  product?: string;
}

export const AddInventoryTransactionModal: React.FunctionComponent<AddInventoryTransactionModalProps> = ({
  onClose,
  products,
  product,
}) => {

  const classes = useStyles();

  const initialValues: Omit<InventoryTransaction, 'dateCreated'> = {
    product: product ?? products[0],
    type: 'order',
    orderId: '',
    count: 0,
  }

  const onSubmit = async (values: Omit<InventoryTransaction, 'dateCreated'>) => {
    await firebase
      .firestore()
      .collection('inventory')
      .doc(values.product)
      .collection('inventory-transactions')
      .add({
        ...values,
        dateCreated: createTimestamp()
      })
    onClose()
  }

  return (
    <Modal open onClose={onClose} className={classes.root}>
      <div className={classes.modalWrapper}>
        <Typography variant="h5" className={classes.formControl}>
          Create a transaction
        </Typography>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          enableReinitialize={true}
        >
          {({ values, handleChange, handleSubmit, isSubmitting }) => (
            <div className="add-inventory-transaction-modal__wrapper">
              <Form onSubmit={handleSubmit}>
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel>Product</InputLabel>
                  <Select
                    labelId="product-label-select"
                    value={values.product}
                    id="product"
                    name="product"
                    onChange={handleChange}
                  >
                    {products.map((product: string) => (
                      <MenuItem key={product} value={product}>{product}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel>Type</InputLabel>
                  <Select
                    labelId="type-label-select"
                    value={values.type}
                    id="type"
                    name="type"
                    onChange={handleChange}
                  >
                    <MenuItem key="order" value='order'>Order</MenuItem>
                    <MenuItem key="regular" value='regular'>Regular</MenuItem>
                  </Select>
                </FormControl>
                {values.type === 'order' && (
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel>Order Id</InputLabel>
                    <Input
                      value={values.orderId}
                      placeholder="Order Id"
                      id="orderId"
                      name="orderId"
                      onChange={handleChange}
                    />
                  </FormControl>
                )}
                <FormControl fullWidth className={classes.formControl}>
                  <TextField
                    className={classes.textField}
                    fullWidth={true}
                    type="number"
                    value={values.count}
                    label="Count"
                    id="count"
                    name="count"
                    onChange={handleChange}
                  />
                </FormControl>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Add Transaction
                  </Button>
                  <Button
                    type="button"
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  {isSubmitting && (
                    <CircularProgress
                      size={20}
                      color="primary"
                      style={{ marginLeft: 10 }}
                    />
                  )}
                </Box>
              </Form>
            </div>
          )}
        </Formik>
      </div>
    </Modal>
  )
}
