import React, { FunctionComponent, useContext } from 'react'
import Modal from '@material-ui/core/Modal'
import InputAdornment from '@material-ui/core/InputAdornment'
import styled from 'styled-components'
import sortBy from 'lodash/sortBy'
import { StoneMaterial } from '@tiary-inc/tiary-shared'

import { FormikInput } from '../../../../lib/components/FormikInput'
import { LocaleContext } from '../../../../contexts/Locale'

const StyledManufacturerStonesModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;

  .stone-cost-modal {
    &__wrapper {
      max-width: 70%;
      max-height: 70%;
      overflow-y: auto;
      width: 600px;
      padding: 48px;
      background: white;
    }
  }

  .editor-form__input {
    margin-top: 16px;
    margin-bottom: 16px;
  }
`

interface ManufacturerStonesModalProps {
  name: string
  open: boolean
  onClose: () => void
  values?: StoneMaterial[]
}

export const ManufacturerStonesModal: FunctionComponent<ManufacturerStonesModalProps> = ({
  name,
  open,
  onClose,
  values = [],
}) => {
  const { localize } = useContext(LocaleContext)
  return (
    <StyledManufacturerStonesModal open={open} onClose={onClose}>
      <div className="stone-cost-modal__wrapper">
        {sortBy(values, 'size').map(({ id, size }) => (
          <FormikInput
            className="editor-form__input"
            key={id}
            name={[name, id].join('.')}
            label={localize(size)}
            type="number"
            helperText={`The cost of this stone in ${localize(size)}.`}
            fullWidth
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            endAdornment={<InputAdornment position="end">USD</InputAdornment>}
          />
        ))}
      </div>
    </StyledManufacturerStonesModal>
  )
}
