import React, { FunctionComponent } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useAuth } from '@humancollective/human-hooks-firebase'
import { firestore } from 'firebase'

interface EditorFormRemoveButtonControlProps {
  getQueryRef: (
    firebaseUserId: string,
    dependencies?: any[],
  ) => firestore.DocumentReference
}

export const EditorFormRemoveButtonControl: FunctionComponent<EditorFormRemoveButtonControlProps> = ({
  getQueryRef,
}) => {
  const { firebaseUser } = useAuth()
  const [open, setOpen] = React.useState(false)

  const handleRemove = () => {
    if (!firebaseUser) {
      throw new Error('tried to delete item without a firebase user')
    }
    getQueryRef(firebaseUser.uid).delete()
    setOpen(false)
    window.history.back()
  }

  return (
    <>
      <Button
        size="large"
        type="submit"
        color="primary"
        onClick={() => setOpen(true)}
      >
        Remove
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Remove this item?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to remove this item?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="default">
            No, Cancel
          </Button>
          <Button onClick={handleRemove} color="primary" autoFocus>
            Yes, Remove
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
