import React from 'react';
import { CardProps } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import styled from 'styled-components';
import { ProductReview } from '@tiary-inc/tiary-shared';
import { useHistory } from 'react-router-dom';

const StyledReviewRowView = styled(TableRow)`
  cursor: pointer;

  .product-row-view {
    &__renderer {
      margin-right: 24px;
    }
  }
`;

interface ReviewRowViewProps {
  review: ProductReview;
}

export const ReviewRowView: React.FunctionComponent<
  ReviewRowViewProps & CardProps
> = ({ review }) => {
  const history = useHistory();
  return (
    <StyledReviewRowView
      hover
      onClick={() => history.push(`/product-reviews/edit/${review.id}`)}
    >
      <TableCell>
        <ListItem>
          <ListItemText
            primary={`${review.title} (${review.rating}/5)`}
            secondary={review.author.name}
          />
        </ListItem>
      </TableCell>
      <TableCell>
        <ListItem>
          <ListItemText primary={review.sku} />
          <ListItemText
            primary={review.id}
            secondary={review.dateCreated.toDate().toLocaleString()}
          />
        </ListItem>
      </TableCell>
    </StyledReviewRowView>
  );
};
