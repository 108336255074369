import * as React from 'react';
import SettingsIcon from '@material-ui/icons/Settings';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import BlockIcon from '@material-ui/icons/Block';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles(() => ({
  paper: {
    boxShadow:
      '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
  },
}));

interface OrderOptionsMenuProps {
  onCancelOrderClicked: () => void;
  onOpenFirebaseClicked: () => void;
}

export const OrderOptionsMenu: React.FunctionComponent<OrderOptionsMenuProps> = ({
  onCancelOrderClicked,
  onOpenFirebaseClicked,
}) => {
  const [menuAnchorEle, setMenuAnchorEle] = React.useState(null);

  const styles = useStyles();

  const handleOpenMenu = (event): void => {
    setMenuAnchorEle(event.currentTarget);
  };

  const handleCloseMenu = (): void => {
    setMenuAnchorEle(null);
  };

  return (
    <>
      <IconButton aria-label="order options" onClick={handleOpenMenu}>
        <SettingsIcon />
      </IconButton>
      <Menu
        classes={{ paper: styles.paper }}
        anchorEl={menuAnchorEle}
        open={Boolean(menuAnchorEle)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={onOpenFirebaseClicked}>
          <ListItemIcon>
            <WhatshotIcon />
          </ListItemIcon>
          <ListItemText primary="Open In Firebase" />
        </MenuItem>
        <MenuItem onClick={onCancelOrderClicked}>
          <ListItemIcon>
            <BlockIcon />
          </ListItemIcon>
          <ListItemText primary="Cancel Order" />
        </MenuItem>
      </Menu>
    </>
  );
};
