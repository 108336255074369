import { ceil } from 'lodash';
import {
  DashboardProductStoneOption,
  getCostForStoneOption,
  applyMargin,
} from '@tiary-inc/tiary-shared';

import { PriceCalculator } from '../types';
import { expandStoneTypeQualities } from '../../stoneUtils';

const getPricingForStone: PriceCalculator = (
  { product, manufacturer, margins, option },
  bag
) =>
  expandStoneTypeQualities(
    (option as DashboardProductStoneOption).values.types,
    bag
  ).map(({ type, quality }) => {
    // If the text option name includes 'CUSTOM', ignore it
    const hasNameOption = !!product.options.find(
      (option) =>
        option.type === 'TEXT' &&
        option.maxLength &&
        option.maxLength > 1 &&
        !option.name.includes('CUSTOM')
    );
    const costs = getCostForStoneOption(
      {
        configuration: {
          [option.name]: { type, quality },
        },
        option,
        product,
        manufacturer,
      },
      bag
    );
    let total = 0;
    for (const cost of costs) {
      if (cost.type === 'ERROR') {
        throw new Error(cost.description || 'ERROR');
      } else {
        total += (cost as any).cost;
      }
    }
    const margin =
      (margins.stoneQuality && margins.stoneQuality[quality]) || margins.base;
    return {
      ...(hasNameOption
        ? {
            // Why multiply by 6...?
            // 6 is the minimum name length we check
            // price: round(applyMargin(total, margin) * 6, 2),
            // letterPrice: round(applyMargin(total, margin), 2),
            price: ceil(applyMargin(total, margin) * 6 / 5) * 5,
            letterPrice: ceil(applyMargin(total, margin) / 5) * 5,
          }
        : {
            // price: round(applyMargin(total, margin), 2),
            price: ceil(applyMargin(total, margin) / 5) * 5,
          }),
      type,
      quality,
    };
  });

export default getPricingForStone;
