import { createMuiTheme } from '@material-ui/core/styles'
import { noShadows } from '../lib/utils/theme'

/**
 * Theme
 * ---
 * These settings are used by the Human Collective Dashboard library.
 * (see the /src/lib folder)
 */

export default createMuiTheme({
  shadows: noShadows,
  palette: {
    type: 'light',
    primary: {
      main: '#948296',
    },
  },
})
