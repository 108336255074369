import compact from 'lodash/compact';
import uniq from 'lodash/uniq';
import get from 'lodash/get';
import {
  DashboardProduct,
  ProductOptionType,
  DashboardProductMetalOption,
  DashboardProductStoneOption,
  MetalMaterial,
  DashboardUtilsBag,
} from '@tiary-inc/tiary-shared';

import { expandOptionValues } from './expandValues';
import { expandStoneTypeQualities } from './stoneUtils';

const getExpectedMetalRenders = (
  option: DashboardProductMetalOption,
  renders: { [type: string]: string },
  bag: DashboardUtilsBag
) => {
  const materials = expandOptionValues(option.values, bag) as MetalMaterial[];
  const typeNames = uniq(materials.map(({ type }) => type));
  return typeNames.map((type) => {
    const missing = !renders[type];
    return { option: option.name, type, missing };
  });
};

const getExpectedStoneRenders = (
  option: DashboardProductStoneOption,
  renders: { [type: string]: string },
  bag: DashboardUtilsBag
) => {
  const typeNames = uniq(
    expandStoneTypeQualities(option.values.types, bag).map(({ type }) => type)
  );
  return typeNames.map((type) => {
    const missing = !renders[type];
    return { option: option.name, type, missing };
  });
};

interface ExpectedRender {
  option: string;
  type?: string;
  missing: boolean;
}

export const getFilenameForRender = ({
  sku,
  option,
  type,
}: {
  sku: string;
  option: string;
  type?: string;
}) => compact([sku, option, type, 'png']).join('.');

export const getExpectedRenders = (
  product: DashboardProduct,
  bag: DashboardUtilsBag
) => {
  let renderPaths: ExpectedRender[] = [
    { option: 'SHADOW', missing: !product.renders?.SHADOW },
  ];
  const { options = [] } = product;

  // if the product has options, this means that it has a metal, stone, etc
  if (options.length > 0) {
    for (const option of options) {
      if (option.type === ProductOptionType.Metal) {
        renderPaths = [
          ...renderPaths,
          ...getExpectedMetalRenders(
            option,
            get(product.renders, option.name, {}),
            bag
          ),
        ];
      } else if (option.type === ProductOptionType.Stone) {
        renderPaths = [
          ...renderPaths,
          ...getExpectedStoneRenders(
            option,
            get(product.renders, option.name, {}),
            bag
          ),
        ];
      }
    }
  } else {
    // if the product has no options (e.x metal, stone)
    // then require a "base" render image besides the shadow
    renderPaths = [
      ...renderPaths,
      { option: 'BASE', missing: !(product.renders || {})['BASE'] },
    ];
  }

  return renderPaths;
};
