import * as React from 'react';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { Button } from '@material-ui/core';
import { Parser } from 'json2csv';
import {
  Product,
  tableRowData,
  mutatedTableValue,
  getDefaultConfiguration,
  getPriceForConfiguration,
} from '@tiary-inc/tiary-shared';
import { saveAs } from 'file-saver';
import { createTimestamp } from 'fastis';
import { round } from 'lodash';

import * as Contentful from '../../utils/contentful';
import { CombinedProduct } from '../../routes/products/index';

interface DownloadProductsButtonProps {
  products: CombinedProduct[];
}

const facebookCatalogFields: any[] = [
  { label: 'id', value: 'sku' },
  { label: 'title', value: 'name' },
  {
    label: 'description',
    value: mutatedTableValue('description', {
      mutate: (description: string) => description,
      defaultValue: 'No description available.',
    }),
  },
  {
    label: 'availability',
    value: mutatedTableValue('', {
      mutate: () => 'available for order',
      defaultValue: 'none',
    }),
  },
  {
    label: 'condition',
    value: mutatedTableValue('', {
      mutate: () => 'new',
      defaultValue: 'none',
    }),
  },
  {
    label: 'price',
    value: mutatedTableValue('', {
      mutate: (product: Product) => {
        if (product.options) {
          const defaultConfig = getDefaultConfiguration(product);
          const price = getPriceForConfiguration({
            product,
            configuration: defaultConfig,
          });
          if (price !== 0)  return `${round(price)} USD`;
          else return `${round(product.basePrice)} USD`;
        } else {
          return `${round(product.basePrice)} USD`;
        }
      },
      defaultValue: '0.00 USD',
    }),
  },
  {
    label: 'link',
    value: mutatedTableValue('slug', {
      mutate: (slug: string) => `https://tiary.com/products/${slug}`,
      defaultValue: 'none',
    }),
  },
  {
    label: 'image_link',
    value: mutatedTableValue('sku', {
      mutate: (sku: string) =>
        `https://tiary.imgix.net/marketing-renders/${sku}.jpg?w=500&h=500&fit=crop`,
      defaultValue: 'none',
    }),
  },
  {
    label: 'brand',
    value: mutatedTableValue('', {
      mutate: () => 'Tiary',
      defaultValue: 'Tiary',
    }),
  },
];

export const DownloadProductsButton: React.FunctionComponent<DownloadProductsButtonProps> = (
  products
) => {

  const csvFromProducts = (products: any[]) =>
    new Parser<Product>({ facebookCatalogFields }).parse(products);

  const exportProducts = async () => {
    // Create the contentful instance
    const contentfulEnv = await Contentful.getInstance();
    // All the product entries
    const allProductEntries = await contentfulEnv.getEntries({
      content_type: 'product',
      limit: 999,
    });

    const getContentfulProductInfo = (
      sku: string
    ): { description: string; caratWeight: string; productSize: string } => {
      try {
        const entryWithSku = allProductEntries.items.find(
          (contentfulEntry) => contentfulEntry.fields.sku['en-US'] === sku
        );

        const contentfulProductInfo = {
          description: '',
          caratWeight: '',
          productSize: '',
        };

        if (entryWithSku) {
          contentfulProductInfo.description =
            entryWithSku.fields.seoDescription['en-US'] || '';
          contentfulProductInfo.caratWeight =
            entryWithSku.fields.caratWeight['en-US'] || '';
          contentfulProductInfo.productSize =
            entryWithSku.fields.productSize['en-US'] || '';
        }
        return contentfulProductInfo;
      } catch (error) {
        return { description: '', caratWeight: '', productSize: '' };
      }
    };

    // get the combined products with both spec & meta data
    const combinedProductsWithData = products.products.filter(
      (combinedProduct) => combinedProduct.spec && combinedProduct.meta
    );

    //We should hook this up to Contentful so we're not filling in missing data
    const productsToExport: Product[] = combinedProductsWithData.map(
      (combinedProduct) => {
        const contentfulProductInfo = getContentfulProductInfo(
          combinedProduct.spec.sku
        );

        return {
          ...combinedProduct.meta,
          ...combinedProduct.spec,
          dateAdded: createTimestamp(),
          description: contentfulProductInfo.description,
          caratWeight: contentfulProductInfo.caratWeight,
          productSize: contentfulProductInfo.productSize,
          bestSeller: false, // TODO: Fix
          categories: [],
          designers: [],
          info: {
            size: '',
          },
          decorations: [],
          contextPhotos: [],
          collections: [],
          seoMeta: {
            title: '',
            description: '',
          },
        };
      }
    );

    const productsToPrint = productsToExport.map((product) => tableRowData(product, facebookCatalogFields));
    const csv = csvFromProducts(productsToPrint);
    const productsBlob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    saveAs(productsBlob, 'products.csv');
  };

  return (
    <Button
      onClick={exportProducts}
      startIcon={<ImportExportIcon />}
    >
      Export
    </Button>
  );
};

export default DownloadProductsButton;
