import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Insight } from '@tiary-inc/tiary-shared';
import { Chart } from 'react-charts';
import { format } from 'date-fns';

interface SalesOverviewCardProps {
  insights: Insight[];
}

const useStyles = makeStyles((theme) => ({
  rootCard: {
    width: 575,
  },
  cardContent: {
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
  },
  headingBox: {
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: 0,
  },
  chartContent: {
    height: 310,
    margin: theme.spacing(1),
  },
}));

const SalesOverviewCard: React.FunctionComponent<SalesOverviewCardProps> = ({
  insights,
}) => {
  const styles = useStyles();

  const chartData = React.useMemo(() => {
    const data = [
      {
        label: 'Sales $',
        data: insights.map((tempInsight) => ({
          secondary: `${tempInsight.data.orders.total}`, // use a string here because the chart doesn't display a 0 number value
          primary: `${format(tempInsight.dateAdded.toDate(), 'MMM d y')}`,
        })),
      },
    ];

    return data;
  }, [insights]);

  const series = React.useMemo(
    () => ({
      type: 'bar',
    }),
    []
  );

  const axes = React.useMemo(
    () => [
      { primary: true, type: 'ordinal', position: 'bottom' },
      { position: 'left', type: 'linear', stacked: false, show: true },
    ],
    []
  );

  const getSeriesStyle = React.useCallback(
    () => ({
      color: '#948396',
      opacity: 1,
    }),
    []
  );

  return (
    <Card variant="outlined" className={styles.rootCard}>
      <CardContent className={styles.cardContent}>
        <Paper className={styles.headingBox}>
          <Typography variant="h6">Sales Overview (USD)</Typography>
        </Paper>
        <div className={styles.chartContent}>
          <Chart
            data={chartData}
            series={series}
            axes={axes}
            tooltip={true}
            getSeriesStyle={getSeriesStyle}
          />
        </div>
      </CardContent>
    </Card>
  );
};

export default SalesOverviewCard;
