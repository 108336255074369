import React, { useContext } from 'react'
import get from 'lodash/get'
import uniq from 'lodash/uniq'
import styled from 'styled-components'
import Select from '@material-ui/core/Select'
import { MenuItem, Box } from '@material-ui/core'
import { Field, FieldProps } from 'formik'

import { LocaleContext } from '../../../../contexts/Locale'
import { FormikSelectChip } from '../../../../lib/components/FormikSelectChip'
import { MaterialsContext } from '../../../../contexts/Materials'

interface ProductChainValuesFormProps {
  name: string
}

const StyledProductChainValuesForm = styled.div`
  .product-chain-values-form {
    &__label {
      font-size: 16px;
      margin-top: 44px;
      text-transform: uppercase;
      letter-spacing: 0.6px;
    }
  }
`

export const ProductChainValuesForm: React.FunctionComponent<ProductChainValuesFormProps> = ({
  name,
}) => {
  const { localize } = useContext(LocaleContext)
  const { chainMaterials, chainGroupings } = useContext(MaterialsContext)

  const allChainSizes = uniq(chainMaterials.map(({ size }) => size))
  const allChainOptions = uniq(chainMaterials.map(({ size }) => size)).map(
    (size) => ({
      value: size,
      label: `${localize(size)}`,
    }),
  )

  return (
    <Field name={name}>
      {({ form }: FieldProps) => {
        const value = get(form.values, name)
        const type = typeof value === 'string' ? value : 'CUSTOM'

        const changeType = (setting: string) => {
          if (setting === 'CUSTOM') {
            form.setFieldValue(name, allChainSizes)
          } else {
            form.setFieldValue(name, setting)
          }
        }

        return (
          <StyledProductChainValuesForm>
            <h2 className="product-chain-values-form__label">Values</h2>
            <Select
              value={type}
              onChange={(e) => changeType(e.target.value as string)}
              fullWidth
            >
              {chainGroupings.map(({ id, name: groupName }) => (
                <MenuItem key={id} value={id}>
                  {groupName}
                </MenuItem>
              ))}
              <MenuItem value={'CUSTOM'}>Custom</MenuItem>
            </Select>
            {type === 'CUSTOM' && (
              <Box marginTop={1}>
                <FormikSelectChip
                  name={name}
                  options={allChainOptions}
                  multiple
                  fullWidth
                />
              </Box>
            )}
          </StyledProductChainValuesForm>
        )
      }}
    </Field>
  )
}
