import React, { useContext, useState, useEffect, useMemo, useCallback } from 'react';
import {
  TableContainer,
  Paper,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Table,
  Button,
  Box,
  TextField,
} from '@material-ui/core';
import firebase from 'firebase/app';
import { useFormikContext } from 'formik';
import styled from 'styled-components';
import { ceil, isNumber, floor, debounce } from 'lodash';
import { useAuthedCollection } from '@humancollective/human-hooks-firebase';
import {
  ProductConfiguration,
  describePrice,
  CurrencyCode,
  CountryCode,
  DashboardProduct,
  Manufacturer,
  getMargin,
  ProductCostResult,
  getCostForProduct,
  DashboardUtilsBag,
  ValueLineItem,
  ProductOptionValue,
} from '@tiary-inc/tiary-shared';
import TestConfigIcon from '@material-ui/icons/MonetizationOn';
import SyncIcon from '@material-ui/icons/Sync';

import { MaterialsContext } from '../../../../contexts/Materials';
import { LocaleContext } from '../../../../contexts/Locale';
import { CostSummaryModal } from './CostSummaryModal';
import {
  getPricingForProduct,
  adjustMargins,
} from '../../../../utils/getPricingForProduct';
import { getPrice, GetPriceItem } from '../../../../utils/getPrice';
import { FormikPercentageInput } from '../../../../lib/components/FormikInput/FormikPercentageInput';
import { PricingContext } from '../../../../contexts/Pricing';
import { getBaseCosts } from '../../../../utils/getBaseCosts';
import { ManagePreviewsModal } from './ManagePreviewsModal';
import {
  RetailCostSummaryModal,
  RetailCostSummaryValues,
} from './RetailCostSummaryModal';
import { getPreviewConfigurations } from './getPreviewConfigurations';
import { FormikInput } from '../../../../lib/components/FormikInput';

const StyledProductPricingForm = styled.div`
  .product-pricing-form {
    &__manufacturer {
      cursor: pointer;
      transition: background-color 500ms ease;
      &:hover {
        background: #f5f5f5;
      }
    }
  }
`;

export interface ManufacturerCostPreview {
  manufacturer: Manufacturer;
  result: ProductCostResult;
  margin?: number;
  cost?: number;
}

interface CostPreview {
  id: string;
  name: string;
  configuration: ProductConfiguration;
  price?: number | Error;
  manufacturers: ManufacturerCostPreview[];
  retailPricing: GetPriceItem[];
}

export const ProductPricingForm: React.FunctionComponent = () => {
  const { values, setFieldValue, touched, setTouched } = useFormikContext();
  const { materials = [], groupings = [] } = useContext(MaterialsContext);
  const { localize } = useContext(LocaleContext);
  const { pricing } = useContext(PricingContext);
  const [costPreviews, setCostPreviews] = useState<CostPreview[]>([]);
  const [retailCostPreview, setRetailCostPreview] = useState<
    RetailCostSummaryValues | undefined
  >(undefined);
  const [manageModal, setManageModal] = useState(false);
  const [costs, setCosts] = useState<any | null>(null);

  const utilsBag = useMemo<DashboardUtilsBag>(
    () => ({
      materials,
      groupings,
      localize,
      pricing,
    }),
    [materials, groupings, localize, pricing]
  );

  const [selection, setSelection] = useState<ProductCostResult>();

  const dashboardConfigurations = useMemo(
    () => getPreviewConfigurations(values as DashboardProduct, utilsBag),
    [values, utilsBag]
  );

  // store the base costs as a default
  const defaultBaseCost = React.useMemo(() => getBaseCosts(utilsBag), [
    utilsBag,
  ]);

  // console.log('Dashboard cofnig', dashboardConfigurations, values);

  const manufacturers = useAuthedCollection({
    getQueryRef: () =>
      firebase
        .firestore()
        .collection('dashboard')
        .doc('data')
        .collection('manufacturers'),
    includeIds: true,
  }) as Manufacturer[];

  useEffect(() => {
    ;(async () => {
      if (manufacturers && values) {
        const product = values as DashboardProduct;
        const baseCosts = getBaseCosts(utilsBag);
        const costRef = await firebase.firestore().collection('costs').doc(product.id).get();
        let costData: any = null;
        if (costRef.exists) {
          costData = costRef.data();
        }

        const nextCostPreviews: CostPreview[] = dashboardConfigurations.map(
          ({ name, configuration }, index) => {
            // const product = values as DashboardProduct;

            let price: number | Error;
            let retailPricing: GetPriceItem[] = [];
            if (product.pricing) {
              try {
                const { total, items } = getPrice(
                  configuration,
                  product.basePrice || 0,
                  product.pricing
                );
                price = total;
                retailPricing = items;
              } catch (error) {
                price = error as Error;
                console.log(error);
              }
            } else {
              price = new Error('No pricing provided');
            }

            const manufacturerCosts = manufacturers.map(
              (manufacturer: Manufacturer) => {
                const result = getCostForProduct(
                  { configuration, product, manufacturer },
                  utilsBag,
                  { detailed: true }
                );

                if (result.status === 'SUCCESS') {
                  let cost = 0;
                  if (costData && costData[manufacturer.id]) {
                    cost = costData[manufacturer.id][index];
                  } else {
                    cost = result.cost;
                    // result.cost = costData[index];
                  }
                  return {
                    result,
                    cost,
                    margin: isNumber(price)
                      ? getMargin(price - baseCosts, cost)
                      : undefined,
                    manufacturer,
                    retailPricing,
                  };
                } else {
                  return {
                    result,
                    manufacturer,
                  };
                }
              }
            );
            return {
              id: name,
              name,
              configuration,
              price,
              manufacturers: manufacturerCosts,
              retailPricing,
            };
          }
        );
        
        if (costRef.exists) {
          setCosts(costData);
        } else {
          setCosts(nextCostPreviews.reduce((acc: any, cur: any) => {
            cur.manufacturers.forEach(({ manufacturer: { id }, cost }: any) => {
              acc[id] = acc[id] || [];
              acc[id].push(ceil(cost))
            })
            return acc;
          }, {}));
        }

        setCostPreviews(nextCostPreviews);
      }
    })();
  }, [manufacturers, values, utilsBag, dashboardConfigurations]);

  const onGeneratePricing = () => {
    const product = values as DashboardProduct;
    try {
      const manufacturer = manufacturers.find(
        ({ id }) => id === utilsBag.pricing.manufacturerOfReference
      );
      if (!manufacturer) {
        throw new Error('manufacturer of reference not found');
      }
      const [basePrice, pricing] = getPricingForProduct(
        {
          product,
          manufacturer,
        },
        utilsBag
      );
      setFieldValue('basePrice', basePrice);
      setFieldValue('pricing', pricing);
      setTouched({ ...touched, basePrice: true, pricing: true });
    } catch (error) {
      console.log(error);
      setFieldValue('pricing', undefined);
    }
  };

  const showRetailCostSummaryModal = (row: CostPreview): void => {
    const product = values as DashboardProduct;
    const manufacturer = row.manufacturers[0];

    // get the margins and adjust them
    const margins = adjustMargins(
      utilsBag.pricing.margin,
      product?.margins?.base
    );

    // go through each pricing option (METAL_1, STONE_1, etc)
    // and get the matching margin for the configuration option
    const marginsForConfig = {};
    // if we have no option,s use the base margin
    if (product.options.length === 0) {
      marginsForConfig['base'] = margins['base'];
    } else {
      product.options.forEach((productOption) => {
        if (
          productOption.type === 'METAL' ||
          productOption.type === 'STONE' ||
          productOption.type === 'CHAIN'
        ) {
          // get the quality of the config option
          const configOptionQuality = row.configuration[
            productOption.name
          ] as ProductOptionValue;

          // we'll store the key where to fetch the quality for the material margin
          // stone = stoneQuality
          // metal = metalQuality
          let materialMarginKey = '';
          switch (productOption.type) {
            case 'METAL':
              materialMarginKey = 'metalQuality';
              break;
            case 'CHAIN':
              materialMarginKey = 'metalQuality';
              break;
            case 'STONE':
              materialMarginKey = 'stoneQuality';
              break;
            default:
              break;
          }

          // if we have a key, and a valid quality, add the margin for later use
          if (materialMarginKey && configOptionQuality.quality) {
            const materialMargin = margins[materialMarginKey];

            const marginForMaterial =
              (materialMargin && materialMargin[configOptionQuality.quality]) ||
              margins.base;

            marginsForConfig[productOption.name] = marginForMaterial;
          }
        }
      });
    }

    setRetailCostPreview({
      retailPrices: row.retailPricing,
      productCosts: manufacturer.result.items as ValueLineItem[],
      margins: marginsForConfig,
    });
  };

  const changeValues = (key: string, type: string, quality: string, price: string, pricing: any) => {
    const newPricing = {
      ...pricing,
      [key]: pricing[key].map(prices => prices.type === type && prices.quality === quality ? {
        ...prices,
        price: +price,
      } : { ...prices })
    };
    setFieldValue('pricing', newPricing);
  }

  const changeCosts = async (index, manufactureId, cost) => {
    if (costs && costs[manufactureId]) {
      const newCosts = {
        ...costs,
        [manufactureId]: costs[manufactureId].map((oldCost, i) => i !== index ? oldCost : +cost),
      };
      await firebase
        .firestore()
        .collection('costs')
        .doc((values as DashboardProduct).id)
        .set(newCosts)
      setCosts(newCosts);
    }
  }

  const debouncedChangeValues = useCallback(
    debounce(changeValues, 500)
  , []);
  const debouncedChangeCosts = useCallback(
    debounce(changeCosts, 500)
  , [costs]);

  const priceInputs = (values: any) => {
    if (values.pricing) {
      return Object.keys(values.pricing).map((key: string) => {      
        if (key.includes('STONE')) {
          let chunkIndex = 0;
          const options = values.pricing[key].reduce((resultArray, item, index) => {
            chunkIndex = floor(index / 8);
            if (!resultArray[chunkIndex]) {
              resultArray[chunkIndex] = [];
            }
            resultArray[chunkIndex].push(item);
            return resultArray;
          }, []);
          return (
            <Box paddingTop={1} paddingBottom={1} key={key}>
              <h4>{key}</h4>
              <TableContainer component={Paper}>
                {options.map((option, index) => (
                  <Table aria-label="simple table" key={`table_${index}`}>
                    <TableHead>
                      <TableRow>
                        {option.map(({type, quality}) => (
                          <TableCell key={`${type}_${quality}`}>
                            {`${type}-${quality}`}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        {option.map(({type, quality, price}) => (
                          <TableCell key={`${type}_${quality}_${price}`}>
                            <TextField
                              type="number"
                              variant="outlined"
                              defaultValue={ceil(price)}
                              size="small"
                              onChange={(e) => debouncedChangeValues(key, type, quality, e.target.value, values.pricing)}
                            />
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableBody>
                  </Table>
                ))}
              </TableContainer>
            </Box>
          )
        } else {
          return (
            <Box paddingTop={1} paddingBottom={1} key={key}>
              <h4>{key}</h4>
              <TableContainer component={Paper}>
                <Table aria-label="simple table" key={`table_${key}`}>
                  <TableHead>
                    <TableRow>
                      {values.pricing[key].map(({type, quality}) => (
                        <TableCell key={`${type}_${quality}`}>
                          {`${type}-${quality}`}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      {values.pricing[key].map(({type, quality, price}) => (
                        <TableCell key={`${type}_${quality}_${price}`}>
                          <TextField
                            type="number"
                            variant="outlined"
                            defaultValue={ceil(price)}
                            size="small"
                            onChange={(e) => debouncedChangeValues(key, type, quality, e.target.value, values.pricing)}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          );
        }
      });
    } else {
      return <></>
    }
  }

  return (
    <>
      {manageModal && (
        <ManagePreviewsModal
          product={values as DashboardProduct}
          onClose={() => setManageModal(false)}
          onGeneratePreview={(preview) => {
            setManageModal(false);
            setSelection(preview);
          }}
          utilsBag={utilsBag}
        />
      )}
      <StyledProductPricingForm>
        {retailCostPreview && (
          <RetailCostSummaryModal
            retailPrices={retailCostPreview.retailPrices}
            productCosts={retailCostPreview.productCosts}
            margins={retailCostPreview.margins}
            onClose={() => setRetailCostPreview(undefined)}
          />
        )}
        {selection && (
          <CostSummaryModal
            data={selection}
            onClose={() => setSelection(undefined)}
          />
        )}

        <Box paddingTop={1} paddingBottom={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={onGeneratePricing}
            startIcon={<SyncIcon />}
          >
            Generate Pricing
          </Button>
        </Box>

        <Box
          paddingTop={1}
          paddingBottom={1}
          display="flex"
          flexDirection="row"
        >
          <FormikInput
            label={`Base Price (Default: $${defaultBaseCost})`}
            name="basePrice"
            type="number"
          />
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => setFieldValue('basePrice', defaultBaseCost)}
          >
            RESET
          </Button>
        </Box>
        <Box paddingBottom={2}>
          <FormikPercentageInput
            label="Margin Adjustment (+/-)"
            name="margins.base"
          />
        </Box>

        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Configuration</TableCell>
                <TableCell align="right">Retail Price</TableCell>
                {costPreviews[0] &&
                  costPreviews[0].manufacturers.map(({ manufacturer }) => (
                    <TableCell align="right" key={manufacturer.id}>
                      {manufacturer.name}
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {costPreviews.map((row, index) => (
                <TableRow key={row.name}>
                  <TableCell component="th" scope="row">
                    {`${row.name}`}
                  </TableCell>
                  <TableCell
                    align="right"
                    className="product-pricing-form__manufacturer"
                    onClick={() => showRetailCostSummaryModal(row)}
                  >
                    {typeof row.price === 'number'
                      ? describePrice(row.price, {
                          currencyCode: CurrencyCode.UnitedStatesDollar,
                          countryCode: CountryCode.UnitedStates,
                        })
                      : 'ERROR'}
                  </TableCell>
                  {row.manufacturers.map(
                    ({ manufacturer, cost, margin, result }) => (
                      <TableCell
                        key={manufacturer.id}
                        className="product-pricing-form__manufacturer"
                        align="right"
                        onClick={() => setSelection(result)}
                      >
                        {typeof cost === 'number' && (
                          <>
                            <TextField
                              type="number"
                              variant="outlined"
                              defaultValue={cost.toFixed(2)}
                              size="small"
                              onChange={(e) => debouncedChangeCosts(index, manufacturer.id, e.target.value)}
                            />
                          </>
                        )}
                        <Box>
                          {typeof cost === 'number'
                            ? describePrice(cost, {
                                currencyCode: CurrencyCode.UnitedStatesDollar,
                                countryCode: CountryCode.UnitedStates,
                              })
                            : 'ERROR'}
                          {margin && ` (${Math.round(margin * 100)}%)`}
                        </Box>
                      </TableCell>
                    )
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box paddingTop={1} paddingBottom={2}>
          <Button
            onClick={() => setManageModal(true)}
            color="primary"
            startIcon={<TestConfigIcon />}
          >
            Test Configuration
          </Button>
        </Box>

        {priceInputs(values)}
      </StyledProductPricingForm>
    </>
  );
};
