import React, { useState } from 'react';
import firebase from 'firebase';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import Modal from '@material-ui/core/Modal';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import { createTimestamp } from 'fastis';

import { useHistory } from 'react-router-dom';
import { Typography } from '@material-ui/core';

export const StyledAddProductModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;

  .add-product-modal {
    &__wrapper {
      max-width: 70%;
      width: 600px;
      padding: 48px;
      background: white;
    }
  }
`;

interface AddProductModalProps {
  onClose: () => void;
}

export const AddProductModal: React.FunctionComponent<AddProductModalProps> = ({
  onClose,
}) => {
  const [sku, setSku] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();

  const onAddItem = async () => {
    try {
      setErrorMessage('');
      if (!sku) {
        throw new Error('you must provide a SKU');
      }
      const ref = firebase
        .firestore()
        .collection('dashboard')
        .doc('data')
        .collection('products')
        .doc(sku);
      const { exists } = await ref.get();
      if (exists) {
        throw new Error(`SKU ${sku} already exists`);
      }
      await ref.set({ dateAdded: createTimestamp(), id: sku });
      history.push(`/products/edit/${ref.id}`);
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
      }
    }
  };

  return (
    <StyledAddProductModal open onClose={onClose}>
      <div className="add-product-modal__wrapper">
        <FormControl>
          <InputLabel>SKU</InputLabel>
          <Input
            placeholder="XXXX-0000"
            value={sku}
            onChange={(e) => setSku(e.target.value)}
          />
        </FormControl>
        {errorMessage && <Typography>{errorMessage}</Typography>}
        <br />
        <Button onClick={onAddItem}>Add Product</Button>
      </div>
    </StyledAddProductModal>
  );
};
