import React, { FunctionComponent } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import styled from 'styled-components'

import { Breadcrumb, Breadcrumbs } from '../../Breadcrumbs'

export interface SearchableTemplate {
  searchKeys?: string[]
}

export interface TemplateData {
  data: any[] | null
}

const StyledTemplateMeta = styled(Box)``

export interface TemplateMetaProps {
  title: string
  breadcrumbs?: Breadcrumb[]
}

export const TemplateMeta: FunctionComponent<TemplateMetaProps> = ({
  title,
  breadcrumbs,
}) => (
  <StyledTemplateMeta paddingY={2}>
    <Typography variant="h4" gutterBottom>
      {title}
    </Typography>
    {breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}
  </StyledTemplateMeta>
)
