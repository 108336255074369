import { Insight } from '@tiary-inc/tiary-shared';
import firebase from 'firebase';

import { createCallableFunction } from './createCallableFunction';

export const fetchInsights = async (dateRange: {
  startDate: Date;
  endDate: Date;
}): Promise<Insight[]> => {
  try {
    const callableFetchInsights = createCallableFunction('fetchInsights');

    const { data } = await callableFetchInsights({
      dateRange: {
        startDate: dateRange.startDate.getTime(),
        endDate: dateRange.endDate.getTime(),
      },
    });

    // For some reason, timestamps are lost over HTTPS
    const patchedInsights: Insight[] = data.insights.map((tempInsight) => ({
      ...tempInsight,
      dateAdded: new firebase.firestore.Timestamp(
        tempInsight.dateAdded._seconds,
        tempInsight.dateAdded._nanoseconds
      ),
    }));

    return patchedInsights;
  } catch (error) {
    console.log('Error fetching insights', error);
    return [];
  }
};
