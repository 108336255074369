import React, { useContext, useState } from 'react';
import { Field, FieldProps } from 'formik';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import styled from 'styled-components';
import AlertIcon from '@material-ui/icons/ErrorOutline';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import set from 'lodash/set';
import ConstraintsIcon from '@material-ui/icons/PhotoSizeSelectLarge';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';

import { MaterialsContext } from '../../../../contexts/Materials';
import {
  getExpectedRenders,
  getFilenameForRender,
} from '../../../../utils/getRendersForProduct';
import { PricingContext } from '../../../../contexts/Pricing';
import { Box, Button } from '@material-ui/core';
import RenderUploader from './RenderUploader';
import { ConstraintsModal } from './ConstraintsModal';
import RenderDownloader from './RenderDownloader';
import RenderDeleter from './RenderDelete';
import { buildImgixUrl } from '../../../../lib/utils/imgix';

const StyledProductRendersForm = styled.div`
  .product-renders-form {
    &__missing-render {
      background-color: white;
      border-bottom: 1px solid #efefef;
    }
    &__toggle {
      background-color: white;
      cursor: pointer;
      transition: background-color 500ms ease;
      &:hover {
        background: #f5f5f5;
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  large: {
    width: 150,
    height: 150,
    border: `1px solid #efefef`,
    marginBottom: theme.spacing(2),
  },
}));

export const ProductRendersForm: React.FunctionComponent<any> = ({
  name,
  sku,
}) => {
  const { materials = [], groupings = [] } = useContext(MaterialsContext);
  const { pricing } = useContext(PricingContext);
  const [expanded, setExpanded] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const classes = useStyles();

  const getReferenceRender = (formValues) => {
    // if a product has no options and a base render, use that
    if (formValues.options.length === 0 && formValues.renders?.BASE) {
      return formValues.renders?.BASE || '';
    } else if (formValues.renders?.METAL_1?.SILVER) {
      // other wise just use the metal render images
      return formValues.renders?.METAL_1?.SILVER;
    } else if (formValues.renders?.METAL_1?.GOLD_YELLOW) {
      return formValues.renders?.METAL_1?.GOLD_YELLOW;
    }

    // console.error('Could not generate a reference render (ProductRendersForm)');

    return undefined;
  };

  return (
    <StyledProductRendersForm>
      <Field name={name}>
        {({ form }: FieldProps) => {
          const renders = getExpectedRenders(form.values, {
            materials,
            groupings,
            pricing,
          }).filter(({ missing }) => !!missing);

          const renderConstraints = form.values.renderConstraints || {};
          const referenceRender = getReferenceRender(form.values);

          return (
            <>
              {modalOpen && typeof referenceRender === 'string' && (
                <ConstraintsModal
                  initialValues={{
                    cursorY: 1600,
                    cursorX: 1000,
                    zoom: 1,
                    ...renderConstraints,
                  }}
                  onChange={(nextConstraints: any) =>
                    form.setFieldValue('renderConstraints', nextConstraints)
                  }
                  onClose={() => setModalOpen(false)}
                  sku={sku}
                />
              )}
              <Box
                marginTop={1}
                marginBottom={1}
                display="flex"
                flexDirection="column"
              >
                {referenceRender && (
                  <Avatar
                    className={classes.large}
                    src={buildImgixUrl(`renders/${referenceRender}`, {
                      height: 512,
                      width: 512,
                    })}
                  />
                )}
                <Box display="flex" flexDirection="row">
                  <RenderUploader
                    expectedRenders={
                      renders.map(({ option, type }) => ({
                        sku,
                        option,
                        type,
                      })) || []
                    }
                    onUploadSuccess={(renderMap) => {
                      const { renders = {} } = form.values;
                      Object.keys(renderMap).forEach((optionPath) => {
                        set(renders, optionPath, renderMap[optionPath]);
                      });
                      form.setFieldValue('renders', renders);
                      form.setFieldTouched('renders', true);
                    }}
                  />
                  <RenderDownloader
                    sku={form.values.id}
                    renders={form.values.renders}
                  />
                  <RenderDeleter
                    renders={form.values.renders}
                    setFieldValue={form.setFieldValue}
                  />
                </Box>
                <br />
              </Box>
              <Button
                startIcon={<ConstraintsIcon />}
                onClick={() => setModalOpen(true)}
                color="primary"
              >
                Set Constraints
              </Button>
              {renders.length > 0 && !expanded && (
                <ListItem
                  className="product-renders-form__toggle"
                  onClick={() => setExpanded(true)}
                >
                  <ListItemText
                    primary={`Missing ${renders.length} Render(s)`}
                  />
                </ListItem>
              )}
              {renders.length > 0 && expanded && (
                <List>
                  {renders.map(({ option, type }) => (
                    <ListItem
                      key={`${option}.${type}`}
                      className="product-renders-form__missing-render"
                    >
                      <ListItemIcon>
                        <AlertIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Missing Render"
                        secondary={getFilenameForRender({ sku, option, type })}
                      />
                    </ListItem>
                  ))}
                  <ListItem
                    className="product-renders-form__toggle"
                    onClick={() => setExpanded(false)}
                  >
                    <ListItemText primary={`Collapse`} />
                  </ListItem>
                </List>
              )}
            </>
          );
        }}
      </Field>
    </StyledProductRendersForm>
  );
};
