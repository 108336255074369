import firebase from 'firebase';

export const createCallableFunction = (
  functionName: string
): CallableFunction => {
  const functionUrl = process.env.REACT_APP_FIREBASE_FUNCTION_URL;
  if (functionUrl) {
    firebase.functions().useFunctionsEmulator(functionUrl);
  }

  const callable = firebase.functions().httpsCallable(functionName);

  return callable;
};
