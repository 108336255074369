import React, { useState } from 'react';
import styled from 'styled-components';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import { useAuth } from '@humancollective/human-hooks-firebase';
import { useHistory } from 'react-router-dom';

import { NavbarMenu } from './Menu';
import { routes } from '../../configuration/routes';
import isDevelopment from '../../utils/isDevelopment';
import { makeStyles } from '@material-ui/core';

interface NavbarProps {
  title: string;
}

const StyledNavbar = styled(AppBar)`
  .navbar {
    &__spacer {
      flex-grow: 1;
      display: flex;
    }
  }
`;

const useStyles = makeStyles(() => ({
  root: (props: { primary: string; secondary: string }) => ({
    backgroundColor: props.primary,
    color: props.secondary,
    '& button': {
      color: props.secondary,
    },
  }),
}));

const Navbar: React.FunctionComponent<NavbarProps> = ({ title }) => {
  const { isLoggedIn, firebaseUser } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const history = useHistory();

  const classes = useStyles({
    primary: isDevelopment ? '#715E73' : '#FAFAFA',
    secondary: isDevelopment ? 'white' : 'black',
  });

  return isLoggedIn ? (
    <StyledNavbar position="static" color="transparent">
      <NavbarMenu
        isOpen={isMenuOpen}
        onClose={() => {
          setIsMenuOpen(false);
        }}
      />
      <Toolbar className={classes.root}>
        <IconButton edge="start" onClick={() => setIsMenuOpen(true)}>
          <MenuIcon />
        </IconButton>
        <Typography className="navbar__spacer" variant="h6">
          {title} ({isDevelopment ? 'development' : 'production'})
        </Typography>
        {firebaseUser && (
          <Typography className="navbar__account-name" variant="subtitle1">
            {firebaseUser.email}
          </Typography>
        )}
        <IconButton
          edge="end"
          aria-label="account of current user"
          aria-haspopup="true"
          color="inherit"
          onClick={() => history.push(routes.logout.path)}
        >
          <AccountCircle />
        </IconButton>
      </Toolbar>
    </StyledNavbar>
  ) : null;
};

export default Navbar;
