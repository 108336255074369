import React from 'react'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import CardActionArea from '@material-ui/core/CardActionArea'
import { useHistory } from 'react-router-dom'

/**
 * Locale Card View
 * Displays a single locale as a card.
 * e.g., for use in a gallery template.
 */

interface LocaleCardViewProps {
  locale: { name: string; id: string }
}

export const LocaleCardView: React.FunctionComponent<LocaleCardViewProps> = ({
  locale,
}) => {
  const history = useHistory()
  return (
    <Card>
      <CardActionArea
        onClick={() => {
          history.push(`/locales/edit/${locale.id}`)
        }}
      >
        <Box paddingX={3} paddingY={2}>
          <Typography variant="h6" className="locale-card__name">
            {locale.name}
          </Typography>
        </Box>
      </CardActionArea>
    </Card>
  )
}
