import React, { FunctionComponent } from 'react'
import InputAdornment from '@material-ui/core/InputAdornment'

import { FormikLocationSelector } from '../../LocationSelector/FormikLocationSelector'
import { FormikImageUploader } from '../../ImageUploader/FormikImageUploader'
import { FormikInput } from '../../FormikInput'
import { FormikSwitch } from '../../FormikSwitch'
import { FormikDateTime } from '../../FormikDateTime'
import { EditorFormGroupField } from './GroupField'
import {
  EditorFormDynamicListField,
  EditorFormDynamicListFieldType,
} from './DynamicListField'
import { EditorFormHeadingField } from './HeadingField'
import { EditorFormDividerField } from './DividerField'
import { FormikSelectChip } from '../../FormikSelectChip'
import { FormikSelect } from '../../FormikSelect'
import { FormikPercentageInput } from '../../FormikInput/FormikPercentageInput'
import { ImportExportField } from './ImportExportField'

export enum EditorFormFieldType {
  ImportExport = 'IMPORT_EXPORT',
  FieldComponent = 'FIELD_COMPONENT',
  DynamicList = 'DYNAMIC_LIST',
  Heading = 'HEADING',
  Image = 'IMAGE',
  SelectChip = 'SELECT_CHIP',
  Select = 'SELECT',
  Switch = 'SWITCH',
  Divider = 'DIVIDER',
  Datetime = 'DATETIME',
  Date = 'DATE',
  Location = 'LOCATION',
  Group = 'GROUP',
  Money = 'MONEY',
  Text = 'TEXT',
  Number = 'NUMBER',
  Hidden = 'HIDDEN',
  Percent = 'PERCENT',
  Other = 'OTHER',
}

export type EditorFormField =
  | {
      type: EditorFormFieldType.Heading
      label: string
      name?: string
      description?: string
    }
  | {
      type: EditorFormFieldType.Hidden
      name: string
      value: any
    }
  | {
      type: EditorFormFieldType.Image
      name: string
      label: string
    }
  | {
      type: EditorFormFieldType.Switch
      name: string
      label: string
      inverted?: boolean
    }
  | {
      type: EditorFormFieldType.SelectChip
      name: string
      label: string
      options: string[]
    }
  | {
      type: EditorFormFieldType.Divider
      name?: string
    }
  | {
      type: EditorFormFieldType.Datetime
      name: string
      label: string
    }
  | {
      type: EditorFormFieldType.Date
      name: string
      label: string
    }
  | {
      type: EditorFormFieldType.Location
      name: string
      label: string
    }
  | {
      type: EditorFormFieldType.ImportExport
      filename: string
    }
  | {
      type: EditorFormFieldType.Money
      name: string
      label: string
      description?: string
    }
  | {
      type: EditorFormFieldType.Text
      name: string
      label: string
      description?: string
      multiline?: boolean
    }
  | {
      type: EditorFormFieldType.Number
      name: string
      label: string
      description?: string
    }
  | {
      type: EditorFormFieldType.Percent
      name: string
      label: string
      description?: string
    }
  | {
      type: EditorFormFieldType.Group
      name?: string
      fields: EditorFormField[]
      label: string
      description?: string
    }
  | ({
      type: EditorFormFieldType.FieldComponent
      FieldComponent: FunctionComponent<EditorFormField & any>
      name: string
    } & any)
  | {
      type: EditorFormFieldType.DynamicList
      name: string
      label: string
      description?: string
      types: EditorFormDynamicListFieldType[]
      getItemLabel?: (
        spec: EditorFormDynamicListFieldType | null,
        value: any,
      ) => string | React.ReactNode
      oneOfEach?: boolean
      options?: Array<{ label: string; action: () => void }>
    }

export const getFormField = (field: EditorFormField, index: number) => {
  switch (field.type) {
    case EditorFormFieldType.FieldComponent:
      return <field.FieldComponent key={field.name} {...field} />
    case EditorFormFieldType.DynamicList:
      return <EditorFormDynamicListField key={field.name} {...field} />
    case EditorFormFieldType.Hidden:
      return <input key={field.name} type="hidden" {...field} />
    case EditorFormFieldType.Image:
      return <FormikImageUploader key={field.name} {...field} />
    case EditorFormFieldType.Switch:
      return <FormikSwitch key={field.name} {...field} />
    case EditorFormFieldType.Divider:
      return <EditorFormDividerField key={`divider-${index}`} />
    case EditorFormFieldType.Datetime:
      return <FormikDateTime key={field.name} {...field} showTime />
    case EditorFormFieldType.Date:
      return <FormikDateTime key={field.name} {...field} />
    case EditorFormFieldType.Location:
      return <FormikLocationSelector key={field.name} {...field} />
    case EditorFormFieldType.Group:
      return <EditorFormGroupField key={field.name} {...field} />
    case EditorFormFieldType.ImportExport:
      return <ImportExportField key={field.filename} {...field} />
    case EditorFormFieldType.Heading:
      return (
        <EditorFormHeadingField key={`heading-${field.label}`} {...field} />
      )
    case EditorFormFieldType.Select:
      return (
        <FormikSelect
          key={field.name}
          name={field.name}
          options={field.options}
          fullWidth
          {...field}
        />
      )
    case EditorFormFieldType.SelectChip:
      return (
        <FormikSelectChip
          key={field.name}
          name={field.name}
          options={field.options}
          multiple
          fullWidth
          {...field}
        />
      )
    case EditorFormFieldType.Money:
      return (
        <FormikInput
          key={field.name}
          helperText={field.description}
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
          endAdornment={<InputAdornment position="end">USD</InputAdornment>}
          fullWidth
          {...field}
          type="number"
        />
      )
    case EditorFormFieldType.Number:
      return (
        <FormikInput
          key={field.name}
          helperText={field.description}
          fullWidth
          {...field}
          type="number"
        />
      )
    case EditorFormFieldType.Percent:
      return (
        <FormikPercentageInput
          key={field.name}
          helperText={field.description}
          endAdornment={<InputAdornment position="end">%</InputAdornment>}
          fullWidth
          {...field}
          type="number"
        />
      )
    default:
      return (
        <FormikInput
          key={field.name}
          helperText={field.description}
          fullWidth
          {...field}
        />
      )
  }
}
