import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import firebase from 'firebase/app';
import { useAuthedDocument } from '@humancollective/human-hooks-firebase';

import { ALL_TABS_LIST } from '../../routes';

import { routes } from '../../configuration/routes';

interface NavbarMenuProps {
  isOpen: boolean;
  onClose: () => void;
}

export interface NavbarRouteProps {
  title: string;
  path: string;
  Icon?: React.FunctionComponent;
}

const StyledNavbarMenu = styled(List)`
  width: 80vw;
  max-width: 300px;
`;

export const NavbarMenu: React.FunctionComponent<NavbarMenuProps> = ({
  isOpen,
  onClose,
}) => {
  const history = useHistory();
  const dashboardUser = useAuthedDocument({
    getQueryRef: (uid) =>
      firebase
        .firestore()
        .collection('dashboard')
        .doc('users')
        .collection('preferences')
        .doc(uid),
  });

  // order the nav bar items
  const navigationTabs = ALL_TABS_LIST.map((routeName) =>
    dashboardUser?.tabs?.find((tabName) => tabName === routeName)
  );

  return (
    <Drawer anchor={'left'} open={isOpen} onClose={onClose}>
      <div role="presentation" onClick={onClose} onKeyDown={onClose}>
        <StyledNavbarMenu>
          {navigationTabs.map((routeName: string) => {
            const tab = routes[routeName];
            return tab ? (
              <ListItem
                button
                key={tab.path}
                onClick={() => {
                  history.push(tab.path);
                }}
              >
                {tab.Icon && (
                  <ListItemIcon>
                    <tab.Icon />
                  </ListItemIcon>
                )}
                <ListItemText primary={tab.title} />
              </ListItem>
            ) : null;
          })}
        </StyledNavbarMenu>
      </div>
    </Drawer>
  );
};
