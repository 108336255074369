import * as React from 'react';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { Button } from '@material-ui/core';
/*
import firebase from 'firebase';
import {
  Product,
  csvFromPopularProducts,
  Order,
} from '@tiary-inc/tiary-shared';
import { saveAs } from 'file-saver';
import flattenDeep from 'lodash/flattenDeep';
import sortBy from 'lodash/sortBy';

import * as Contentful from '../../utils/contentful';
*/

interface ExportablePopularProduct {
  sku: string;
  amount: number;
  name: string;
  slug: string;
}

export const DownloadPopularProductsButton: React.FunctionComponent = () => {
  const [inProgress] = React.useState(false);

  /*
  const exportProducts = async () => {
    setInProgress(true);

    // Create the contentful instance
    const contentfulEnv = await Contentful.getInstance();

    // get all the order products
    const allOrderDocs = await firebase.firestore().collection('orders').get();
    const allOrders: Order[] = allOrderDocs.docs.map(
      (orderDoc) => orderDoc.data() as Order
    );
    const allOrderProducts = allOrders.map((order) => order.products);
    // flatten it so its [[blah, blah], [blah]] => [blah, blah blah]
    const orderProductsFlattened = flattenDeep(allOrderProducts);

    // All the product entries
    const allProductEntries = await contentfulEnv.getEntries({
      content_type: 'product',
      limit: 999,
    });

    console.log('Contentful entries:', allProductEntries.items.length, 'fb: ');

    // where'll store the end result
    const popularProductsToExport: ExportablePopularProduct[] = [];

    // adds the items that have been purchased
    for (const orderProduct of orderProductsFlattened) {
      // check if we've added the product yet
      const doesProductExist = popularProductsToExport.find(
        (popularProduct) => popularProduct.sku === orderProduct.sku
      );

      // check if we have a contentful entry for that SKU
      const entryWithSku = allProductEntries.items.find(
        (contentfulEntry) =>
          contentfulEntry.fields.sku['en-US'] === orderProduct.sku
      );

      if (!doesProductExist && entryWithSku) {
        const allOrderedProductsWithSKU = orderProductsFlattened.filter(
          (productFromOrder) => productFromOrder.sku === orderProduct.sku
        );

        const exportableOrderProduct: ExportablePopularProduct = {
          sku: orderProduct.sku,
          amount: allOrderedProductsWithSKU.length,
          name: entryWithSku.fields.name['en-US'],
          slug: entryWithSku.fields.slug['en-US'],
        };

        popularProductsToExport.push(exportableOrderProduct);
      }
    }

    // get all the products
    const allProductsDocs = await firebase
      .firestore()
      .collection('products')
      .get();
    const allProducts: Product[] = allProductsDocs.docs.map(
      (orderDoc) => orderDoc.data() as Product
    );

    // adds the items that have NOT been purchased
    for (const product of allProducts) {
      const hasProductBeenAdded = popularProductsToExport.find(
        (popProduct) => popProduct.sku === product.sku
      );

      // check if we have a contentful entry for that SKU
      const entryWithSku = allProductEntries.items.find(
        (contentfulEntry) => contentfulEntry.fields.sku['en-US'] === product.sku
      );

      if (!hasProductBeenAdded && entryWithSku) {
        const exportableOrderProduct: ExportablePopularProduct = {
          sku: product.sku,
          amount: 0,
          name: entryWithSku.fields.name['en-US'],
          slug: entryWithSku.fields.slug['en-US'],
        };

        popularProductsToExport.push(exportableOrderProduct);
      }
    }

    // sort it so the lowest is highest, then reverse so the lowest is at the end
    const resultsToExport = sortBy(popularProductsToExport, 'amount').reverse();

    setInProgress(false);

    const csv = csvFromPopularProducts(resultsToExport);
    const ordersBlob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    saveAs(ordersBlob, 'popularProductsExport.csv');
  };
  */

  return (
    <Button
      onClick={() => alert('Deprecated. Please talk too a developer.')}
      startIcon={<ImportExportIcon />}
    >
      {!inProgress ? 'Export (by popularity)' : 'In progres....'}
    </Button>
  );
};
