import React, { FunctionComponent, useState, useContext } from 'react';
import Modal from '@material-ui/core/Modal';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import firebase from 'firebase/app';
import {
  DashboardProduct,
  DashboardProductMetalOption,
  DashboardProductStringOption,
  DashboardUtilsBag,
  MetalMaterial,
  DashboardProductStoneOption,
  Manufacturer,
  getCostForProduct,
  ProductCostResult,
  DashboardProductChainOption,
  ProductOptionType,
} from '@tiary-inc/tiary-shared';
import { LocaleContext } from '../../../../contexts/Locale';
import {
  expandOptionValues,
  expandChainLengths,
} from '../../../../utils/expandValues';
import { expandStoneTypeQualities } from '../../../../utils/stoneUtils';
import { useAuthedCollection } from '@humancollective/human-hooks-firebase';

const StyledManagePreviewsModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;

  .cost-summary-modal {
    &__wrapper {
      max-width: 70%;
      width: 600px;
      padding: 48px;
      background: white;
    }
  }
`;

interface ManagePreviewsModalProps {
  onClose: () => void;
  product: DashboardProduct;
  utilsBag: DashboardUtilsBag;
  onGeneratePreview: (preview: ProductCostResult) => void;
}

export const ManagePreviewsModal: FunctionComponent<ManagePreviewsModalProps> = ({
  onClose,
  onGeneratePreview,
  product,
  utilsBag,
}) => {
  const [manufacturer, setManufacturer] = useState<string>();
  const [configuration, setConfiguration] = useState<any>({});
  const { localize } = useContext(LocaleContext);

  const manufacturers = useAuthedCollection({
    getQueryRef: () =>
      firebase
        .firestore()
        .collection('dashboard')
        .doc('data')
        .collection('manufacturers'),
    includeIds: true,
    defaultValue: [],
  }) as Manufacturer[];

  const showPreview = () => {
    const referenceManufacturer = (manufacturers || []).find(
      ({ id }) => id === manufacturer
    );
    if (!referenceManufacturer) {
      return {
        status: 'ERROR',
        label: 'No Manufacturer Selected',
      };
    }
    const result = getCostForProduct(
      { configuration, product, manufacturer: referenceManufacturer },
      utilsBag,
      { detailed: true }
    );
    onGeneratePreview(result);
  };

  if (!manufacturers.length) {
    return null;
  }

  return (
    <StyledManagePreviewsModal open onClose={onClose}>
      <div className="cost-summary-modal__wrapper">
        <Box paddingTop={2}>
          <FormControl fullWidth>
            <InputLabel>Manufacturer</InputLabel>
            <Select
              value={manufacturer}
              onChange={(e) => {
                setManufacturer(e.target.value as string);
              }}
            >
              {manufacturers.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {product.options.map((option) => {
          if (option.type === 'METAL') {
            const metalOption = option as DashboardProductMetalOption;
            const metalOptionValues = expandOptionValues(
              metalOption.values,
              utilsBag
            ) as MetalMaterial[];
            return (
              <Box paddingTop={2} key={metalOption.name}>
                <FormControl fullWidth>
                  <InputLabel>{metalOption.label}</InputLabel>
                  <Select
                    value={configuration[metalOption.name]}
                    onChange={(e) => {
                      const [type, quality] = (e.target.value as string).split(
                        '.'
                      );
                      setConfiguration({
                        ...configuration,
                        // also update the chain quality
                        ...(configuration?.CHAIN_LENGTH && {
                          CHAIN_LENGTH: {
                            ...configuration.CHAIN_LENGTH,
                            quality,
                          },
                        }),
                        [metalOption.name]: { type, quality },
                      });
                    }}
                  >
                    {metalOptionValues.map(({ type, quality }) => (
                      <MenuItem
                        key={[type, quality].join('.')}
                        value={[type, quality].join('.')}
                      >
                        {localize(type)} {localize(quality)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            );
          } else if (option.type === 'STONE') {
            const stoneOption = option as DashboardProductStoneOption;
            const stoneOptionValues = expandStoneTypeQualities(
              stoneOption.values.types,
              utilsBag
            );
            return (
              <Box paddingTop={2} key={stoneOption.name}>
                <FormControl fullWidth>
                  <InputLabel>{stoneOption.label}</InputLabel>
                  <Select
                    value={configuration[stoneOption.name]}
                    onChange={(e) => {
                      const [type, quality] = (e.target.value as string).split(
                        '.'
                      );
                      setConfiguration({
                        ...configuration,
                        [stoneOption.name]: { type, quality },
                      });
                    }}
                  >
                    {stoneOptionValues.map(({ type, quality }) => (
                      <MenuItem
                        key={[type, quality].join('.')}
                        value={[type, quality].join('.')}
                      >
                        {localize(type)} {localize(quality)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            );
          } else if (option.type === 'CHAIN') {
            const chainOption = option as DashboardProductChainOption;
            const chainOptionValues = expandChainLengths(
              chainOption.values,
              utilsBag
            );
            return (
              <Box paddingTop={2} key={chainOption.name}>
                <FormControl fullWidth>
                  <InputLabel>{chainOption.label}</InputLabel>
                  <Select
                    value={configuration[chainOption.name]}
                    onChange={(e) => {
                      setConfiguration({
                        ...configuration,
                        [chainOption.name]: {
                          type: e.target.value as string,
                          quality:
                            configuration?.METAL_1?.quality || 'STERLING',
                        },
                      });
                    }}
                  >
                    {chainOptionValues.map((value) => (
                      <MenuItem
                        key={value}
                        value={value.replace('_INCHES', '')}
                      >
                        {localize(value)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            );
          } else if (option.type === 'ENGRAVING') {
            const engravingOption = option as DashboardProductStringOption;
            return (
              <Box paddingTop={2} key="engraving">
                <FormControl fullWidth>
                  <TextField
                    label="Engraving"
                    value={configuration[engravingOption.name]}
                    placeholder="e.x Emily, RD, 2001, etc."
                    inputProps={{
                      minlength: engravingOption.minLength,
                      maxlength: engravingOption.maxLength,
                    }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setConfiguration({
                        ...configuration,
                        [ProductOptionType.Engraving]: event.target.value,
                      });
                    }}
                  />
                </FormControl>
              </Box>
            );
          } else if (option.type === ProductOptionType.Quantity) {
            return (
              <Box paddingTop={2} key="quanity">
                <FormControl fullWidth>
                  <InputLabel>Quantity</InputLabel>
                  <Select
                    value={configuration[option.name]}
                    onChange={(e) => {
                      setConfiguration({
                        ...configuration,
                        [ProductOptionType.Quantity]: e.target.value,
                      });
                    }}
                  >
                    {option.values.map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            );
          } else {
            return null;
          }
        })}
        <Button
          onClick={() => showPreview()}
          variant="contained"
          color="primary"
          disabled={Object.keys(configuration).length === 0}
        >
          Get Preview
        </Button>
      </div>
    </StyledManagePreviewsModal>
  );
};
