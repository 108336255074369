import React, { FunctionComponent, useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'
import Pagination from '@material-ui/lab/Pagination'

import {
  useAuthedCollection,
  UseAuthedCollectionOptions,
} from '@humancollective/human-hooks-firebase'
import AuthenticatedContent from '../../components/AuthenticatedContent'
import { Breadcrumbs, Breadcrumb } from '../../components/Breadcrumbs'
import Loading from '../../components/Loading'

interface GalleryTemplateProps {
  title?: string
  breadcrumbs?: Breadcrumb[]
  renderItem: (item: any) => React.ReactNode
  onAddItem?: (itemId: string) => void
}

type CombinedProps = GalleryTemplateProps & UseAuthedCollectionOptions

const StyledGalleryTemplate = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .gallery-template {
    &__meta {
      flex: 0;
    }
    &__body {
      flex: 1;
      overflow-y: auto;
      border-top: 1px solid #e0e0e0;
      background-color: #f5f5f5;
      margin-top: 0;
      margin-bottom: 0;
      padding-left: 24px;
      padding-right: 24px;
    }
    &__search-container {
      display: flex;
      align-items: center;
    }
    &__search-icon {
      margin-right: 8px;
    }
    &__pagination-container {
      border-top: 1px solid #e4e4e4;
      display: flex;
      justify-content: center;
    }
  }
`

const ITEMS_PER_PAGE = 18

export const GalleryTemplate: FunctionComponent<CombinedProps> = ({
  children,
  renderItem,
  getQueryRef,
  onAddItem = () => {},
  title,
  breadcrumbs,
  ...rest
}) => {
  const [pageIndex, setPageIndex] = useState(1)

  const [selectedItem, setSelectedItem] = useState()
  const data = useAuthedCollection({
    getQueryRef,
    ...rest,
  })

  useEffect(() => {
    if (!selectedItem && data && data[0]) {
      setSelectedItem(data[0])
    }
  }, [data, selectedItem])

  const pageCursor = (pageIndex - 1) * ITEMS_PER_PAGE
  const pageCount = Math.ceil((data?.length || 1) / ITEMS_PER_PAGE)

  return (
    <AuthenticatedContent>
      <StyledGalleryTemplate>
        <Box
          className="gallery-template__meta"
          paddingLeft={3}
          paddingBottom={4}
          paddingTop={2}
        >
          <Typography variant="h4" gutterBottom>
            {title}
          </Typography>
          {breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}
        </Box>
        {data ? (
          <Grid className="gallery-template__body" container spacing={3}>
            {data
              .slice(pageCursor, pageCursor + ITEMS_PER_PAGE)
              .map((item: any) => (
                <Grid item sm={4}>
                  {renderItem(item)}
                </Grid>
              ))}
          </Grid>
        ) : (
          <Loading />
        )}
        {pageCount > 1 && (
          <Box className="gallery-template__pagination-container" padding={3}>
            <Pagination
              count={pageCount}
              color="primary"
              onChange={(_e, val) => setPageIndex(val)}
              page={pageIndex}
            />
          </Box>
        )}
      </StyledGalleryTemplate>
    </AuthenticatedContent>
  )
}
