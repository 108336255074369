import csvStringify from 'csv-stringify/lib/sync'
import find from 'lodash/find'
import get from 'lodash/get'
import set from 'lodash/set'
import findIndex from 'lodash/findIndex'

import {
  ModificationType,
  Modification,
  ChangeModification,
  AddModification,
  RemoveModification,
} from './types'

interface ExecuteChangeSetArgs {
  changes: Modification[]
  collectionRef: firebase.firestore.CollectionReference
  onProgress?: (progress: number) => void
}

export const executeChangeSet = async ({
  changes,
  collectionRef,
  onProgress,
}: ExecuteChangeSetArgs) => {
  const changeCount = changes.length
  for (let i = 0; i < changes.length; i++) {
    const change = changes[i]
    switch (change.type) {
      case ModificationType.Add:
        if (change.id) {
          await collectionRef.doc(change.id).set(change.newData)
        } else {
          await collectionRef.add(change.newData)
        }
        break
      case ModificationType.Remove:
        await collectionRef.doc(change.oldData.id).delete()
        break
      case ModificationType.Change:
        await collectionRef.doc(change.newData.id).set(change.newData)
        break
      default:
        break
    }
    if (onProgress) {
      onProgress(Math.floor((i / changeCount) * 100))
    }
  }
}

export const generateChangeSet = async <T extends { id: string } = any>(
  oldValues: T[],
  newValues: T[],
  properties: string[],
): Promise<Array<Modification<T>>> => {
  const added: AddModification[] = []
  newValues
    .filter(({ id }) => !id)
    .forEach((value) => {
      delete value.id
      const oldValuesWithoutIds = oldValues.map(({ id, ...oldRest }) => oldRest)
      const match = find(oldValuesWithoutIds, value)
      if (!match) {
        added.push({ type: ModificationType.Add, newData: value })
      }
    })
  newValues
    .filter(({ id }) => !!id)
    .forEach((value) => {
      const { id } = value
      const matchingId = find(oldValues, { id } as { id: string })
      // if there's no matching ID then this is an add at the ID
      if (!matchingId) {
        added.push({ type: ModificationType.Add, newData: value, id })
      }
    })

  const removed: RemoveModification[] = []
  oldValues.forEach((oldValue) => {
    const { id: _oldId, ...oldData } = oldValue

    // if there's a matching ID then this is not a removal
    if (find(newValues, { id: _oldId } as { id: string })) {
      return
    }

    // otherwise let's check if there's a match ignoring the IDs
    const newValuesWithoutIds = newValues.map(
      ({ id: _newId, ...newData }) => newData,
    )
    const matchIndex = findIndex(newValuesWithoutIds, oldData as any)
    if (matchIndex !== -1) {
      const match = newValues[matchIndex]
      // and then double check that the value doesn't have an ID already
      // if we didn't do this then it would be impossible to remove duplicates
      if (!match.id) {
        return
      }
    }

    // if there's no matching id or values then this must be a removal
    removed.push({ type: ModificationType.Remove, oldData: oldValue })
  })

  const changed: ChangeModification[] = []
  newValues
    .filter(({ id }) => !!id)
    .forEach((newData) => {
      const oldData = find(oldValues, { id: newData.id } as { id: string })

      // if there's no match then this case was already handled as an add
      if (!oldData) {
        return
      }

      const changedProperties: string[] = []
      properties.forEach((name) => {
        const oldValue = get(oldData, name)
        const newValue = get(newData, name)
        if (oldValue !== newValue) {
          changedProperties.push(name)
        }
      })

      if (changedProperties.length) {
        changed.push({
          type: ModificationType.Change,
          changedProperties,
          oldData,
          newData,
        })
      }
    })

  return [...removed, ...changed, ...added]
}

export const generateCsv = async <T extends { id: string } = any>(
  values: T[],
  properties: string[],
  csvProperties?: string[],
  transform?: (value: T) => any,
  transformProperties?: (value: T) => any,
  uploadFtp?: boolean,
) => {
  const propertiesWithId = ['id', ...properties]

  const flattenValue = (value: any) => {
    if (transformProperties) {
      const transformed = transformProperties(value)
      if (Array.isArray(transformed)) {
        const data: any[] = []
        transformed.forEach(trans => {
          const property = csvProperties?.map(name => get(trans, name))
          data.push(property)
        })
        return data
      }
      return [csvProperties?.map(name => get(transformed, name))]
    }
    const transformed = transform ? transform(value) : value
    return propertiesWithId.map((name) => get(transformed, name))
  }

  if (transformProperties) {
    const result = values.map(flattenValue).flat()
    const csv = csvStringify([csvProperties, ...result])
    if (uploadFtp) {
      await uploadCsvFtp(csv)
    }
    return csv
  }

  return csvStringify([propertiesWithId, ...values.map(flattenValue)])
}

export const expandCsv = <T extends { id: string } = any>(
  values: any[],
  properties: string[],
  transform?: (value: any) => T,
): T[] => {
  const propertiesWithId = ['id', ...properties]

  const expandValue = (value: any) => {
    const nextValue: { [key: string]: any } = {}
    propertiesWithId.forEach((name) => set(nextValue, name, value[name]))
    return transform ? transform(nextValue) : (nextValue as T)
  }

  return values.map(expandValue)
}

export const uploadCsvFtp = async<T = any>(
  values: string
) => {
  // implement firebase backend call here for uploading array as a file to ftp server
  console.log('UPLOADING CSV FILE TO FTP SERVER...')
  return
}
