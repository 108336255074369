import React from 'react'
import firebase from 'firebase/app'
import 'firebase/auth'
import { useHistory } from 'react-router-dom'

import Loading from '../Loading'

const SignOut: React.FunctionComponent = () => {
  const history = useHistory()

  React.useEffect(() => {
    const handleSignOut = async () => {
      try {
        await firebase.auth().signOut()
      } catch (error) {}
      history.push('/')
    }
    handleSignOut()
  }, [history])

  return <Loading />
}

export default SignOut
