import * as React from 'react'
import styled from 'styled-components'
import { SearchableProducts } from '../SearchableProducts';
import { ProductRowView } from '../Product/RowView';
import { getConsumerProduct } from '../../utils/getConsumerProduct';
import { SelectedProducts } from '../SelectedProducts';
import firebase from 'firebase';

import { useFormikContext } from 'formik';


const SalesSearchParent = styled.div`
  display: flex;

`

interface SalesSearchProps {
  data?: any;
  searchKeys?: string[];
  sale?: string,
  label?: string,

}

interface SaleDetails {
  individualPercentage: number;
}

export interface ProductInfo {
  sku: string;
  product: any; 
  metadata: any; 
  percentage?: number;
}



export const SalesSearch: React.FC<SalesSearchProps> =({sale, data, label}) => {



  const searchKeys = ['product.id', 'meta.name']
  const { setFieldValue } = useFormikContext<any>();
  const [selectedProducts, setSelectedProducts] = React.useState<ProductInfo[]>([])

  const updateFormikSelectedProducts = (selectedProducts) => {
    setFieldValue('specificProducts', selectedProducts)
  }

  const handleSelectProduct = async (productInfo) => {
    
    // check if sku is selected

    const isProductSelected = selectedProducts.some(selected => selected.sku === productInfo.sku);

    //add sku to state if not selected
    if (!isProductSelected) {
      //add sku if it isnt in selectedProducts state
      const updatedSelectedProducts = [...selectedProducts, productInfo];
      setSelectedProducts(updatedSelectedProducts);

      //map through state. 
      const formikProducts = updatedSelectedProducts.map(product => {

        //for every product in state, gets its sku. if it has 
        const productForFormik: {sku: string, percentage?: number} = {sku: product.sku};
        if(product.percentage){
          productForFormik.percentage = product.percentage
        }
        return productForFormik
      })

      setFieldValue('specificProducts', formikProducts)
    }
  }

  React.useEffect(()=>{

    const getSaleData = async () => {
      const ref = firebase.firestore().collection('ongoing-sales').doc(sale)
      const doc = await ref.get();
      if (doc.exists) {
        const saleData = doc.data()

        if (data && saleData?.specificProducts) {
          const productsInSale = saleData?.specificProducts.map((saleProduct) => {
            const foundProduct = data.find(productData => productData.product.id === saleProduct.sku);

            if (foundProduct) {
              return {
                sku: foundProduct.product.id,  // Assuming you need the SKU
                metadata: foundProduct.meta,
                product: foundProduct.spec,
                ...(saleProduct.percentage && { percentage: saleProduct.percentage * 100})
              };
            } else {
              return null;
            }
          }).filter(product => product !== null);

          setSelectedProducts(productsInSale)

        }

      }

    };

    if (sale) {
      getSaleData()
    }

  }, [sale, data])

  const handleRemoveProduct = (skuToRemove) => {

    const filteredSelectedProducts = selectedProducts.filter((product) => product.sku !== skuToRemove)

    setSelectedProducts(filteredSelectedProducts)

    const formikSpecificProducts = filteredSelectedProducts.map((product) => {
      if (product.percentage) {
        return (
          {
            product: product.sku,
            percentage: product.percentage
          }
        )
      } else {
        return (
          {
            product: product.sku,
          }
        )
      }

    })

    //then it updates formikContext. 
    updateFormikSelectedProducts(formikSpecificProducts)

  }

  const updateSaleDetails = (sku, newPercentage) => {

    // Update state with percentage
    const updatedSelectedProducts = selectedProducts.map(product => {
      if (product.sku === sku) {
        return { ...product, percentage: newPercentage };
      }
      return product;
    });

    // set state
    setSelectedProducts(updatedSelectedProducts);

    // map through state, adding only sku and percentage to formik variable
    const formikProducts = updatedSelectedProducts.map(product => {
      const productForFormik: {sku: string, percentage?: any} = { 
        sku: product.sku, 
      };
      if (product.percentage) {
        productForFormik.percentage = (product.percentage / 100);
      }
      return productForFormik;
    });

    // Update Formik state
    setFieldValue('specificProducts', formikProducts);
  }
  
  return (
    <>
      <div style={{marginTop: '15px'}}>{label}</div>
      <SalesSearchParent>
        <SearchableProducts 
          data={data} 
          searchKeys={searchKeys} 
          renderItem={({
            product,
            spec,
            meta,
          }) => {
            try {
              // If this returns an error or undefined, something is wrong with the product
              const productHasNoErrors = getConsumerProduct(product);

              return (
                <ProductRowView
                  key={product.id}
                  sku={product.id}
                  product={spec}
                  metadata={meta}
                  selected={false}
                  onProductClick={handleSelectProduct}
                  onSelect={()=>{}}
                  error={Boolean(productHasNoErrors) === false}
                  isSales={true}
                />
              );
            } catch (error) {
              return (
                <ProductRowView
                  key={product.id}
                  sku={product.id}
                  product={spec}
                  metadata={meta}
                  selected={false}
                  onSelect={() => { }}
                  onProductClick={handleSelectProduct}
                  error={true}
                  isSales={true}
                />
              );
            }
          }}
        
        />
        <SelectedProducts 
          selectedProducts={selectedProducts} 
          handleRemoveProduct={handleRemoveProduct}
          updateSaleDetails={updateSaleDetails}
        />
      </SalesSearchParent>
    </>
  )
}