import React, { FunctionComponent } from 'react';
import Modal from '@material-ui/core/Modal';
import styled from 'styled-components';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {
  Typography,
  Collapse,
  makeStyles,
  Switch,
  FormGroup,
  FormControlLabel,
} from '@material-ui/core';
import { applyMargin, ValueLineItem } from '@tiary-inc/tiary-shared';

import { GetPriceItem } from '../../../../utils/getPrice';

const StyledCostSummaryModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;

  .cost-summary-modal {
    &__wrapper {
      max-width: 70%;
      width: 600px;
      padding: 48px;
      background: white;
      max-height: 85vh;
      overflow: auto;
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  headingContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  nonnested: {
    padding: 0,
  },
  nested: {
    padding: 0,
    paddingLeft: theme.spacing(4),
  },
  subtotalRow: {
    padding: 0,
    paddingLeft: theme.spacing(4),
    marginBottom: theme.spacing(1),
  },
}));

export interface RetailCostSummaryValues {
  retailPrices: GetPriceItem[];
  productCosts: ValueLineItem[];
  margins: {
    [key: string]: number;
  };
}
interface RetailCostSummaryModalProps extends RetailCostSummaryValues {
  onClose: () => void;
}

export const RetailCostSummaryModal: FunctionComponent<RetailCostSummaryModalProps> = ({
  onClose,
  retailPrices,
  productCosts,
  margins,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const baseCostLineItem = retailPrices.find(
    (lineItem) => lineItem.label === 'Base Cost'
  );

  const lineItemsWithoutBase = baseCostLineItem
    ? retailPrices.filter((lineItem) => lineItem.label !== 'Base Cost')
    : retailPrices;

  // gets the detailed line items for each row
  const getLineItemsForRow = (rowLabel: string): ValueLineItem[] => {
    if (rowLabel === 'METAL_1') {
      // get the items where the option === 'METAL_1' or if the type is a finding or assembly
      const itemTypesToMatch = [
        'FINDING',
        'ASSEMBLY',
        'FINISHING',
        'RHODIUM',
        'FAKE_RHODIUM',
      ];
      const applicableItems = productCosts.filter(
        (lineItem) =>
          lineItem.option === rowLabel ||
          itemTypesToMatch.includes(lineItem.type)
      );
      return applicableItems;
    } else if (rowLabel.includes('STONE_')) {
      // get the items for the stone option
      const itemTypesToMatch = ['STONE', 'STONE_SETTING'];
      // if it matches the row label and matches the option (STONE_1 applies STONE_1 costs)
      const applicableItems = productCosts.filter(
        (lineItem) =>
          lineItem.option === rowLabel &&
          itemTypesToMatch.includes(lineItem.type)
      );
      return applicableItems;
    } else if (rowLabel.includes('CUSTOM_PRICE')) {
      const applicableItems = productCosts.filter(
        (lineItem) => lineItem.type === rowLabel
      );

      return applicableItems;
    } else {
      const applicableItems = productCosts.filter(
        (lineItem) => lineItem.option === rowLabel
      );
      return applicableItems;
    }
  };

  const getMarginsForLabel = (label): number => {
    if (label === 'CUSTOM_PRICE') {
      return margins['base'];
    }

    return margins[label];
  };

  return (
    <StyledCostSummaryModal open onClose={onClose}>
      <div className="cost-summary-modal__wrapper">
        <div className={classes.headingContent}>
          <Typography variant="h6" style={{ flex: 1 }}>
            Retail Pricing Summary
          </Typography>
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  checked={expanded}
                  onChange={(event) => setExpanded(event.target.checked)}
                  color="primary"
                />
              }
              label="toggle detailed pricing"
            />
          </FormGroup>
        </div>
        <List>
          {lineItemsWithoutBase.map(({ label, value, description }) => {
            const detailedLineItems = getLineItemsForRow(label);

            // get the margin from the current label (i.e metal_1, stone_1, etc)
            const margin = getMarginsForLabel(label);

            const totalWithMargin = applyMargin(
              detailedLineItems.reduce((total, item) => item.cost + total, 0),
              margin
            ).toFixed(2);

            return (
              <React.Fragment key={label}>
                <ListItem
                  style={{ background: 'white' }}
                  className={classes.nonnested}
                >
                  <ListItemText primary={label} secondary={description} />
                  <ListItemText
                    style={{ textAlign: 'right' }}
                    primary={`$${(value || 0).toFixed(2)}`}
                    primaryTypographyProps={{
                      variant: 'h6',
                    }}
                  />
                </ListItem>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  <List>
                    {detailedLineItems.map((subLineItem) => (
                      <ListItem
                        key={`${label} - ${subLineItem.description}`}
                        className={classes.nested}
                      >
                        <ListItemText
                          primary={subLineItem.label}
                          secondary={subLineItem.description}
                        />
                        <ListItemText
                          style={{ textAlign: 'right' }}
                          primary={`$${(subLineItem.cost || 0).toFixed(2)}`}
                          secondary={`($${Number(subLineItem.unitCost).toFixed(
                            2
                          )} x ${subLineItem.quantity})`}
                        />
                      </ListItem>
                    ))}
                    <ListItem
                      style={{ background: 'white' }}
                      className={classes.subtotalRow}
                    >
                      <Typography variant="h6" style={{ flex: 1 }}>
                        SUBTOTAL W/ MARGIN ({(margin * 100).toFixed(0)}%)
                      </Typography>
                      <Typography variant="h6" style={{ textAlign: 'right' }}>
                        ${totalWithMargin}
                      </Typography>
                    </ListItem>
                  </List>
                </Collapse>
              </React.Fragment>
            );
          })}
          {baseCostLineItem && (
            <>
              <ListItem
                style={{ background: 'white', display: 'flex', marginTop: 20 }}
                className={classes.nonnested}
              >
                <Typography variant="h6" style={{ flex: 1 }}>
                  SUBTOTAL
                </Typography>
                <Typography variant="h6" style={{ textAlign: 'right' }}>
                  $
                  {lineItemsWithoutBase
                    .reduce((total, item) => item.value + total, 0)
                    .toFixed(2)}
                </Typography>
              </ListItem>
              <ListItem
                style={{ background: 'white' }}
                className={classes.nonnested}
              >
                <ListItemText
                  primary={baseCostLineItem.label}
                  secondary={baseCostLineItem.description}
                />
                <ListItemText
                  style={{ textAlign: 'right' }}
                  primary={`$${(baseCostLineItem.value || 0).toFixed(2)}`}
                />
              </ListItem>
            </>
          )}
          <ListItem
            style={{ background: 'white', display: 'flex' }}
            className={classes.nonnested}
          >
            <Typography variant="h6" style={{ flex: 1 }}>
              TOTAL
            </Typography>
            <Typography variant="h6" style={{ textAlign: 'right' }}>
              $
              {retailPrices
                .reduce((total, item) => item.value + total, 0)
                .toFixed(2)}
            </Typography>
          </ListItem>
        </List>
      </div>
    </StyledCostSummaryModal>
  );
};
