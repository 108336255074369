import React, { useState, useEffect } from 'react';
import { useAuthedDocument } from '@humancollective/human-hooks-firebase';
import { ProductMetadata } from '@tiary-inc/tiary-shared';
import firebase from 'firebase';
import { useParams } from 'react-router-dom';
import Container from '@material-ui/core/Container';

import { routes } from '../../configuration/routes';
import EditorTemplate from '../../lib/templates/EditorTemplate';
import {
  EditorFormFieldType,
  // EditorFormField,
} from '../../lib/components/EditorForm/Fields/getFormField';
import ProductContentfulPreview from '../../components/Product/ContentfulPreview';
import { ProductDeployModal } from '../../components/Product/DeployModal';
import NonJewellryModal from '../../components/Product/NonJewellryModal';
import { InventoryPricingForm } from '../../components/Product/forms/Pricing/InventoryPricingForm';
import { DefaultSchema,GoldmanSchema } from '../../lib/components/FormikSchema';
import { isGoldmanProduct } from '@tiary-inc/tiary-shared';
/**
 * Edit Product Page
 * ---
 *
 * This page is for editing a single product. Each product contains variables
 * related to its production, pricing, and rendering. These variables are used
 * in conjunction with manufacturer data to create the product "spec" document
 * used on the consumer website.
 */


export const EditProductPage: React.FunctionComponent = () => {
  const { product } = useParams<{ product: string }>();
  const [showDeployModal, setShowDeployModal] = useState(false);
  const [isInventoryItem, setIsInventoryItem] = useState(false);
  // const [isGoldmanProduct,setIsGoldmanProduct] = useState(false);
  //made this one to see if it can hold the changed Product until its passed down.
  const [modifiedProduct, setModifiedProduct] = useState(null);

  
  const metadata = useAuthedDocument<ProductMetadata>({
    getQueryRef: () =>
      firebase.firestore().collection('product-metadata').doc(product),
  });

  // if(isGoldmanProduct(product.id)) {

  // }
  // const goldmanSkus = ["RD-0001","RDP-0001",""];
  //so we might have to actually have to make a mechanism to get the right schema for correct manufacturer
  // if(!isGoldmanProduct && goldmanSkus.includes(product) ){
  //     setIsGoldmanProduct(true)
  // }

  useEffect(() => {
    ;(async () => {
      // const ifInventoryItem = await checkIfInventoryItem(product);
      setIsInventoryItem(false);
    })();
  }, [product]);

  

  const getReadyToSubmit = (values:any) => {
    console.log(values, 'my values')
    setModifiedProduct(values);
    setShowDeployModal(true);
  }

  const formSchema = isGoldmanProduct(product) ? GoldmanSchema(product) : DefaultSchema(product) ;

  return (
    <>
      <NonJewellryModal />
      {metadata && (
        <Container>
          <ProductContentfulPreview metadata={metadata} />
        </Container>
      )}

      {showDeployModal && (
        <ProductDeployModal
          sku={product}
          modifiedProduct={modifiedProduct}
          onClose={() => setShowDeployModal(false)}
        />
      )}

      <EditorTemplate
        title={metadata?.name || metadata?.sku || 'Edit Product'}
        breadcrumbs={[
          {
            label: 'Products',
            to: routes.PRODUCTS.path,
          },
          {
            label: metadata?.name || metadata?.sku || 'Edit Product',
          },
        ]}
        defaultValues={{
          options: [],
        }}
        onSubmitSuccess={getReadyToSubmit}
        getQueryRef={() =>
          firebase
            .firestore()
            .collection('dashboard')
            .doc('data')
            .collection('products')
            .doc(product)
        }
        formSchema={isInventoryItem ? [
          {
            type: EditorFormFieldType.Heading,
            label: 'Inventory Pricing',
          }, {
            type: EditorFormFieldType.FieldComponent,
            FieldComponent: InventoryPricingForm,
            name: 'pricing',
            sku: product,
          },
          ...formSchema,
        ] : formSchema}
      />
    </>
  );
};
