import React from 'react'
import { CardProps } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import CardActionArea from '@material-ui/core/CardActionArea'
import { useHistory } from 'react-router-dom'

/**
 * Manufacturer Card View
 * Displays a single manufacturer as a card.
 * e.g., for use in a gallery template.
 */

interface ManufacturerCardViewProps {
  manufacturer: any
}

export const ManufacturerCardView: React.FunctionComponent<
  ManufacturerCardViewProps & CardProps
> = ({ manufacturer }) => {
  const history = useHistory()
  return (
    <Card>
      <CardActionArea
        onClick={() => {
          history.push(`/manufacturers/edit/${manufacturer.id}`)
        }}
      >
        <Box paddingX={3} paddingY={2}>
          <Typography variant="h6" className="manufacturer-card__name">
            {manufacturer.name}
          </Typography>
        </Box>
      </CardActionArea>
    </Card>
  )
}
