import React from 'react'
import firebase from 'firebase'
import { Container, Grid } from '@material-ui/core'
import trim from 'lodash/trim'

import { CsvCollectionManager } from '../../lib/components/CsvCollectionManager'

/**
 * Components Page
 * ---
 *
 * This page is for managing the raw components of the dashboard data model.
 * For example, raw materials, groups of materials, etc.
 *
 * These components are used to determine which combinations of feature sets on
 * a given material (e.g., size / quality / type combinations on a stone) are
 * valid.
 */

export const ComponentsPage: React.FunctionComponent = () => {
  const dataRef = firebase.firestore().collection('dashboard').doc('data')
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={4}>
          <CsvCollectionManager
            title="Metals"
            collectionRef={dataRef.collection('materials')}
            queryRef={dataRef
              .collection('materials')
              .where('material', '==', 'METAL')}
            properties={['material', 'type', 'metal', 'color', 'quality']}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <CsvCollectionManager
            title="Stones"
            collectionRef={dataRef.collection('materials')}
            queryRef={dataRef
              .collection('materials')
              .where('material', '==', 'STONE')}
            properties={[
              'material',
              'type',
              'size',
              'stone',
              'color',
              'quality',
            ]}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <CsvCollectionManager
            title="Findings"
            collectionRef={dataRef.collection('materials')}
            queryRef={dataRef
              .collection('materials')
              .where('material', '==', 'FINDING')}
            properties={['material', 'finding', 'metal', 'quality']}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <CsvCollectionManager
            title="Chains"
            collectionRef={dataRef.collection('materials')}
            queryRef={dataRef
              .collection('materials')
              .where('material', '==', 'CHAIN')}
            properties={['material', 'size', 'style', 'metal', 'quality']}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <CsvCollectionManager
            title="Groupings"
            collectionRef={dataRef.collection('groupings')}
            properties={['name', 'type', 'materials']}
            transformImport={({ materials = '', ...rest }: any) => ({
              ...rest,
              materials: materials.split(',').map(trim),
            })}
            transformExport={({ materials = [], ...rest }: any) => ({
              ...rest,
              materials: materials.join(', '),
            })}
            transformDisplay={({ materials = [], ...rest }: any) => ({
              ...rest,
              materials: materials.join(', '),
            })}
          />
        </Grid>
      </Grid>
    </Container>
  )
}
