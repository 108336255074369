import uniqBy from 'lodash/uniqBy'
import sortBy from 'lodash/sortBy'
import groupBy from 'lodash/groupBy'
import { FindingMaterial } from '@tiary-inc/tiary-shared'

interface GroupedFindings {
  finding: string
  materials: FindingMaterial[]
}

export const groupFindings = (
  materials: FindingMaterial[],
): GroupedFindings[] => {
  const materialsByFinding = groupBy(materials, 'finding')

  const allFindings = Object.values(materialsByFinding).map(
    (materials: FindingMaterial[]) => {
      const referenceValue = materials[0]
      return {
        finding: referenceValue.finding,
        materials: sortBy(materials, 'quality'),
      }
    },
  )

  return sortBy(uniqBy(allFindings, 'finding'), 'finding')
}
