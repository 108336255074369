import React from 'react';
import { Field, FieldProps } from 'formik';
import { FormControlProps, Button } from '@material-ui/core';
import fileDownload from 'js-file-download';
import firebase from 'firebase';

import { ImportJson } from '../../../ImportJson';

interface ImportExportFieldProps extends FormControlProps {
  filename: string;
}

export const ImportExportField: React.FunctionComponent<ImportExportFieldProps> = ({
  filename,
}) => (
  <div>
    <Field>
      {({ form }: FieldProps) => {
        const onDownload = async () => {
          const file = JSON.stringify(form.values || {});
          fileDownload(file, `${filename}.json`);
        };

        const onUpload = async (values: any) => {
          // timestamp conversion since its deserialized when exporting
          // this isnt 100% accurate, just better than nothing
          Object.keys(values).forEach((parentKey) => {
            const innerObject = values[parentKey];

            // if the child obj has 2 keys, and theyre both nanoseconds and seconds
            if (
              Object.keys(innerObject).length === 2 &&
              innerObject['seconds'] &&
              innerObject['nanoseconds']
            ) {
              const dateFromSeconds = new Date(innerObject['seconds'] * 1000);
              const convertedTimestamp = firebase.firestore.Timestamp.fromDate(
                dateFromSeconds
              );

              values[parentKey] = convertedTimestamp;
            }
          });

          form.setValues(values);
        };

        return (
          <>
            <Button onClick={onDownload}>Export</Button>
            <ImportJson onSuccess={onUpload} buttonLabel="Import" />
          </>
        );
      }}
    </Field>
  </div>
);
