import React, { useMemo, useState } from 'react';
import firebase from 'firebase';
import { useAuthedCollection } from '@humancollective/human-hooks-firebase';
import {
  ProductSpec,
  ProductMetadata,
  DashboardProduct,
  getPriceForConfiguration,
  getDefaultConfiguration,
} from '@tiary-inc/tiary-shared';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import UpdateIcon from '@material-ui/icons/CloudUpload';
import Button from '@material-ui/core/Button';

import { ProductRowView } from '../../components/Product/RowView';
import DownloadProductsButton from '../../components/Product/ExportButton';
import { DownloadPopularProductsButton } from '../../components/Product/ExportPopularButton';
import { ListTemplate } from '../../lib/templates/ListTemplate';
import { ProductBulkUpdateModal } from '../../components/Product/BulkUpdateModal';
import { AddProductModal } from '../../components/Product/AddProductModal';
import { getConsumerProduct } from '../../utils/getConsumerProduct';
import { createCallableFunction } from '../../utils/firebase/createCallableFunction';

export interface CombinedProduct {
  spec: ProductSpec;
  meta: ProductMetadata;
  product?: any
}

/**
 * Products Page
 * ---
 *
 * This page displays a list of products that are available for editing.
 *
 * Each product contains variables related to its production, pricing, and
 * rendering. These variables are used in conjunction with manufacturer data to
 * create the product "spec" document used on the consumer website.
 */

export const ProductsPage: React.FunctionComponent = () => {
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);

  const products = useAuthedCollection({
    getQueryRef: () =>
      firebase
        .firestore()
        .collection('dashboard')
        .doc('data')
        .collection('products'),
    includeIds: true,
  });

  const specs = useAuthedCollection({
    getQueryRef: () => firebase.firestore().collection('products'),
    includeIds: true,
  });

  const metadata = useAuthedCollection<ProductMetadata>({
    getQueryRef: () => firebase.firestore().collection('product-metadata'),
    includeIds: true,
  });

  const data = useMemo<CombinedProduct[]>(
    () =>
      products?.map((product: DashboardProduct & { id: string }) => {
        const meta = metadata?.find(
          ({ sku }: ProductMetadata) => sku === product.id
        );
        const spec = specs?.find(
          ({ sku }: ProductMetadata) => sku === product.id
        );
        return {
          product,
          spec,
          meta,
        };
      }) || null,
    [products, metadata, specs]
  );

  const removeStonesHandler = async () => {
    try {
      const callableRemoveStones = createCallableFunction('updateTransaction');
      await callableRemoveStones();
    } catch (err) {
      console.log("Error removing stones", err);
    }
  }

  const uploadGoogleProducts = async () => {
    ;(async () => {
      try {
        const callableGoogleProducts = createCallableFunction('getProducts');
        const callableUploadGoogleProducts = createCallableFunction('uploadGoogleProducts');
        const data = await callableGoogleProducts();
        const { data: { products }} = data;
        const allProducts: any[] = [];
        products.forEach((product) => {
          const configuration = {
            ...getDefaultConfiguration(product),
          };
          const price = getPriceForConfiguration({ product, configuration });
          allProducts.push({
            id: `online:en:US:${product.sku}`,
            offerId: product.sku,
            identifierExists: false,
            title: product.name,
            description: product.description.content[0].content[0].value,
            link: `https://tiary.com/products/${product.slug}`,
            imageLink: `https://tiary-development.imgix.net/imageTest/${product.sku}-feed.png`,
            contentLanguage: "en",
            targetCountry: "US",
            feedLabel: "US",
            channel: "online",
            availability: "in stock",
            brand: product.classifications.length > 0 ? product.classifications[0].fields.name : '',
            // brand: product.classifications[0].fields.name,
            condition: "new",
            price: {
                value: price,
                currency: "USD"
            },
          });
        });
        await callableUploadGoogleProducts(allProducts);
      } catch (err) {
        console.log(err);
      }
    })()
  }

  return (
    <>
      {updateModalOpen && (
        <ProductBulkUpdateModal onClose={() => setUpdateModalOpen(false)} />
      )}
      {addModalOpen && (
        <AddProductModal onClose={() => setAddModalOpen(false)} />
      )}
      <ListTemplate
        title="Products"
        data={data}
        searchKeys={['product.id', 'meta.name']}
        controls={
          <>
          <Button
              onClick={() => uploadGoogleProducts()}
              startIcon={<UpdateIcon />}
            >
              Deploy Google
            </Button>
            <Button
              onClick={() => removeStonesHandler()}
              startIcon={<EditIcon />}
            >
              Edit Stones
            </Button>
            <Button
              onClick={() => setAddModalOpen(true)}
              startIcon={<AddIcon />}
            >
              Add
            </Button>
            <Button
              onClick={() => setUpdateModalOpen(true)}
              startIcon={<UpdateIcon />}
            >
              Deploy All ({data ? data.length : 0})
            </Button>
            <DownloadProductsButton products={data} />
            <DownloadPopularProductsButton />
          </>
        }
        renderItem={({
          product,
          spec,
          meta,
        }: {
          product: DashboardProduct;
          spec: ProductSpec & { id: string };
          meta: ProductMetadata;
        }) => {
          try {
            // If this returns an error or undefined, something is wrong with the product
            const productHasNoErrors = getConsumerProduct(product);

            return (
              <ProductRowView
                key={product.id}
                sku={product.id}
                product={spec}
                metadata={meta}
                selected={false}
                onSelect={() => {}}
                error={Boolean(productHasNoErrors) === false}
              />
            );
          } catch (error) {
            return (
              <ProductRowView
                key={product.id}
                sku={product.id}
                product={spec}
                metadata={meta}
                selected={false}
                onSelect={() => {}}
                error={true}
              />
            );
          }
        }}
      />
    </>
  );
};
