import * as React from 'react';
import Button from '@material-ui/core/Button';
import firebase from 'firebase/app';
import 'firebase/storage';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { UIContext } from '../../../../contexts/UI';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

interface RenderLookup {
  [optionPath: string]: string;
}

export interface RenderDeleterProps {
  renders: { key: { key: string } };
  setFieldValue: (fieldName: string, value: any) => void;
}

const RenderDeleter: React.FunctionComponent<RenderDeleterProps> = ({
  renders,
  setFieldValue,
}) => {
  const ui = React.useContext(UIContext);
  const [alertShowing, setAlertShowing] = React.useState(false);

  const startHardDeletion = async () => {
    const renderPaths: string[] = [];

    Object.keys(renders).forEach((parentKey) => {
      const parentRenderObject = renders[parentKey];

      if (parentKey === 'SHADOW') {
        // treat the shadow differently as it is different obj layout
        renderPaths.push(parentRenderObject);
      } else {
        Object.keys(parentRenderObject).forEach((childKey) => {
          const renderPath = parentRenderObject[childKey];
          renderPaths.push(renderPath);
        });
      }
    });

    for (const renderPath of renderPaths) {
      const fileRef = firebase.storage().ref(`renders/${renderPath}`);

      await fileRef.delete();
    }

    setFieldValue('renders', {});

    ui.snackbar.show({ message: 'Renders deleted!', type: 'success' });
  };

  const startSoftDeletion = () => {
    setFieldValue('renders', {});
  };

  return (
    <>
      <Dialog
        open={alertShowing}
        onClose={() => setAlertShowing(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete renders?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            A soft deletion will only remove the renders from the product. A
            full deletion will remove the renders from the server and from the
            product.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              startSoftDeletion();
              setAlertShowing(false);
            }}
          >
            Soft Delete
          </Button>

          <Button
            onClick={() => {
              startHardDeletion();
              setAlertShowing(false);
            }}
          >
            Full Delete
          </Button>
        </DialogActions>
      </Dialog>
      <div>
        <Button
          onClick={() => setAlertShowing(true)}
          startIcon={<DeleteForeverIcon />}
          variant="contained"
          color="primary"
        >
          Delete Renders
        </Button>
      </div>
    </>
  );
};

export default RenderDeleter;
