import React, { FunctionComponent } from 'react'
import Box from '@material-ui/core/Box'
import InputBase from '@material-ui/core/InputBase'
import SearchIcon from '@material-ui/icons/Search'
import styled from 'styled-components'

const StyledTemplateSearchBar = styled(Box)`
  display: flex;
  align-items: center;
  .search-bar {
    &__icon {
      margin-right: 8px;
    }
  }
`

interface TemplateSearchBarProps {
  query: string
  setQuery: (nextQuery: string) => void
  className?: string
}

export const TemplateSearchBar: FunctionComponent<TemplateSearchBarProps> = ({
  query,
  setQuery,
  className,
}) => (
  <StyledTemplateSearchBar paddingY={2} className={className}>
    <SearchIcon className="search-bar__icon" />
    <InputBase
      placeholder="Search…"
      fullWidth
      value={query}
      onChange={(e) => setQuery(e.target.value)}
    />
  </StyledTemplateSearchBar>
)
