import React, { useContext } from 'react'
import get from 'lodash/get'
import styled from 'styled-components'
import Select from '@material-ui/core/Select'
import { MenuItem, Box } from '@material-ui/core'
import { Field, FieldProps } from 'formik'

import { LocaleContext } from '../../../../contexts/Locale'
import { FormikSelectChip } from '../../../../lib/components/FormikSelectChip'
import { MaterialsContext } from '../../../../contexts/Materials'

interface ProductMetalValuesFormProps {
  name: string
}

const StyledProductMetalValuesForm = styled.div`
  .product-metal-values-form {
    &__label {
      font-size: 16px;
      margin-top: 44px;
      text-transform: uppercase;
      letter-spacing: 0.6px;
    }
  }
`

export const ProductMetalValuesForm: React.FunctionComponent<ProductMetalValuesFormProps> = ({
  name,
}) => {
  const { localize } = useContext(LocaleContext)
  const { metalMaterials, metalGroupings } = useContext(MaterialsContext)

  const allMetalIds = metalMaterials.map(({ id }) => id)
  const allMetalOptions = metalMaterials.map(
    ({ id, metal, color, quality }) => ({
      value: id,
      label: `${localize(quality)} ${localize(color)} ${localize(metal)}`,
    }),
  )

  return (
    <Field name={name}>
      {({ form }: FieldProps) => {
        const value = get(form.values, name)
        const type = typeof value === 'string' ? value : 'CUSTOM'

        const changeType = (setting: string) => {
          if (setting === 'CUSTOM') {
            form.setFieldValue(name, allMetalIds)
          } else {
            form.setFieldValue(name, setting)
          }
        }

        return (
          <StyledProductMetalValuesForm>
            <h2 className="product-metal-values-form__label">Values</h2>
            <Select
              value={type}
              onChange={(e) => changeType(e.target.value as string)}
              fullWidth
            >
              {metalGroupings.map(({ id, name: groupName }) => (
                <MenuItem key={id} value={id}>
                  {groupName}
                </MenuItem>
              ))}
              <MenuItem value={'CUSTOM'}>Custom</MenuItem>
            </Select>
            {type === 'CUSTOM' && (
              <Box marginTop={1}>
                <FormikSelectChip
                  name={name}
                  options={allMetalOptions}
                  multiple
                  fullWidth
                />
              </Box>
            )}
          </StyledProductMetalValuesForm>
        )
      }}
    </Field>
  )
}
