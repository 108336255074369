import React from 'react';
import { Order, ManufacturerCode, OrderProduct } from '@tiary-inc/tiary-shared';
import addBusinessDays from 'date-fns/addBusinessDays';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { MasterCastingManufacturerProduct } from './manufacturers/masterCasting';
import { UnassignedManufacturerProduct } from './manufacturers/unassigned';

import { getNameFromManufacturerCode } from '../../../../utils/transforms';

interface OrderProductsPartProps {
  order: Order;
}

export const OrderProductsPart: React.FunctionComponent<OrderProductsPartProps> = ({
  order,
}) => {
  const legacyManufacturer =
    order.manufacturing && order.manufacturing.provider;
  const manufacturerCode = order.manufacturer || legacyManufacturer;

  const dateCreated = order.dateCreated.toDate();
  const completionDate = addBusinessDays(dateCreated, 10);

  const renderProductFromManufacturer = (
    product: OrderProduct
  ): React.ReactNode => {
    switch (manufacturerCode) {
      case ManufacturerCode.MasterCasting:
        return (
          <MasterCastingManufacturerProduct order={order} product={product} />
        );

      default:
        return (
          <UnassignedManufacturerProduct order={order} product={product} />
        );
    }
  };

  return (
    <>
      <Typography gutterBottom={true}>
        {`${getNameFromManufacturerCode(
          manufacturerCode
        )} (estimated completetion ${completionDate.toDateString()})`}
      </Typography>
      <Grid container={true} spacing={3}>
        {order.products.map((orderProduct) => (
          <Grid item xs={4} key={orderProduct.id}>
            {renderProductFromManufacturer(orderProduct)}
          </Grid>
        ))}
      </Grid>
    </>
  );
};
