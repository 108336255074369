import React, { FunctionComponent } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import { firestore } from 'firebase';
import { Order } from '@tiary-inc/tiary-shared';

interface CancelOrderModalArgs {
  order: Order;
  onClose: () => void;
}

export const CancelOrderModal: FunctionComponent<CancelOrderModalArgs> = ({
  order,
  onClose,
}) => (
  <Dialog open onClose={onClose}>
    <DialogTitle>Cancel Order {order.id}?</DialogTitle>
    <DialogContent>
      <DialogContentText>
        <p>
          You&apos;re about to cancel order <strong>{order.id}</strong> for{' '}
          <strong>
            {order.billingAddress.firstName} {order.billingAddress.lastName}
          </strong>
          .
        </p>
        <p>You may also need to:</p>
        <ul>
          <li>
            <strong>Refund the order in Stripe</strong>.
          </li>
          <li>
            <strong>Contact the manufacturer</strong>.
          </li>
        </ul>
        <p>
          You can still perform these actions once the order is cancelled (just
          don&apos;t forget!)
        </p>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Oops.. No</Button>
      <Button
        variant="contained"
        color="primary"
        onClick={async () => {
          await firestore().collection('orders').doc(order.id).update({
            status: 'CANCELLED',
          });
          onClose();
        }}
      >
        Yes, Cancel Order
      </Button>
    </DialogActions>
  </Dialog>
);
