import React from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import parse from 'autosuggest-highlight/parse'
import throttle from 'lodash/throttle'

interface Place {
  description: string
  structured_formatting: {
    main_text: string
    secondary_text: string
    main_text_matched_substrings: [
      {
        offset: number
        length: number
      },
    ]
  }
}

interface LocationSelectorProps {
  label?: string
  onChange: (nextValue: any) => void
}

export const LocationSelector: React.FunctionComponent<LocationSelectorProps> = ({
  onChange,
  label,
}) => {
  const [inputValue, setInputValue] = React.useState('')
  const [options, setOptions] = React.useState<Place[]>([])
  const autocompleteService = React.useRef(null)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
  }

  const fetch = React.useMemo(
    () =>
      throttle(
        (request: { input: string }, callback: (results?: Place[]) => void) => {
          ;(autocompleteService.current as any).getPlacePredictions(
            request,
            callback,
          )
        },
        200,
      ),
    [],
  )

  React.useEffect(() => {
    let active = true

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService()
    }
    if (!autocompleteService.current) {
      return undefined
    }

    if (inputValue === '') {
      setOptions([])
      return undefined
    }

    fetch({ input: inputValue }, (results?: Place[]) => {
      if (active) {
        setOptions(results || [])
      }
    })

    return () => {
      active = false
    }
  }, [inputValue, fetch])

  return (
    <Autocomplete
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      // disableOpenOnFocus
      onChange={(_e: React.ChangeEvent<any>, value: any) => {
        onChange(value)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          fullWidth
          onChange={handleChange}
        />
      )}
      renderOption={(option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [
            match.offset,
            match.offset + match.length,
          ]),
        )

        return (
          <Grid container alignItems="center">
            <Grid item xs>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}
              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        )
      }}
    />
  )
}
