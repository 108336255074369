import find from 'lodash/find';
import { ProductConfiguration } from '@tiary-inc/tiary-shared';

import { ProductPricing } from './getPricingForProduct/types';

export interface GetPriceItem {
  label: string;
  description?: string;
  value: number;
}

interface GetPriceReturn {
  total: number;
  items: GetPriceItem[];
}
export const getPrice = (
  configuration: ProductConfiguration,
  basePrice: number,
  pricing: ProductPricing
): GetPriceReturn => {
  let total = basePrice;
  const pricingItems: GetPriceItem[] = [];

  pricingItems.push({ label: 'Base Cost', value: basePrice });

  const optionKeys = Object.keys(pricing);
  const optionNames = optionKeys.filter((key) => key !== 'NONE');

  for (const optionName of optionNames) {
    const configSet = configuration[optionName];
    if (!configSet) {
      throw new Error(`no configuration provided for ${optionName}`);
    }
    const values = pricing[optionName];
    const match = find(values, configSet);

    if (!match) {
      throw new Error(
        `no configuration match for ${JSON.stringify(configSet)}`
      );
    }

    pricingItems.push({
      label: optionName,
      description: `${match.type} - ${match.quality}`,
      value: match.price,
    });
    total += match.price;
  }

  if (optionKeys.includes('NONE')) {
    const pricingOption = pricing['NONE'];
    pricingItems.push({
      label: 'CUSTOM_PRICE',
      description: `Custom priced item`,
      // @ts-ignore
      value: pricingOption.price,
    });
    // @ts-ignore
    total += pricingOption.price;
  }

  return { total, items: pricingItems };
};
