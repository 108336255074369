import React from 'react'

import { UploadFile } from '../UploadFile'

const readFileAsText = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (e) => {
      resolve((e.target?.result as string) || '')
    }
    reader.onerror = (err) => {
      reject(err)
    }
    reader.readAsText(file)
    return
  })

interface ImportJsonProps {
  buttonLabel?: string
  onSuccess: (values: any) => void
}

export const ImportJson: React.FunctionComponent<ImportJsonProps> = ({
  buttonLabel,
  onSuccess,
}) => {
  const handleSelectFile = async (file?: File) => {
    if (!file) {
      throw new Error('no file selected')
    }
    const contents = await readFileAsText(file)
    const resultObj = JSON.parse(contents)
    onSuccess(resultObj)
  }

  return (
    <UploadFile buttonLabel={buttonLabel} onSelectFile={handleSelectFile} />
  )
}
