import React from 'react';
import { Avatar, CardProps, Chip } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { DashboardUser } from '../../routes/users';

const StyledReviewRowView = styled(TableRow)`
  cursor: pointer;

  .product-row-view {
    &__renderer {
      margin-right: 24px;
    }
  }
`;

interface DashboardUserRowViewProps {
  dashboardUser: DashboardUser;
}

const defaultUser = {
  name: 'No name',
  id: '',
  tabs: [],
};

export const DashboardUserRowView: React.FunctionComponent<
  DashboardUserRowViewProps & CardProps
> = ({ dashboardUser }) => {
  const user: DashboardUser = {
    ...defaultUser,
    ...dashboardUser,
  };

  const avatarInitials = user.name
    .split(' ')
    .map((part) => (part[0] || '').toUpperCase())
    .join('');

  const history = useHistory();
  return (
    <StyledReviewRowView
      hover
      onClick={() => history.push(`/users/edit/${user.id}`)}
    >
      <TableCell>
        <ListItem>
          <Avatar>{avatarInitials}</Avatar>
        </ListItem>
      </TableCell>
      <TableCell>
        <ListItem>
          <ListItemText
            primary={`${dashboardUser.name}`}
            secondary={`${dashboardUser.id}`}
          />
        </ListItem>
      </TableCell>
      <TableCell>
        <ListItem>
          <Chip label="Click to view permissions" />
        </ListItem>
      </TableCell>
    </StyledReviewRowView>
  );
};
