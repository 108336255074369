import React from 'react'
import { FormikHelpers, Formik, Form } from 'formik'
import Button from '@material-ui/core/Button'
import firebase from 'firebase/app'
import 'firebase/auth'
import * as Yup from 'yup'
import styled from 'styled-components'

import { firebaseAuthErrors } from '../../describe/errors/firebaseAuthErrors'
import { FormikInput } from '../FormikInput'
import { useAuth } from '@humancollective/human-hooks-firebase'
import { useHistory } from 'react-router-dom'

interface SignInFormValues {
  email: string
  password: string
}

const validationSchema = Yup.object({
  email: Yup.string().email().required(),
  password: Yup.string().required(),
})

const StyledSignInForm = styled(Form)`
  .login-form {
    &__input {
      margin-bottom: 24px;
    }
    &__button {
      margin-top: 24px;
    }
  }
`

interface SignInFormProps {
  redirectUrl?: string
}

const SignInForm: React.FunctionComponent<SignInFormProps> = ({
  redirectUrl,
}) => {
  const history = useHistory()
  const { isLoggedIn } = useAuth()

  React.useEffect(() => {
    if (isLoggedIn) {
      history.push(redirectUrl || '/')
    }
  }, [isLoggedIn, history, redirectUrl])

  const onSubmit = async (
    values: SignInFormValues,
    actions: FormikHelpers<SignInFormValues>,
  ) => {
    const { setSubmitting, setErrors } = actions
    const { password, email } = values
    setSubmitting(true)
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password)
    } catch (error) {
      setErrors(firebaseAuthErrors[error.code] || firebaseAuthErrors.unknown)
    }
    setSubmitting(false)
  }

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{ email: '', password: '' }}
      validationSchema={validationSchema}
    >
      <StyledSignInForm>
        <FormikInput
          className="login-form__input"
          name="email"
          label="Email"
          type="email"
          placeholder="you@domain.com"
          fullWidth
        />
        <FormikInput
          className="login-form__input"
          name="password"
          label="Password"
          type="password"
          placeholder="••••••••"
          fullWidth
        />
        <Button
          className="login-form__button"
          color="primary"
          variant="contained"
          disableElevation
          size="large"
          type="submit"
        >
          Sign In
        </Button>
      </StyledSignInForm>
    </Formik>
  )
}

export default SignInForm
