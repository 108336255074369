import React,{useState,useRef,useContext,useCallback} from "react";
import EditableTextField from "./EditableTextField";
import  {getPriceForConfiguration,ProductConfiguration,generateGoldmanReferenceNumber,calculateGoldmanCost, RendererLayoutMode}  from "@tiary-inc/tiary-shared";
import * as _ from "lodash";
import { PricingContext } from '../../../../../contexts/Pricing';
import Modal from '@material-ui/core/Modal'
import { makeStyles } from '@material-ui/core/styles'
import { getConsumerProduct } from "../../../../../utils/getConsumerProduct";
import {TiaryRenderer} from '@tiary-inc/tiary-components';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    content: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: '5px',
        fontFamily:'Roboto, Helvetica, Arial, sans-serif',
    },
    

}))
interface EditableLevelProps{
    levelValues: any;
    product: any;
    propConfiguration?: any;
    modifyOffsets:any;
    modifyComponentPrice?: any;
    propBreakdown?:any;
}

const EditableLevel:React.FC<EditableLevelProps> = ({
    levelValues: {component_price, config_name,children, type, quality},//levels from the datastructure I made
    product,//currentProduct to run getPriceForConfiguration
    modifyOffsets,//function to see if the retail price is changed
    propBreakdown,
    modifyComponentPrice,//function to resolve the componentPrice changes
}) => {
    const classes = useStyles()

    const { goldmanReference } = useContext(PricingContext);
    //* we will start with a blank configuration. in each level, we have config_name, so based on that we can add quality and type or both
    
    const makeBreakdown = useCallback(()=>{
        let breakdownComponent ={}
        if (quality) {
            breakdownComponent={METAL_1 : {
                quality: quality,
                type: type,
                component_price:component_price //adding this just to see if it works
                }
            }
        } else if (type === "RING_SIZE") {
            breakdownComponent={RING_SIZE:{
                    type: config_name,
                    component_price:component_price
                }
            }

        } else if ( type === "CARAT_WEIGHT") {
            breakdownComponent={CARAT_WEIGHT :{
                type: config_name,
                component_price:component_price
                }
            }
        }
        const levelBreakdown = {
            ...propBreakdown,
            ...breakdownComponent
        }
        return levelBreakdown;
    },[ component_price, config_name, propBreakdown, quality, type]);


    const makeConfiguration = useCallback(()=>{

        //we can generate configuration from the breakdown
        const currentBreakdown = makeBreakdown();
        const currentConfiguration={}
        Object.keys(currentBreakdown).forEach((key)=>{
            //key looks like "METAL_1" or "CARAT_WEIGHT" or "RING_SIZE"
            const component = currentBreakdown[key];
            //component looks like {type:'5', component_price:200}
            // configuration should not have component_price
            const {component_price,...rest} = component;
            currentConfiguration[key] = rest
        });
        return currentConfiguration

    },[ makeBreakdown]);

    
    const initialCost = calculateGoldmanCost({
        product:product,
        configuration:makeConfiguration(),
        goldmanData:goldmanReference
    }) 
    const [costState, setCostState] = useState(initialCost);
    const [infoModal, setInfoModal] = useState(false);

    const handleInfo = () => {
        setInfoModal(!infoModal)
    }

    const calculateRetailPrice = useCallback(()=>{

        const consumerProduct = getConsumerProduct(product);
        console.log(consumerProduct,'this value is the changed product')
        console.log(getPriceForConfiguration({
            product:consumerProduct,
            configuration:makeConfiguration() 
        }),'its price')
        return getPriceForConfiguration({
            product:consumerProduct,
            configuration:makeConfiguration() 
        })
    },[product,makeConfiguration])

    const calculateOffset=useCallback(()=>{
        
        let offsetPrice = 0;
        const offsetOption = _.find(product.offsets,{configuration:makeConfiguration()})
        if(offsetOption){
            offsetPrice = offsetOption.offsetPrice
        }
        return offsetPrice

    },[product,makeConfiguration]);
        

    const [expanded,setExpanded]=useState(false)
    const [configVisible,setConfigVisible] = useState(false)
    const highlighted = useRef(null);
    const showChildren=()=>{
        if(children && children.length>0){
            setExpanded(!expanded)
        }else{
            alert('no children')
        }
    }

    const show=()=>{
        //if there are no children, show configuration
        if(!children){
            setConfigVisible(!configVisible)
        }
    }

    const updateComponentPrice = (value: any) => {
        let configComponent = {}
        if (quality) {
            configComponent = {
                METAL_1: {
                    quality: quality,
                    type: type,
                    component_price: value //adding this just to see if it works
                }
            }
        } else if (type === "RING_SIZE") {
            configComponent = {
                RING_SIZE: {
                    type: config_name,
                    component_price: value 
                }
            }

        } else if (type === "CARAT_WEIGHT") {
            configComponent = {
                CARAT_WEIGHT: {
                    type: config_name,
                    component_price: value 
                }
            }
        }
        
        // what if we send an object that looks like METAL_1: {type: type, quality: quality}
        modifyComponentPrice(configComponent)
        

    }
    const updateRetailPrice=(newValue:any)=>{
        //retail price modification implies an offset has been added 

        if(!product.offsets){
            product.offsets=[];

        }
        
        
        //since the retailPrice  has offset that's already been applied in it we gotta remove that first
        const appliedOffset = calculateOffset()
        const retailPriceWithoutCurrentOffset = calculateRetailPrice() - appliedOffset; 
        const newOffsetValue = newValue - retailPriceWithoutCurrentOffset;


        const changedOffset = {
            configuration:makeConfiguration() as ProductConfiguration,
            offsetPrice:  newOffsetValue
        }

        modifyOffsets(changedOffset);
    }

    const updateCost=()=>{
        // placeholder for if pam wants a cost changing function
        setCostState(initialCost)
    }

    const generateProductSpecWithBase=()=>{
        const consumerProduct = getConsumerProduct(product);
        //at the carat weight level, the ring size component of the configuration would be empty,this is vital to generate goldman style number. The style number isn't dependent on ring size
        const configAtCurrentLevel = makeConfiguration()

        //since pendants might not have ring size. 
        const configForUse = product.pricing['RING_SIZE']?
                            {...configAtCurrentLevel,
                                        RING_SIZE:{
                                            type:"9"
                                        }
                             }:
                             {...configAtCurrentLevel,
                                        CARAT_WEIGHT:{
                                            type:"1.00"
                                        }
                            }

        const goldmanStyleNumber = generateGoldmanReferenceNumber({
                                        product:product,
                                        configuration:configForUse
                                    })
        const modifiedBase = `${consumerProduct.sku}/${goldmanStyleNumber}.jpg`
        
        return {...consumerProduct,base:modifiedBase}
    }

  return (
    < >
  
    <ul className={expanded?"upperLevel focusedLevel":"upperLevel"} onClick={show}>
        <li ref={highlighted}>
            <ul className={expanded || configVisible?"inlineLevel highlighted":"inlineLevel"} >
                {   (children && children.length>0)
                    ? <li className="expandButton">
                        <button type="button" onClick={showChildren}>{expanded?"Close":"Open"}</button>
                        </li>
                    : <li className="expandButton"></li>
                }
                <li>
                    {type==='CARAT_WEIGHT'?
                    <TiaryRenderer
                        backgroundHex="#FFFFFF"
                        productSpec={generateProductSpecWithBase()}    
                        configuration={makeConfiguration()}
                        layout={{
                            focalPointY: 0.8,
                            height: 150,
                            mode:RendererLayoutMode.Responsive,
                            width: 150,
                            zoomFactor: 0.6,
                        }}
                    />
                    :<></>
                    }
                    
                </li>
                <li className={expanded || configVisible?"configName": ""}>
                    <div className="textFieldHeader">Config Name</div>
                    <div className="textType">{type.replace(/[_-]/g, ' ').toLowerCase()}</div>
                    <div className="textName">{config_name.replace(/[_-]/g, ' ').toLowerCase()}</div>
                </li> 
                <li className="componentPrice">   
                    <EditableTextField disabled={expanded} inputName={"Component Price"} defaultValue={component_price} onEdited={updateComponentPrice} helpText={"price will be edited for all variants with this configuration, to just change this variant, please modify directly."}/>
                </li>
                <li>

                    {(children && children.length>0) || expanded?
                    ''
                    : <EditableTextField disabled={expanded} inputName={"Retail Price"} defaultValue={calculateRetailPrice()} onEdited={updateRetailPrice}/>

                    }
                </li>
                <li>
                    {(children && children.length > 0) ?
                    ""
                    :<>

                        <EditableTextField disabled={expanded} inputName={"Cost"} defaultValue={costState} onEdited={updateCost} noButton={true}/>
                        <div className="margin">Margin: {`${((calculateRetailPrice() - costState) * 100 / calculateRetailPrice()).toFixed(2)}%`}</div>

                     </>
                    }
                </li>   
                    {(children && children.length > 1) ? 
                    <>
                        <li>
                            <div 
                                className="infoButtonContainer" 
                                onClick={handleInfo}
                            >
                            <p className="infoButton" >?</p>
                            </div>
                        </li>
                        {infoModal ? 
                        <Modal
                            open
                            onClick={handleInfo}
                            className={classes.modal}
                        >
                            <div className={classes.content}>
                                <ul>
                                    <li>
                                        <p>You can set retail prices of <b>Goldman</b> configurations in this module.</p>
                                        <p>Click <b>open</b> to see the configurations.</p>
                                        <p>Opening a <b>metal type</b> shows <b>carat weights</b>, and opening a carat weight shows <b>ring sizes</b>. </p>
                                        <p>Clicking anywhere in <b>ring size</b> shows how the retail price for that configurationis calculated.</p>
                                        <p>Editing <b>component price</b> lets you set the component price for all configurations that have that component.</p>
                                        <p>Editing <b>retail price</b> lets you set a specific price for a specific configuration. For example, you can set a configuration of <b>14kt yellow gold ct wt 0.5 ring size 4</b> to $900.</p>
                                    </li>
                                </ul>
                            </div>
                        </Modal> : null}
                    </> 
                    : 
                    <>
                        <li>
                            <div className="infoButtonContainerInvis">
                                <p className="infoButton" >?</p>
                            </div>
                        </li>
                    </>
                    }

            </ul>
            {
                configVisible?
                <div className="configDiv"> 
                    <div className="configHeader"> Retail Price Breakdown</div>
                    <div className="configContent">
                        <div>Goldman Reference No: {generateGoldmanReferenceNumber({ product: product, configuration: makeConfiguration() })}
                            <div className="breakdownImageContainer"> 
                                <TiaryRenderer
                                    backgroundHex="#FFFFFF"
                                    productSpec={generateProductSpecWithBase()}    
                                    configuration={makeConfiguration()}
                                    layout={{
                                        focalPointY: 0.8,
                                        height: 150,
                                        mode:RendererLayoutMode.Responsive,
                                        width: 150,
                                        zoomFactor: 0.6,
                                    }}
                                />
                            </div>
                        </div>

                        <table className="breakdownContainer">
                            
                            <tr>
                                <td>
                                    Metal Price:
                                </td>  
                                <td>
                                    {makeBreakdown().METAL_1.component_price}
                                </td>
                            </tr>
                            {   makeBreakdown().CARAT_WEIGHT?
                                <tr>
                                    <td>
                                        Carat Weight Price:
                                    </td>
                                    <td>
                                        
                                     {makeBreakdown().CARAT_WEIGHT.component_price}
                                    </td> 
                                </tr>
                                :
                                <></>
                            }
                            
                            {   makeBreakdown().RING_SIZE?
                                <tr>
                                    <td>
                                        Ring Size Price:
                                    </td>
                                    <td>
                                        
                                     {makeBreakdown().RING_SIZE.component_price}
                                    </td> 
                                </tr>
                                :
                                <></>
                            }
                            <tr>
                                <td>
                                    Retail Offset:   
                                </td>
                                <td>  
                                  {calculateOffset()}
                                </td> 
                            </tr>
                            
                            <tr>
                                <td>
                                    Final Retail Price:     
                                </td>
                                <td>
                                    {calculateRetailPrice()}         
                                </td>  
                            </tr>
                        </table>
                    </div>
                    
                </div>
                :<></>
            }
            
            {
                expanded?
                <div className="childLevel">
                    {
                    children?.map((entry, index)=>{
                        return(
                            <EditableLevel levelValues={entry} key={index} propConfiguration={makeConfiguration()}
                            modifyOffsets={modifyOffsets}
                            product={product}
                            modifyComponentPrice={modifyComponentPrice}
                            propBreakdown = {makeBreakdown()}
                            />
                        )
                    })}
                </div>
                :
                <></>
            }
        </li>
        

    </ul>

    </>
  )
}

export default EditableLevel;