import json2csv, { Parser } from 'json2csv';
import {
  describeConfiguration,
  describeOrderStatus,
  describeShippingOption,
  Order,
  OrderProduct,
  PriceRule,
} from '@tiary-inc/tiary-shared';

import {
  mutatedTableValue,
  tableRowData,
  tableValueDateFromFirebase,
  tableValuePrice,
  tableValuePriceFromStripe,
} from './table';

const fields: Array<json2csv.FieldInfo<Order>> = [
  { label: 'Order ID', value: 'id' },
  { label: 'Job ID', value: 'manufacturing.jobId' },
  { label: 'Date', value: tableValueDateFromFirebase('dateCreated') },
  { label: 'User ID', value: 'uid' },
  {
    label: 'Status',
    value: mutatedTableValue('status', { mutate: describeOrderStatus }),
  },
  { label: 'First Name', value: 'shippingAddress.firstName' },
  { label: 'Last Name', value: 'shippingAddress.lastName' },
  { label: 'Company', value: 'shippingAddress.company' },
  { label: 'Email', value: 'email' },
  { label: 'Phone', value: 'shippingAddress.phone' },
  { label: 'Street Address', value: 'shippingAddress.streetAddress' },
  { label: 'Locality', value: 'shippingAddress.locality' },
  { label: 'Region', value: 'shippingAddress.region' },
  { label: 'Country', value: 'shippingAddress.country' },
  { label: 'Postal Code', value: 'shippingAddress.postalCode' },
  {
    label: 'Shipping Option',
    value: mutatedTableValue('shippingOption.code', {
      mutate: describeShippingOption,
    }),
  },
  { label: 'Tracking Code', value: 'trackingCode' },
  { label: 'Stripe ID', value: 'payment.id' },
  { label: 'Amount Paid', value: tableValuePriceFromStripe('payment.amount') },
  { label: 'Currency', value: 'payment.currency' },
  { label: 'Conversion Rate', value: 'requiredPayment.conversionRate' },
  { label: 'Subtotal (USD)', value: tableValuePrice('pricing.subtotal') },
  { label: 'Discount (USD)', value: tableValuePrice('pricing.discount') },
  { label: 'Donation (USD)', value: tableValuePrice('pricing.donation') },
  { label: 'Shipping (USD)', value: tableValuePrice('pricing.shipping') },
  { label: 'Tax (USD)', value: tableValuePrice('pricing.tax') },
  { label: 'Total (USD)', value: tableValuePrice('pricing.total') },
  {
    label: 'Price Rules',
    value: mutatedTableValue('validPriceRules', {
      mutate: (rules: PriceRule[]) =>
        rules.map(({ code = '' }) => code).join(', '),
    }),
  },
  { label: 'Cart Size', value: 'products.length' },
  {
    label: 'Cart Description',
    value: mutatedTableValue('products', {
      mutate: (products: OrderProduct[]) =>
        products
          .map(
            (product) =>
              `${product.sku}\n${describeConfiguration(
                product.configuration,
                (details) =>
                  details
                    .map(({ label, value }) => `${label}: ${value}`)
                    .join('\n')
              )}`
          )
          .join('\n\n'),
    }),
  },
];

export const orderColumnLabels = fields.map(({ label }) => label);

export const tableDataFromOrders = (orders: Order[]) =>
  orders.map((order) => tableRowData(order, fields));

export const csvFromOrders = (orders: Order[]) =>
  new Parser<Order>({ fields }).parse(orders);
