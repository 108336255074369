import React from 'react'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import styled from 'styled-components'
import get from 'lodash/get'

import { ChangeModification } from './types'

const StyledChangedRow = styled(TableRow)`
  background-color: #fdffdc;
  .changed-row {
    &__action {
      font-weight: 600;
    }
  }
`

interface ChangedRowProps extends ChangeModification {
  properties: string[]
  transformDisplay?: (value: any) => any
}

export const ChangedRow: React.FunctionComponent<ChangedRowProps> = ({
  properties,
  oldData,
  newData,
  transformDisplay = (value) => value,
}) => {
  const displayOldData = transformDisplay(oldData)
  const displayNewData = transformDisplay(newData)
  return (
    <StyledChangedRow key={oldData?.id}>
      <TableCell className="changed-row__action">Changed</TableCell>
      {properties.map((property) => {
        const oldValue = get(displayOldData, property)
        const newValue = get(displayNewData, property)
        const changed = oldValue !== newValue
        return (
          <TableCell key={property}>
            {changed && `${oldValue} → `}
            {newValue}
          </TableCell>
        )
      })}
    </StyledChangedRow>
  )
}
