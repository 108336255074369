import React, { useState } from 'react';
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Box,
} from '@material-ui/core';
import firebase from 'firebase/app';
import styled from 'styled-components';
import { capitalize } from 'lodash';
import { useAuthedCollection } from '@humancollective/human-hooks-firebase';
import { useParams } from 'react-router-dom';
import { InventoryTransaction, ProductMetadata } from '@tiary-inc/tiary-shared';
import { addBusinessDays } from 'date-fns/esm';
import AddIcon from '@material-ui/icons/Add';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import fileDownload from 'js-file-download';

import { AddInventoryTransactionModal } from '../../components/Inventory/AddInventoryTransactionModal';

import { generateCsv } from '../../lib/components/CsvCollectionManager/utils';

const StyledEditInventoryPage = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 20px;

  .buttons-wrapper {
    display: inline-flex;
    justify-content: right;
    padding: 20px 0;
  }
`;

export const EditInventoryPage: React.FunctionComponent = () => {
  const { product } = useParams<{ product: string }>();

  const [addModalOpen, setAddModalOpen] = useState(false);

  const properties = ['product', 'type', 'orderId', 'count'];

  const transactions = useAuthedCollection({
    getQueryRef: () =>
      firebase
        .firestore()
        .collection('inventory')
        .doc(product)
        .collection('inventory-transactions')
        .orderBy('dateCreated', 'desc'),
      includeIds: true,
  }) as (InventoryTransaction & { id: string })[];

  const products = useAuthedCollection<ProductMetadata>({
    getQueryRef: () => firebase.firestore().collection('products'),
    includeIds: true,
  });

  const onDownload = async () => {
    const csv = await generateCsv(transactions || [], properties);
    fileDownload(csv || '', 'values.csv');
  };

  return (
    <StyledEditInventoryPage>
      {addModalOpen && (
        <AddInventoryTransactionModal
          onClose={() => setAddModalOpen(false)}
          products={products.map((product: ProductMetadata) => product.sku)}
          product={product}
        />
      )}

      <div className="buttons-wrapper">
        <Button onClick={() => setAddModalOpen(true)} startIcon={<AddIcon />}>
          Add
        </Button>
        <Button onClick={onDownload} startIcon={<ImportExportIcon />}>
          Export
        </Button>
      </div>
    
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Order Id</TableCell>
              <TableCell>Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions && transactions.map(({
              id,
              dateCreated,
              type,
              orderId,
              count,
            }: InventoryTransaction & { id: string }) => (
              <TableRow key={id}>
                <TableCell component="th">
                  {/* {format({
                    timestamp: dateCreated,
                    format: `MMM do yyyy @ p`,
                  })} */}
                  <br />
                  {addBusinessDays(dateCreated.toDate(), 10).toDateString()}
                </TableCell>
                <TableCell component="th">
                  {capitalize(type)}
                </TableCell>
                <TableCell component="th">
                  {orderId}
                </TableCell>
                <TableCell component="th">
                  {count}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledEditInventoryPage>
  )
}