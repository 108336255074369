import React, { FunctionComponent, useContext } from 'react'
import InputAdornment from '@material-ui/core/InputAdornment'
import { FindingMaterial } from '@tiary-inc/tiary-shared'

import { FormikInput } from '../../../../lib/components/FormikInput'
import { LocaleContext } from '../../../../contexts/Locale'
import { StyledManufacturerFindingsModal } from './style'

interface ManufacturerFindingsModalProps {
  name: string
  onClose: () => void
  values?: FindingMaterial[]
}

export const ManufacturerFindingsModal: FunctionComponent<ManufacturerFindingsModalProps> = ({
  name,
  onClose,
  values = [],
}) => {
  const { localize } = useContext(LocaleContext)
  return (
    <StyledManufacturerFindingsModal open onClose={onClose}>
      <div className="finding-cost-modal__wrapper">
        {values.map(({ id, quality, metal, finding }) => (
          <FormikInput
            className="editor-form__input"
            key={id}
            name={[name, id].join('.')}
            label={`${localize(quality)} ${localize(metal)}`}
            type="number"
            helperText={`The cost of "${localize(finding)}" in ${localize(
              quality,
            )} ${localize(metal)}.`}
            fullWidth
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            endAdornment={<InputAdornment position="end">USD</InputAdornment>}
          />
        ))}
      </div>
    </StyledManufacturerFindingsModal>
  )
}
