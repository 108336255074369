import { Order } from '@tiary-inc/tiary-shared'
import React from 'react'
import { ListItemText } from '@material-ui/core'

interface ListManufacturerProps {
  order: Order
}

export const UnknonwManufacturer: React.FunctionComponent<ListManufacturerProps> = ({
  order,
}) => (
  <ListItemText
    primary="Unknown Manufacturer"
    secondary={order.manufacturing}
  />
)

export const MasterCastingManufacturer: React.FunctionComponent<ListManufacturerProps> = ({
  order,
}) => (
  <ListItemText
    primary="Master Casting & CAD"
    secondary={
      Object.values(order._MasterCasting?.jobs || {}).join(', ') ||
      '(No Job IDs)'
    }
  />
)

export const InventoryManufacturer: React.FunctionComponent<ListManufacturerProps> = () => (
  <ListItemText
    primary="Physical Inventory"
    secondary="Product was sold at a pop-up shop."
  />
)

export const UnassignedManufacturer: React.FunctionComponent<ListManufacturerProps> = () => (
  <ListItemText
    primary="Unassigned"
    secondary="Order will be assigned to a manufacturer."
  />
)
