import React, { useState, useEffect } from 'react';
import {
  Button,
  Box,
  TextField
} from '@material-ui/core';
import firebase from 'firebase/app';
import { useAuthedDocument } from '@humancollective/human-hooks-firebase';

export const InventoryPricingForm: React.FunctionComponent<any> = ({
  sku,
}) => {
  const [price, setPrice] = useState<number>();
  const [mccCost, setMccCost] = useState<number>();
  const [pgCost, setPgCost] = useState<number>();
  const inventoryPrice = useAuthedDocument<any>({
    getQueryRef: () => firebase.firestore().collection('prices').doc(sku),
  });
  const inventoryCosts = useAuthedDocument<any>({
    getQueryRef: () => firebase.firestore().collection('costs').doc(sku),
  });

  const changePrice = async () => {
    await firebase
      .firestore()
      .collection('prices')
      .doc(sku)
      .set({ price })
  }

  const changeMccCost = async () => {
    let data = {}
    const dataRef = await firebase
      .firestore()
      .collection('costs')
      .doc(sku)
      .get();
    if (dataRef.exists) {
      data = {
        ...dataRef.data(),
        'UVK19KsGZDuL9rpUhZYv': [ mccCost ],
      }
    } else {
      data = {
        'UVK19KsGZDuL9rpUhZYv': [ mccCost ],
      }
    }
    await firebase
      .firestore()
      .collection('costs')
      .doc(sku)
      .set(data);
  }

  const changePgCost = async () => {
    let data = {}
    const dataRef = await firebase
      .firestore()
      .collection('costs')
      .doc(sku)
      .get();
    if (dataRef.exists) {
      data = {
        ...dataRef.data(),
        'yHNgt1AA1mPJgoCZskqF': [ pgCost ],
      }
    } else {
      data = {
        'yHNgt1AA1mPJgoCZskqF': [ pgCost ],
      }
    }
    await firebase
      .firestore()
      .collection('costs')
      .doc(sku)
      .set(data);
  }

  useEffect(() => {
    if (inventoryPrice && inventoryPrice.price) {
      setPrice(inventoryPrice.price);
    }
    if (inventoryCosts && inventoryCosts['UVK19KsGZDuL9rpUhZYv']) {
      setMccCost(inventoryCosts['UVK19KsGZDuL9rpUhZYv'][0]);
    }
    if (inventoryCosts && inventoryCosts['yHNgt1AA1mPJgoCZskqF']) {
      setPgCost(inventoryCosts['yHNgt1AA1mPJgoCZskqF'][0]);
    }
  }, [inventoryPrice, inventoryCosts]);

  return (
    <Box
      paddingTop={1}
      paddingBottom={1}
      marginBottom={5}
      display="flex"
      flexDirection="row"
      gridGap={20}
    >
      <Box>
        <TextField
          name="price"
          helperText="Inventory Price"
          type="number"
          value={price}
          onChange={e => setPrice(+e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={changePrice}
        >
          SET
        </Button>  
      </Box>
      <Box>
        <TextField
          name="mccCost"
          helperText="MCC Cost"
          type="number"
          value={mccCost}
          onChange={e => setMccCost(+e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={changeMccCost}
        >
          SET
        </Button>
      </Box>
      <Box>
        <TextField
          name="pgCost"
          helperText="Personalized GEM Cost"
          type="number"
          value={pgCost}
          onChange={e => setPgCost(+e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={changePgCost}
        >
          SET
        </Button>
      </Box>
    </Box>
  );
};
