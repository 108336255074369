import React from 'react'
import firebase from 'firebase'

import { GalleryTemplate } from '../../lib/templates/GalleryTemplate'
import { ManufacturerCardView } from '../../components/Manufacturer/CardView'

/**
 * Manufacturers Page
 * ---
 *
 * This page displays a list of manufacturers that are available for editing.
 *
 * Each manufacturer contains costs related to the production of a product.
 * These raw costs are used in conjunction with product data to calculate
 * manufacturing costs and consumer pricing.
 */

export const ManufacturersPage: React.FunctionComponent = () => (
  <GalleryTemplate
    title="Manufacturers"
    renderItem={(manufacturer) => (
      <ManufacturerCardView manufacturer={manufacturer} />
    )}
    getQueryRef={() =>
      firebase
        .firestore()
        .collection('dashboard')
        .doc('data')
        .collection('manufacturers')
    }
    includeIds
  />
)
