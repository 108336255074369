import React, { useState } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'
import CardActions from '@material-ui/core/CardActions'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import styled from 'styled-components'
import {
  useAuthedCollection,
  useAuthedCollectionCount,
} from '@humancollective/human-hooks-firebase'
import fileDownload from 'js-file-download'

import { AddedRow } from './RowAdded'
import { RemovedRow } from './RowRemoved'
import { ChangedRow } from './RowChanged'
import { ImportCsv } from '../../../lib/components/ImportCsv'
import { Modification, ModificationType } from './types'
import {
  generateChangeSet,
  executeChangeSet,
  generateCsv,
  expandCsv,
} from './utils'

const StyledCsvCollectionManager = styled(Card)``

interface CsvCollectionManagerProps {
  title?: string
  collectionRef: firebase.firestore.CollectionReference
  queryRef?: firebase.firestore.Query
  properties: string[]
  csvProperties?: string[]
  uploadFtp?: boolean
  transformImport?: (value: any) => any
  transformExport?: (value: any) => any
  transformDisplay?: (value: any) => any
  transformProperties?: (value: any) => any
}

export const CsvCollectionManager: React.FunctionComponent<CsvCollectionManagerProps> = ({
  title,
  collectionRef,
  queryRef,
  properties,
  csvProperties,
  uploadFtp = false,
  transformImport,
  transformExport,
  transformDisplay,
  transformProperties,
}) => {
  const [showModal, setShowModal] = useState(false)
  const [changes, setChanges] = useState<Modification[]>([])
  const [importProgress, setImportProgress] = useState<number>()

  const oldValues = useAuthedCollection({
    getQueryRef: () => queryRef || collectionRef,
    includeIds: true,
  })

  const count = useAuthedCollectionCount({
    getQueryRef: () => queryRef || collectionRef,
  })

  const onUpload = async (csvObj: any[]) => {
    const newValues = expandCsv(csvObj, properties, transformImport)
    const nextChanges = await generateChangeSet(
      oldValues,
      newValues,
      properties,
    )
    setChanges(nextChanges)
    setShowModal(true)
  }

  const onDownload = async () => {
    const csv = await generateCsv(oldValues || [], properties, csvProperties, transformExport, transformProperties, uploadFtp)
    fileDownload(csv || '', 'values.csv')
  }

  const onExecute = async () => {
    setShowModal(false)
    await executeChangeSet({
      changes,
      collectionRef,
      onProgress: setImportProgress,
    })
    setImportProgress(undefined)
  }

  const renderChange = (change: Modification) => {
    switch (change.type) {
      case ModificationType.Add:
        return (
          <AddedRow
            properties={properties}
            transformDisplay={transformDisplay}
            {...change}
          />
        )
      case ModificationType.Remove:
        return (
          <RemovedRow
            properties={properties}
            transformDisplay={transformDisplay}
            {...change}
          />
        )
      case ModificationType.Change:
        return (
          <ChangedRow
            properties={properties}
            transformDisplay={transformDisplay}
            {...change}
          />
        )
      default:
        return null
    }
  }

  return (
    <>
      <StyledCsvCollectionManager>
        <CardContent>
          {title && (
            <Typography variant="h5" component="h2">
              {title}
            </Typography>
          )}
          <Typography color="textSecondary" gutterBottom>
            {count} values
          </Typography>
          {!!importProgress && (
            <Box paddingTop={2}>
              <Typography gutterBottom>{importProgress}% imported</Typography>
              <LinearProgress variant="determinate" value={importProgress} />
            </Box>
          )}
        </CardContent>
        <CardActions>
          <Button onClick={onDownload}>Export</Button>
          <ImportCsv buttonLabel="Import" onSuccess={onUpload} />
        </CardActions>
      </StyledCsvCollectionManager>

      <Dialog open={showModal} onClose={() => setShowModal(false)}>
        <DialogTitle id="simple-dialog-title">Confirm Changes</DialogTitle>
        <DialogContent>
          {changes.length !== 0 ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Change</TableCell>
                  {properties.map((property) => (
                    <TableCell key={property}>{property}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>{changes.map(renderChange)}</TableBody>
            </Table>
          ) : (
            <Typography>This import did not result in any changes.</Typography>
          )}
        </DialogContent>
        <DialogActions>
          {changes.length !== 0 ? (
            <>
              <Button onClick={() => setShowModal(false)}>Cancel</Button>
              <Button onClick={onExecute} color="primary">
                Execute Changes
              </Button>
            </>
          ) : (
            <Button onClick={() => setShowModal(false)} color="primary">
              Close
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  )
}
