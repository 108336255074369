import {
  ProductSpec,
  DashboardProduct,
  ProductOptionType,
  DashboardProductSizeOption,
  DashboardProductTextOption,
  DashboardProductQuantityOption,
  DashboardProductGroupedOption,
  isGoldmanProduct,
  ProductOption,
} from '@tiary-inc/tiary-shared';
import * as _ from 'lodash';
const generateDefaultProduct =(dashboardProduct: DashboardProduct)=>{
    
  const options = dashboardProduct.options.map((option) => {
    const { label, name, type } = option;

    if (
      type === ProductOptionType.BraceletSize ||
      type === ProductOptionType.RingSize
    ) {
      const sizeOption = option as DashboardProductSizeOption;
      const values = sizeOption.values.map((type) => ({ type }));
      return {
        label,
        name,
        type,
        values,
      };
    } else if (
      type === ProductOptionType.Engraving ||
      type === ProductOptionType.Text
    ) {
      const textOption = option as DashboardProductTextOption;

      if (!textOption.regex) {
        throw new Error(
          'Text fields require a regex pattern. Default is [a-zA-Z0-9]*.'
        );
      }

      return {
        label,
        name,
        type,
        ...(typeof textOption.regex === 'string' && {
          regex: textOption.regex,
        }),
        ...(typeof textOption.required === 'boolean' && {
          required: textOption.required,
        }),
        ...(typeof textOption.minLength === 'number' && {
          minLength: textOption.minLength,
        }),
        ...(typeof textOption.maxLength === 'number' && {
          maxLength: textOption.maxLength,
        }),
      };
    } else if (
      type === ProductOptionType.Stone ||
      type === ProductOptionType.Metal
    ) {
      if (!dashboardProduct.pricing) {
        throw new Error(`product ${dashboardProduct.id} does not have pricing`);
      }
      if (!dashboardProduct.renders) {
        throw new Error(`product ${dashboardProduct.id} does not have renders`);
      }
      const optionValues = dashboardProduct.pricing[option.name];
      const renders = dashboardProduct.renders[option.name];
      const values = optionValues.map((value: any) => {
        const render = renders[value.type as ProductOptionType];
        if (!render) {
          throw new Error(`render missing for ${value.type}`);
        }
        return { ...value, render };
      });
      return {
        label,
        name,
        type,
        values,
      };
    } else if (type === ProductOptionType.Quantity) {
      const quantityOption = option as DashboardProductQuantityOption;
      const values = quantityOption.values
        .map((qty: string) => Number(qty))
        .map((qtyNum) => ({
          type: `${qtyNum}`,
          multiplier: qtyNum / 2,
        }));
      return {
        label,
        name,
        type,
        values,
      };
    } else if (type === ProductOptionType.Chain) {
      if (!dashboardProduct.pricing) {
        throw new Error(`product ${dashboardProduct.id} does not have pricing`);
      }
      const values = dashboardProduct.pricing[option.name];
      return {
        label,
        name,
        type: 'CHAIN_LENGTH',
        values,
      };
    } else if (type === 'GROUPED') {
      const groupedOption = option as DashboardProductGroupedOption;
      return {
        label,
        name,
        type,
        values: groupedOption.values.map((value) => ({ fieldName: value })),
      };
    }

    return {
      label,
      name,
      type,
    };
  });

  const nameOption = dashboardProduct.options.find(
    (option) =>
      option.type === 'TEXT' && option.maxLength && option.maxLength > 1
  )?.name;

  let basePriceToUse = dashboardProduct.basePrice || 0;

  // hack for setting the custom pricing
  // if the product has a custom price set
  // then use the original base price + custom price = the new product pricing
  const productFindings = dashboardProduct.findings || [];
  const customPriceFinding = productFindings.find(
    (finding) => finding.type === 'CUSTOM_PRICE'
  );
  const productPricingForNone = dashboardProduct.pricing
    ? dashboardProduct.pricing['NONE']
    : undefined;

  if (customPriceFinding && productPricingForNone) {
    // @ts-ignore
    basePriceToUse += productPricingForNone.price;
  }

  return {
    sku: dashboardProduct.id,
    shadow: dashboardProduct.renders?.SHADOW,
    base: dashboardProduct.renders?.BASE || '',
    ...(!!nameOption && { nameOption }),
    options,
    basePrice: basePriceToUse,
    renderConstraints: dashboardProduct.renderConstraints || {},
    renderEnvironments: {},
    // @ts-ignore
    dateAdded: dashboardProduct.dateAdded,
  } as ProductSpec;
}

const generateGoldmanProduct = (dashboardProduct: any) => {

  const basePriceToUse = dashboardProduct.basePrice || 0;
  const {styleFamily,styleSetCode,pricing:pricingObject} = dashboardProduct;
  //now we can actually change the data structure here... we can actually just generate all the renders out of the pricing document itself. 
  const productOptions = dashboardProduct.options || {};

  const options:ProductOption[] = productOptions.map((option:ProductOption) => {
    //each option will have four values: name, label, type and values. its only the metal for which we have to ensure that name is METAL_1 and type is METAL

    let modifiedType = option.type
    if (option.name ===  "METAL_1") {
      modifiedType = "METAL"  ;
    }
    //engraving doesn't have values
    if(option.values){
      option.values.forEach((value)=>{
        let priceEntry:any = {}
        const {type,quality} = value
        //add the corresponding price entry
        if(modifiedType === "METAL"){
          priceEntry = _.find(pricingObject["METAL_1"],{type:type,quality:quality})
        }else{
          priceEntry = _.find(pricingObject[option.type],{type:type})
        }
        if(priceEntry && priceEntry.price){
          value.price = priceEntry.price
        }
        // console.log('added price',value)
      })
    }


    return (
      {
        ...option,
        type: modifiedType
      }
    )
  })
  
  const finaloffsets = dashboardProduct.offsets || [];
  
  const finalConsumerProduct =  {
    sku: dashboardProduct.id,
    shadow: dashboardProduct.renders?.SHADOW,
    base: `${dashboardProduct.id}/${dashboardProduct.renders?.BASE}` || '',
    // ...(!!nameOption && { nameOption }),
    options:options,
    basePrice: basePriceToUse,
    offsets:finaloffsets,
    renderConstraints: dashboardProduct.renderConstraints || {},
    renderEnvironments: {},
    styleFamily: styleFamily,
    styleSetCode: styleSetCode,
    // @ts-ignore
    dateAdded: dashboardProduct.dateAdded,
  } as ProductSpec;
  return finalConsumerProduct
}

export const getConsumerProduct = (dashboardProduct: any) => {
  //gotta say if its a goldman product things have to be different here,
  // const goldmanSkus = ['RDP-0001',"RD-0001"];
  // const isGoldmanProduct = goldmanSkus.includes(dashboardProduct.id);
  return isGoldmanProduct(dashboardProduct.id) ? generateGoldmanProduct(dashboardProduct) : generateDefaultProduct(dashboardProduct) 
};
