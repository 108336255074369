import React, { useContext } from 'react'
import firebase from 'firebase/app'
import { useAuthedCollection } from '@humancollective/human-hooks-firebase'
import { Manufacturer } from '@tiary-inc/tiary-shared'
import uniq from 'lodash/uniq'
import uniqWith from 'lodash/uniqWith'
import isEqual from 'lodash/isEqual'
import sortBy from 'lodash/sortBy'

import EditorTemplate from '../../lib/templates/EditorTemplate'
import { EditorFormFieldType } from '../../lib/components/EditorForm/Fields/getFormField'
import { MaterialsContext } from '../../contexts/Materials'
import { LocaleContext } from '../../contexts/Locale'

/**
 * Pricing Page
 * ---
 *
 * This page contains a set of variables used to convert manufacturing costs
 * into pricing. For example, margins.
 *
 * NOTE: some of these variables can be over-ridden on a per-product basis.
 */

export const PricingPage: React.FunctionComponent = () => {
  const { stoneMaterials, metalMaterials } = useContext(MaterialsContext)
  const { localize } = useContext(LocaleContext)

  const stoneQualities = uniq(stoneMaterials.map(({ quality }) => quality))
  const metalQualities = uniqWith(
    metalMaterials.map(({ quality, metal }) => ({ quality, metal })),
    isEqual,
  )

  const manufacturers = useAuthedCollection({
    getQueryRef: () =>
      firebase
        .firestore()
        .collection('dashboard')
        .doc('data')
        .collection('manufacturers'),
    includeIds: true,
  }) as Manufacturer[]
  return (
    <EditorTemplate
      title="Pricing"
      getQueryRef={() =>
        firebase.firestore().collection('dashboard').doc('pricing')
      }
      formSchema={[
        // {
        //   filename: `pricing`,
        //   type: EditorFormFieldType.ImportExport,
        // },
        {
          label: 'Reference',
          type: EditorFormFieldType.Heading,
        },
        {
          name: 'manufacturerOfReference',
          label: 'Manufacturer of Reference',
          type: EditorFormFieldType.Select,
          options:
            manufacturers?.map(({ name, id }) => ({
              label: name,
              value: id,
            })) || [],
        },
        {
          name: 'rhodiumFactor',
          label: 'Rhodium Factor',
          type: EditorFormFieldType.Percent,
        },
        {
          label: 'Fixed Costs',
          type: EditorFormFieldType.Heading,
        },
        {
          label: 'Packaging',
          name: 'packaging',
          type: EditorFormFieldType.Money,
        },
        {
          label: 'Pick & Pack',
          name: 'pickAndPack',
          type: EditorFormFieldType.Money,
        },
        {
          label: 'Shipping Costs',
          name: 'shipping',
          type: EditorFormFieldType.Money,
        },
        {
          name: 'margins',
          label: 'Margins',
          type: EditorFormFieldType.Heading,
        },
        {
          name: 'margin.base',
          label: 'Default Base Margin',
          type: EditorFormFieldType.Percent,
        },
        ...sortBy(
          stoneQualities.map((quality) => ({
            name: `margin.stoneQuality.${quality}`,
            label: `${localize(quality)} Stone Margin`,
            type: EditorFormFieldType.Percent,
          })),
          'label',
        ),
        ...sortBy(
          metalQualities.map(({ quality, metal }) => ({
            name: `margin.metalQuality.${quality}`,
            label: `${localize(quality)} ${localize(metal)} Margin`,
            type: EditorFormFieldType.Percent,
          })),
          'label',
        ),
      ]}
    />
  )
}
