import React, { FunctionComponent } from 'react'

import { Typography, Box } from '@material-ui/core'

interface EditorFormHeadingFieldProps {
  label: string
  description?: string
}

export const EditorFormHeadingField: FunctionComponent<EditorFormHeadingFieldProps> = ({
  label,
  description,
}) => (
  <Box paddingBottom={1} paddingTop={1} key={`heading-${label}`}>
    <Typography variant="h6" className="editor-form__title">
      {label}
    </Typography>
    {description && (
      <Typography
        variant="body2"
        color="textSecondary"
        className="editor-form__description"
      >
        {description}
      </Typography>
    )}
  </Box>
)
