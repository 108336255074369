import React, { useMemo } from 'react';
import firebase from 'firebase';
import * as Yup from 'yup';
import EditorTemplate from '../../lib/templates/EditorTemplate';
import { useParams } from 'react-router-dom';
import { EditorFormFieldType } from '../../lib/components/EditorForm/Fields/getFormField';
import { UIContext } from '../../contexts/UI';


import { useAuthedCollection } from '@humancollective/human-hooks-firebase';
import { DashboardProduct, ProductMetadata } from '@tiary-inc/tiary-shared';
import { CombinedProduct } from '../products';

import styled from 'styled-components';

import { SalesSearch } from '../../components/SalesSearch';

// import { useFormikContext } from 'formik';

/**
 * Edit Sales Page
 * ---
 *
 * This page is for editing a sale.
 *
 *
 */

const SalesContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const EditSalesPage: React.FunctionComponent = () => {

  const { sale } = useParams<{sale: string}>();
  const ui = React.useContext(UIContext);

  const products = useAuthedCollection({
    getQueryRef: () =>
      firebase
        .firestore()
        .collection('dashboard')
        .doc('data')
        .collection('products'),
    includeIds: true,
  });

  const specs = useAuthedCollection({
    getQueryRef: () => firebase.firestore().collection('products'),
    includeIds: true,
  });

  const metadata = useAuthedCollection<ProductMetadata>({
    getQueryRef: () => firebase.firestore().collection('product-metadata'),
    includeIds: true,
  });

  const data = useMemo<CombinedProduct[]>(
    () =>
      products?.map((product: DashboardProduct & { id: string }) => {
        const meta = metadata?.find(
          ({ sku }: ProductMetadata) => sku === product.id
        );
        const spec = specs?.find(
          ({ sku }: ProductMetadata) => sku === product.id
        );
        return {
          product,
          spec,
          meta,
        };
      }) || null,
    [products, metadata, specs]
  );


  const timestampSchema = Yup.object().shape({
    seconds: Yup.number(),
    nanoseconds: Yup.number(),
  });

  const productSchema = Yup.object().shape({
    sku: Yup.string(),
    percentage: Yup.number().min(0)
  });

  const submitSchema = Yup.object().shape({
    code: Yup.string().required(),
    dateAdded: timestampSchema,
    // endDate: timestampSchema,

    endDate: timestampSchema.test(
      'is-greater',
      'End Date must be later than Start Date',
      function (value) {
        const { startDate } = this.parent;

        const startSeconds = startDate.seconds;
        const endSeconds = value.seconds;

        // Debugging logs
        console.log(startSeconds, '<- Start Date seconds', endSeconds, '<- End Date seconds');
        if(endSeconds < startSeconds) {
          ui.snackbar.show({ message: 'end date cant be earlier than start date!', type: 'error' });
        }
        // Check if the end date is greater than or equal to the start date
        return endSeconds >= startSeconds;
      }
    ),
    startDate: timestampSchema,
    defaultPercent: Yup.number().required(),
    description: Yup.string(),
    specificProducts: Yup.array().of(productSchema).required()
  });


  //* make optional validation prop (function?) that enables save button only when validation passes for editor template
  return (
    <SalesContainer>

      <EditorTemplate
        validationSchema={submitSchema}
        breadcrumbs={[
          { label: 'Sales', to: '/sales' },
          { label: 'Edit Sale' },
        ]}
        onSubmitSuccess={
          () => {
          ui.snackbar.show({ message: 'Sale saved!', type: 'success' });
        }
        }
        getQueryRef={() =>
          firebase.firestore().collection('ongoing-sales').doc(sale)
        }
        formSchema={[
          {
            name: 'code',
            label: 'Sale Name (required)',
            type: EditorFormFieldType.Text,
          },
          {
            name: 'defaultPercent',
            label: 'Discount Percentage (required)',
            type: EditorFormFieldType.Percent,
          },
          {
            name: 'startDate',
            label: 'Start Date (required)',
            type: EditorFormFieldType.Datetime,
            dateRequired: true,
          },
          {
            name: 'endDate',
            label: 'End Date (required)',
            type: EditorFormFieldType.Datetime,
            dateRequired: true
          },
          {
            name: 'description',
            label: 'Description',
            type: EditorFormFieldType.Text,
            multiline: true,
          },
          {
            name: 'Specific Products',
            label: 'Specific Products (required)',
            sale: sale,
            data: data,
            type: EditorFormFieldType.FieldComponent,
            FieldComponent: SalesSearch,
          },
        ]}
      />
    </SalesContainer>
  );
};


