import { createClient } from 'contentful-management';

const contentfulClient = createClient({
  accessToken: process.env.REACT_APP_CONTENTFUL_API_KEY as string,
});

export const getInstance = async () => {
  const contentfulSpace = await contentfulClient.getSpace('fata390wwc76');

  const contentfulEnv = await contentfulSpace.getEnvironment('master');

  return contentfulEnv;
};

export const checkIfInventoryItem = async (sku: string) => {
  const contentfulEnv = getInstance();

  const allProductEntries = await (await contentfulEnv).getEntries({
    content_type: 'product',
    limit: 999,
  });

  const product = allProductEntries.items.find(
    item => item.fields.sku && item.fields.sku['en-US'] === sku
  );

  if (product && product.fields.isInventoryItem) {
    return product.fields.isInventoryItem['en-US'];
  }
  return false;
}
