import React, { useContext, useState, useMemo } from 'react'
import uniqBy from 'lodash/uniqBy'
import sortBy from 'lodash/sortBy'
import Button from '@material-ui/core/Button'

import Loading from '../../../../lib/components/Loading'
import { LocaleContext } from '../../../../contexts/Locale'
import { MaterialsContext } from '../../../../contexts/Materials'
import { ManufacturerFinishingsModal } from './Modal'
import { StyledManufacturerFinishings } from './style'

interface ManufacturerFinishingsProps {
  name: string
}

export const ManufacturerFinishings: React.FunctionComponent<ManufacturerFinishingsProps> = ({
  name,
}) => {
  const { localize } = useContext(LocaleContext)
  const { metalMaterials } = useContext(MaterialsContext)
  const [modalName, setModalName] = useState<string>()

  const qualities = useMemo(() => {
    if (!metalMaterials) {
      return undefined
    }
    const allQualities = metalMaterials.map(({ quality, metal }) => ({
      label: [quality, metal].map(localize).join(' '),
      path: [name, metal, quality].join('.'),
    }))
    return sortBy(uniqBy(allQualities, 'path'), 'path')
  }, [metalMaterials, localize, name])

  const onSelectQuality = (q: string) => () => setModalName(q)
  const onCloseModal = () => setModalName(undefined)

  return qualities ? (
    <>
      {modalName && (
        <ManufacturerFinishingsModal name={modalName} onClose={onCloseModal} />
      )}
      <StyledManufacturerFinishings>
        {qualities.map(({ label, path }) => (
          <div className="manufacturer-finishings__finishing">
            <div className="manufacturer-finishings__finishing__label">
              <strong>{label}</strong>
            </div>
            <Button
              color="primary"
              size="small"
              onClick={onSelectQuality(path)}
            >
              Edit
            </Button>
          </div>
        ))}
      </StyledManufacturerFinishings>
    </>
  ) : (
    <Loading />
  )
}
