import React, { useContext } from 'react';
import uniq from 'lodash/uniq';
import { StoneMaterial } from '@tiary-inc/tiary-shared';

import { MaterialTableWithIcons } from '../../../../lib/components/MaterialTableWithIcons';
import { LocaleContext } from '../../../../contexts/Locale';
import { StoneSize } from './';

interface ProductStoneSizesFormProps {
  materials: StoneMaterial[];
  value: StoneSize[];
  onChange: (nextValue: StoneSize[]) => void;
}

export const ProductStoneSizesForm: React.FunctionComponent<ProductStoneSizesFormProps> = ({
  value,
  onChange,
  materials,
}) => {
  const { localize } = useContext(LocaleContext);
  const allSizes = uniq(materials.map(({ size }) => size));

  const sizesLookup: { [size: string]: string } = {};
  allSizes.forEach((size) => (sizesLookup[size] = localize(size)));

  return (
    <MaterialTableWithIcons
      columns={[
        {
          title: 'Quantity',
          field: 'quantity',
          type: 'numeric',
        },
        {
          title: 'Size',
          field: 'size',
          lookup: sizesLookup,
        },
      ]}
      data={value}
      title=""
      editable={{
        onRowAdd: async (newValue: StoneSize) => {
          const transformedValue = {
            ...newValue,
            quantity: Number(newValue.quantity),
          };
          const nextValue = [...value, transformedValue];
          onChange(nextValue);
        },
        onRowUpdate: async (newValue: StoneSize, oldValue: StoneSize) => {
          const transformedValue = {
            ...newValue,
            quantity: Number(newValue.quantity),
          };
          const index = value.findIndex(({ id }) => id === oldValue.id);
          if (typeof index !== 'number') {
            throw new Error('old value not found');
          }
          const nextValue = [...value];
          nextValue.splice(index, 1, transformedValue);
          onChange(nextValue);
        },
        onRowDelete: async (rowData: StoneSize) => {
          // keep the rows that dont share the same size & quantity
          const nextValues = value.filter(
            (rowValue) =>
              rowData.size !== rowValue.size &&
              rowData.quantity !== rowValue.quantity
          );
          onChange(nextValues);
        },
      }}
    />
  );
};
