import React from 'react';
import firebase from 'firebase';

import EditorTemplate from '../../lib/templates/EditorTemplate';
import { useParams } from 'react-router-dom';
import { EditorFormFieldType } from '../../lib/components/EditorForm/Fields/getFormField';
import { UIContext } from '../../contexts/UI';

/**
 * Edit Promotion Page
 * ---
 *
 * This page is for editing a single promotion. Promotions can be applied to
 * orders for discounts at checkout.
 *
 * NOTE: Promotions are called "price-rules" in the database for legacy reasons
 * (this is what PrestaShop called them).
 */

export const EditPromotionPage: React.FunctionComponent = () => {
  const { promotion } = useParams();
  const ui = React.useContext(UIContext);
  return (
    <EditorTemplate
      breadcrumbs={[
        { label: 'Promotions', to: '/promotions' },
        { label: 'Edit Promotion' },
      ]}
      onSubmitSuccess={() => {
        ui.snackbar.show({ message: 'Promotion saved!', type: 'success' });
      }}
      getQueryRef={() =>
        firebase.firestore().collection('price-rules').doc(promotion)
      }
      formSchema={[
        {
          name: 'code',
          label: 'Promo Code',
          type: EditorFormFieldType.Text,
        },
        {
          name: 'fixedAmount',
          label: 'Dollar Amount',
          type: EditorFormFieldType.Money,
        },
        {
          name: 'fixedAmountUsed',
          label: 'Amount Used',
          type: EditorFormFieldType.Money,
          disabled: true,
        },
        {
          name: 'isPrimary',
          label: 'Stackable',
          type: EditorFormFieldType.Switch,
          inverted: true,
        },
        {
          name: 'isPaused',
          label: 'Paused',
          type: EditorFormFieldType.Switch,
        },
        {
          name: 'percent',
          label: 'Discount Percentage',
          type: EditorFormFieldType.Percent,
        },
        {
          name: 'startDate',
          label: 'Start Date',
          type: EditorFormFieldType.Datetime,
        },
        {
          name: 'endDate',
          label: 'End Date',
          type: EditorFormFieldType.Datetime,
        },
        {
          name: 'description',
          label: 'Description',
          type: EditorFormFieldType.Text,
          multiline: true,
        },
      ]}
    />
  );
};
