import React from 'react'
import { Field, FieldProps } from 'formik'
import { FormControlProps, Select, MenuItem } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'

export interface FormikSelectProps {
  name: string
  label?: string
  required?: boolean
  helperText?: string
  options: { label: string; value: string }[]
}

export const FormikSelect: React.FunctionComponent<
  FormikSelectProps & FormControlProps
> = ({ name, label, options, className, helperText, ...rest }) => (
  <div className={className}>
    <Field name={name}>
      {({ field, form }: FieldProps) => {
        return (
          <FormControl {...rest}>
            {label && <InputLabel htmlFor={name}>{label}</InputLabel>}
            <Select {...field}>
              {options.map((option) => (
                <MenuItem value={option.value} key={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
            {form.touched && form.errors[name] ? (
              <FormHelperText>{form.errors[name]}</FormHelperText>
            ) : helperText ? (
              <FormHelperText>{helperText}</FormHelperText>
            ) : null}
          </FormControl>
        )
      }}
    </Field>
  </div>
)
