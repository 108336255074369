import React, { FunctionComponent } from 'react';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
import firebase from 'firebase';

import { TiaryRenderer } from '@tiary-inc/tiary-components';
import {
  getDefaultConfiguration,
  ProductSpec,
  ProductConfiguration,
  RendererLayoutMode,
  ProductRenderConstraints,
} from '@tiary-inc/tiary-shared';

const RENDER_PRESETS = [
  {
    label: 'Charm',
    values: {
      cursorX: 1000,
      cursorY: 1320,
      zoom: 1.8,
    },
  },
  {
    label: 'Necklace',
    values: {
      cursorX: 1000,
      cursorY: 1620,
      zoom: 1.2,
    },
  },
  {
    label: 'Ring',
    values: {
      cursorX: 1000,
      cursorY: 1300,
      zoom: 1,
    },
  },
  {
    label: 'Bracelet/Anklet',
    values: {
      cursorX: 1000,
      cursorY: 1150,
      zoom: 1,
    },
  },
  {
    label: 'Earrings',
    values: {
      cursorX: 1000,
      cursorY: 1300,
      zoom: 1.2,
    },
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalWrapper: {
    maxWidth: '70%',
    width: 350,
    padding: theme.spacing(3),
    background: 'white',
  },
  renderContent: {
    position: 'relative',
    border: '1px solid #948296',
  },
  textField: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));

interface ConstraintsModalProps {
  initialValues: any;
  onChange: (nextConstraints: any) => void;
  onClose: () => void;
  sku: string;
}

export const ConstraintsModal: FunctionComponent<ConstraintsModalProps> = ({
  initialValues,
  onChange,
  onClose,
  sku,
}) => {
  const classes = useStyles();

  const [productData, setProductData] = React.useState<
    { spec: ProductSpec; configuration: ProductConfiguration } | undefined
  >();
  const [renderDimensions, setRenderDimensions] = React.useState({
    height: 400,
    width: 400,
  });

  React.useEffect(() => {
    firebase
      .firestore()
      .collection('products')
      .doc(sku)
      .get()
      .then((productDoc) => {
        const productSpec: ProductSpec = productDoc.data() as ProductSpec;
        const defaultConfig = getDefaultConfiguration(productSpec);

        setProductData({ spec: productSpec, configuration: defaultConfig });
      });
    // eslint-disable-next-line
  }, []);

  const onSubmit = (values: ProductRenderConstraints): void => {
    onChange(values);
    onClose();
  };

  if (productData) {
    return (
      <Modal open={true} onClose={onClose} className={classes.root}>
        <div className={classes.modalWrapper}>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            enableReinitialize={true}
          >
            {({ values, handleSubmit, setValues, handleChange }) => (
              <Form onSubmit={handleSubmit}>
                <InputLabel>Preset:</InputLabel>
                <Select
                  labelId="render-presets"
                  id="demo-simple-select"
                  value=""
                  displayEmpty={true}
                  onChange={(event) => {
                    const selectionIndex = Number(event.target.value);
                    const presetForIndex = RENDER_PRESETS[selectionIndex];
                    setValues({ ...values, ...presetForIndex.values });
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {RENDER_PRESETS.map((preset, index) => (
                    <MenuItem key={preset.label} value={index}>
                      {preset.label}
                    </MenuItem>
                  ))}
                </Select>
                <TiaryRenderer
                  className={classes.renderContent}
                  productSpec={{
                    ...productData.spec,
                    renderConstraints: values,
                  }}
                  configuration={productData.configuration}
                  backgroundHex="white"
                  layout={{
                    mode: RendererLayoutMode.Responsive,
                    height: renderDimensions.height,
                    width: renderDimensions.width,
                    focalPointY: 0.9,
                  }}
                />

                <Grid container spacing={2} alignItems="center">
                  <Grid item xs>
                    <InputLabel>Render height</InputLabel>
                    <Input
                      value={renderDimensions.height}
                      margin="dense"
                      onChange={(event) => {
                        setRenderDimensions({
                          ...renderDimensions,
                          height:
                            event.target.value === ''
                              ? 0
                              : Number(event.target.value),
                        });
                      }}
                      inputProps={{
                        step: 10,
                        min: 100,
                        max: 1000,
                        type: 'number',
                        'aria-labelledby': 'input-slider',
                      }}
                    />
                  </Grid>
                  <Grid item xs>
                    <InputLabel>Render width</InputLabel>
                    <Input
                      value={renderDimensions.width}
                      margin="dense"
                      onChange={(event) => {
                        setRenderDimensions({
                          ...renderDimensions,
                          width:
                            event.target.value === ''
                              ? 0
                              : Number(event.target.value),
                        });
                      }}
                      inputProps={{
                        step: 10,
                        min: 100,
                        max: 1000,
                        type: 'number',
                        'aria-labelledby': 'input-slider',
                      }}
                    />
                  </Grid>
                </Grid>

                <TextField
                  className={classes.textField}
                  fullWidth={true}
                  type="number"
                  id="zoom"
                  name="zoom"
                  label="zoom"
                  value={values.zoom}
                  onChange={handleChange}
                />

                <TextField
                  className={classes.textField}
                  fullWidth={true}
                  type="number"
                  id="cursorX"
                  name="cursorX"
                  label="Cursor X:"
                  value={values.cursorX}
                  onChange={handleChange}
                />

                <TextField
                  className={classes.textField}
                  fullWidth={true}
                  type="number"
                  id="cursorY"
                  name="cursorY"
                  label="Cursor Y:"
                  value={values.cursorY}
                  onChange={handleChange}
                />

                <Button type="submit" variant="contained" color="primary">
                  Save
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    );
  }

  return null;
};
