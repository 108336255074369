import {
  uniq,
  ceil,
} from 'lodash';

import {
  DashboardProductMetalOption,
  DashboardProductChainOption,
  getCostForChainOption,
  ChainMaterial,
  applyMargin,
  DashboardUtilsBag,
} from '@tiary-inc/tiary-shared';
import { expandOptionValues, expandChainLengths } from '../../expandValues';
import { PriceCalculator } from '../types';

const getBaseMetalQualities = (
  option: DashboardProductMetalOption,
  bag: DashboardUtilsBag
) => {
  const values = expandOptionValues(option.values, bag) as ChainMaterial[];
  return uniq(values.map(({ quality }) => quality));
};

const getAllConfigsForChainOption = (
  option: DashboardProductChainOption,
  qualities: string[],
  bag: DashboardUtilsBag
) => {
  const configurations: { type: string; quality: string }[] = [];

  const expandedLengths = expandChainLengths(option.values, bag);

  expandedLengths.forEach((size) => {
    const type = (size as string).replace('_INCHES', '');
    qualities.forEach((quality) => {
      configurations.push({ type, quality });
    });
  });

  return configurations;
};

const getPricingForChain: PriceCalculator = (
  { product, manufacturer, margins, option },
  bag
) => {
  const baseMetalOption = product.options.find(
    ({ name }) => name === 'METAL_1'
  ) as DashboardProductMetalOption;
  const qualities = getBaseMetalQualities(baseMetalOption, bag);
  const allPrices = getAllConfigsForChainOption(
    option as DashboardProductChainOption,
    qualities,
    bag
  ).map(({ type, quality }) => {
    const configuration = {
      [option.name]: { type, quality },
    };
    const costs = getCostForChainOption(
      {
        configuration,
        option,
        product,
        manufacturer,
      },
      bag
    );

    let total = 0;
    for (const cost of costs) {
      if (cost.type !== 'ERROR') {
        total += (cost as any).cost;
      } else {
        console.error('error');
      }
    }
    const margin =
      (margins.metalQuality && margins.metalQuality[quality]) || margins.base;
    return {
      // price: round(applyMargin(total, margin), 2),
      price: ceil(applyMargin(total, margin) / 5) * 5,
      type,
      quality,
    };
  });
  return allPrices;
};

export default getPricingForChain;
