import React, { useMemo } from 'react'
import firebase from 'firebase/app'
import { useAuthedCollection } from '@humancollective/human-hooks-firebase'
import find from 'lodash/find'
import compact from 'lodash/compact'
import {
  TiaryMaterial,
  MaterialType,
  MetalMaterial,
  FindingMaterial,
  ChainMaterial,
  StoneMaterial,
  MaterialGroup,
} from '@tiary-inc/tiary-shared'

/**
 * Materials Context
 * ---
 * This context subscribes to the materials and grouping
 */

interface MaterialsContextValue {
  materials: TiaryMaterial[]
  groupings: MaterialGroup[]

  metalMaterials: MetalMaterial[]
  stoneMaterials: StoneMaterial[]
  findingMaterials: FindingMaterial[]
  chainMaterials: ChainMaterial[]

  metalGroupings: MaterialGroup[]
  stoneGroupings: MaterialGroup[]
  findingGroupings: MaterialGroup[]
  chainGroupings: MaterialGroup[]

  getMaterialsForGroup: (id: string) => TiaryMaterial[]
}

const defaultValue: MaterialsContextValue = {
  materials: [],
  groupings: [],
  metalMaterials: [],
  stoneMaterials: [],
  findingMaterials: [],
  chainMaterials: [],
  metalGroupings: [],
  stoneGroupings: [],
  findingGroupings: [],
  chainGroupings: [],
  getMaterialsForGroup: (_id: string) => [],
}

export const MaterialsContext = React.createContext(defaultValue)

export const MaterialsContextProvider: React.FunctionComponent = ({
  children,
}) => {
  const materials: TiaryMaterial[] = useAuthedCollection({
    getQueryRef: () =>
      firebase
        .firestore()
        .collection('dashboard')
        .doc('data')
        .collection('materials'),
    defaultValue: [] as TiaryMaterial[],
    includeIds: true,
  })

  const metalMaterials = useMemo(
    () =>
      materials.filter(
        ({ material }) => material === MaterialType.Metal,
      ) as MetalMaterial[],
    [materials],
  )

  const stoneMaterials = useMemo(
    () =>
      materials.filter(
        ({ material }) => material === MaterialType.Stone,
      ) as StoneMaterial[],
    [materials],
  )

  const findingMaterials = useMemo(
    () =>
      materials.filter(
        ({ material }) => material === MaterialType.Finding,
      ) as FindingMaterial[],
    [materials],
  )

  const chainMaterials = useMemo(
    () =>
      materials.filter(
        ({ material }) => material === MaterialType.Chain,
      ) as ChainMaterial[],
    [materials],
  )

  const groupings: MaterialGroup[] = useAuthedCollection({
    getQueryRef: () =>
      firebase
        .firestore()
        .collection('dashboard')
        .doc('data')
        .collection('groupings'),
    defaultValue: [] as TiaryMaterial[],
    includeIds: true,
  })

  const metalGroupings = useMemo(
    () => groupings.filter(({ type }) => type === MaterialType.Metal),
    [groupings],
  )

  const stoneGroupings = useMemo(
    () => groupings.filter(({ type }) => type === MaterialType.Stone),
    [groupings],
  )

  const findingGroupings = useMemo(
    () => groupings.filter(({ type }) => type === MaterialType.Finding),
    [groupings],
  )

  const chainGroupings = useMemo(
    () => groupings.filter(({ type }) => type === MaterialType.Chain),
    [groupings],
  )

  const getMaterialsForGroup = useMemo(
    () => (groupId: string) => {
      const materialIds = find(groupings, { id: groupId })?.materials || []
      return compact(materialIds.map((id) => find(materials, { id })))
    },
    [materials, groupings],
  )

  return (
    <MaterialsContext.Provider
      value={{
        materials,
        groupings,
        metalMaterials,
        stoneMaterials,
        findingMaterials,
        chainMaterials,
        metalGroupings,
        stoneGroupings,
        findingGroupings,
        chainGroupings,
        getMaterialsForGroup,
      }}
    >
      {children}
    </MaterialsContext.Provider>
  )
}
