import React, { useState } from 'react'
import find from 'lodash/find'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import AddIcon from '@material-ui/icons/AddCircle'

interface ControlAddProps {
  types: any[]
  onAdd: (type: string) => void
}

export const ControlAdd: React.FunctionComponent<ControlAddProps> = ({
  types,
  onAdd,
}) => {
  const [typeValue, setTypeValue] = useState('none')

  return (
    <>
      <Select
        value={typeValue}
        onChange={(e) => setTypeValue(e.target.value as string)}
        fullWidth
      >
        <MenuItem value="none">Select a Type</MenuItem>
        {types.map((type) => (
          <MenuItem key={type.id} value={type.id}>
            {type.label}
          </MenuItem>
        ))}
      </Select>
      <Button
        onClick={() => {
          const defaults = find(types, { id: typeValue })?.defaults || {}
          onAdd({ type: typeValue, ...defaults })
          setTypeValue('none')
        }}
        color="primary"
        className="dynamic-form-list__button"
        startIcon={<AddIcon />}
        size="large"
        disabled={typeValue === 'none'}
      >
        Add
      </Button>
    </>
  )
}
