import React, { FunctionComponent } from 'react'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import styled from 'styled-components'

import { getFormField, EditorFormField } from '../getFormField'
import { Box } from '@material-ui/core'

const StyledEditorFormGroupField = styled.div`
  border: 1px solid #eaeaea;
  border-top: none;
  width: 100%;

  .expandable-form-group {
    &__fields-container {
      width: 100%;
    }
    &__label-container {
      width: 100%;
    }
    &__label-title {
      font-weight: 600;
    }
  }
`

interface EditorFormGroupFieldProps {
  label: string | React.ReactNode
  fields: EditorFormField[]
  description?: string
}

export const EditorFormGroupField: FunctionComponent<EditorFormGroupFieldProps> = ({
  label,
  description,
  fields,
}) => {
  const [expanded, setExpanded] = React.useState(false)

  return (
    <StyledEditorFormGroupField>
      <ExpansionPanel
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
        style={{ margin: 0 }}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div className="expandable-form-group__label-container">
            {typeof label === 'string' ? (
              <Typography
                className="expandable-form-group__label-title"
                variant="body1"
              >
                {label}
              </Typography>
            ) : (
              label
            )}
            <Typography variant="body2" color="textSecondary">
              {description}
            </Typography>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Box className="expandable-form-group__fields-container" padding={1}>
            {fields.map(getFormField)}
          </Box>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </StyledEditorFormGroupField>
  )
}
