import React from 'react'
import styled from 'styled-components'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'

const StyledControlMore = styled.div``

export interface ControlMoreOption {
  label: string
  action: () => void
}

interface ControlMoreProps {
  options: ControlMoreOption[]
}

export const ControlMore: React.FunctionComponent<ControlMoreProps> = ({
  options,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <StyledControlMore>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        color="primary"
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        {options.map(({ label, action }) => (
          <MenuItem key={label} selected={false} onClick={action}>
            {label}
          </MenuItem>
        ))}
      </Menu>
    </StyledControlMore>
  )
}
