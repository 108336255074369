import React from 'react';
import { Order, describeShippingOption } from '@tiary-inc/tiary-shared';
import { Card, CardContent, Box } from '@material-ui/core';

interface OrderAddressPartProps {
  order: Order;
}

export const OrderAddressPart: React.FunctionComponent<OrderAddressPartProps> = ({
  order,
}) => (
  <Card>
    <CardContent>
      <Box paddingBottom={2}>
        {order.shippingOption && (
          <div>
            <strong>{describeShippingOption(order.shippingOption.code)}</strong>
          </div>
        )}
        {order.trackingCode && (
          <div>
            Tracking Code:{' '}
            <a
              href={`https://www.fedex.com/apps/fedextrack/?action=track&tracknumbers=${order.trackingCode}&locale=en_US&cntry_code=us`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {order.trackingCode}
            </a>
          </div>
        )}
      </Box>
      <strong>
        Email: <a href={`mailto:${order.email}`}>{order.email}</a>
      </strong>
      <br />
      <br />
      <strong>Shipping Address:</strong>
      <br />
      {order.shippingAddress.firstName} {order.shippingAddress.lastName}
      <br />
      {order.shippingAddress.streetAddress}
      <br />
      {order.shippingAddress.locality} {order.shippingAddress.region},{' '}
      {order.shippingAddress.country} {order.shippingAddress.postalCode}
      <br />
      <br />
      <strong>Billing Address:</strong>
      <br />
      {order.billingAddress.firstName} {order.billingAddress.lastName}
      <br />
      {order.billingAddress.streetAddress}
      <br />
      {order.billingAddress.locality} {order.billingAddress.region},{' '}
      {order.billingAddress.country} {order.billingAddress.postalCode}
    </CardContent>
  </Card>
);
