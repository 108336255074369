import React from 'react'
import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography';

const StyledLoading = styled.div<{ height: string; width: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  flex: 1;

  > h4 {
    margin-bottom: 30px;
  }
`

interface Loading {
  height?: string // Pass in 100vh, 100px, 100%, etc;
  width?: string
  label?: string;
}

const Loading: React.FunctionComponent<Loading> = ({
  height = '100vh',
  width = '100vw',
  label
}) => (
  <StyledLoading height={height} width={width}>
    {label && <Typography variant="h4">{label}</Typography>}
    <CircularProgress />
  </StyledLoading>
)

export default Loading
