import React, { useRef, useState } from 'react'
import Button from '@material-ui/core/Button'
import firebase from 'firebase/app'
import 'firebase/storage'
import { v4 as uuid } from 'uuid'

import { useAuth } from '@humancollective/human-hooks-firebase'

export interface ImageUploader {
  onUploadSuccess: (filePath: string) => void
}

const ImageUploader: React.FunctionComponent<ImageUploader> = ({
  onUploadSuccess,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [uploadProgress, setUploadProgress] = useState(0)

  const { firebaseUser } = useAuth()

  const onClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleError = (error: Error) => {
    console.log('Error', error)
    setUploadProgress(0)
  }

  const handleSuccess = (filePath: string) => {
    setUploadProgress(0)
    onUploadSuccess(filePath)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target
    const fileToUpload: undefined | File = files ? files[0] : undefined

    if (!firebaseUser) {
      throw new Error('attempted upload without a firebase user')
    }

    if (fileToUpload) {
      const fileHash = uuid()
      // Sets the file name to a random hash & uses the original image extension
      const filePath = `uploads/${firebaseUser.uid}/${fileHash}`
      const uploadTask = firebase
        .storage()
        .ref(`/imgix/${filePath}`)
        .put(fileToUpload)

      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        (progress) => {
          const uploadPercent =
            (progress.bytesTransferred / progress.totalBytes) * 100
          setUploadProgress(Number(uploadPercent.toFixed(0)))
        },
        handleError,
        () => handleSuccess(filePath),
      )
    }
  }

  return (
    <div>
      <input
        style={{ display: 'none' }}
        type="file"
        ref={fileInputRef}
        onChange={handleChange}
      />
      <Button onClick={onClick}>
        {uploadProgress > 0
          ? `Uploading ${uploadProgress}%...`
          : 'Upload Image'}
      </Button>
    </div>
  )
}

export default ImageUploader
