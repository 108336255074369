import React, { FunctionComponent } from 'react'
import Typography from '@material-ui/core/Typography'
import MuiBreadcrumbs from '@material-ui/core/Breadcrumbs'
import { Link } from 'react-router-dom'

export interface Breadcrumb {
  label: string
  to?: string
}

interface BreadcrumbsProps {
  breadcrumbs?: Breadcrumb[]
}

export const Breadcrumbs: FunctionComponent<BreadcrumbsProps> = ({
  breadcrumbs,
}) => (
  <MuiBreadcrumbs aria-label="breadcrumb">
    {breadcrumbs &&
      breadcrumbs.map(({ label, to }) =>
        to ? (
          <Link key={label} style={{ color: 'inherit' }} to={to}>
            {label}
          </Link>
        ) : (
          <Typography key={label} color="textPrimary">
            {label}
          </Typography>
        ),
      )}
  </MuiBreadcrumbs>
)
