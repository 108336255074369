import React, { FunctionComponent } from 'react';
import Modal from '@material-ui/core/Modal';
import styled from 'styled-components';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import { Typography } from '@material-ui/core';
import { ProductCostResult } from '@tiary-inc/tiary-shared';

const StyledCostSummaryModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;

  .cost-summary-modal {
    &__wrapper {
      max-width: 70%;
      width: 600px;
      padding: 48px;
      background: white;
      max-height: 85vh;
      overflow: auto;
    }
  }
`;

interface CostSummaryModalProps {
  onClose: () => void;
  data: ProductCostResult;
}

export const CostSummaryModal: FunctionComponent<CostSummaryModalProps> = ({
  onClose,
  data,
}) => (
  <StyledCostSummaryModal open onClose={onClose}>
    <div className="cost-summary-modal__wrapper">
      <List>
        {data.items.map(
          ({ type, label, description, cost, unitCost, quantity }: any) =>
            type !== 'ERROR' ? (
              <ListItem key={description} style={{ background: 'white' }}>
                <ListItemText primary={label} secondary={description} />
                <ListItemText
                  style={{ textAlign: 'right' }}
                  primary={`$${(cost || 0).toFixed(2)}`}
                  secondary={
                    typeof quantity === 'number'
                      ? `($${(unitCost || 0).toFixed(2)} x ${quantity})`
                      : undefined
                  }
                />
              </ListItem>
            ) : (
              <ListItem key={description} style={{ background: '#ffe6e6' }}>
                <ListItemIcon>
                  <ErrorIcon />
                </ListItemIcon>
                <ListItemText primary="Error" secondary={description} />
              </ListItem>
            )
        )}
        {data.status === 'SUCCESS' && (
          <ListItem style={{ background: 'white', display: 'flex' }}>
            <Typography variant="h6" style={{ flex: 1 }}>
              TOTAL
            </Typography>
            <Typography variant="h6" style={{ textAlign: 'right' }}>
              ${(data.cost || 0).toFixed(2)}
            </Typography>
          </ListItem>
        )}
      </List>
    </div>
  </StyledCostSummaryModal>
);
