import React from 'react'
import { InputAdornment, FormControlProps } from '@material-ui/core'
import round from 'lodash/round'

import { FormikInput, FormikInputProps } from './index'

export const FormikPercentageInput = (props: FormikInputProps & FormControlProps) => {

  return(
    <FormikInput
      transformInput={(value) =>
        value && typeof value === 'number' ? round(value / 100, 6) : undefined
      }
      transformOutput={(value) =>
        value && typeof value === 'number' ? round(value * 100, 6) : undefined
      }
      endAdornment={<InputAdornment position="end">%</InputAdornment>}
      type="number"
      {...props}
    />
)
}
