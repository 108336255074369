import React, { useContext, useState, useMemo } from 'react';
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';
import Button from '@material-ui/core/Button';

import Loading from '../../../../lib/components/Loading';
import { LocaleContext } from '../../../../contexts/Locale';
import { MaterialsContext } from '../../../../contexts/Materials';
import { ManufacturerRhodiumModal } from './Modal';
import { StyledManufacturerRhodium } from './style';

interface ManufacturerRhodiumProps {
  name: string;
}

export const ManufacturerRhodium: React.FunctionComponent<ManufacturerRhodiumProps> = ({
  name,
}) => {
  const { localize } = useContext(LocaleContext);
  const { metalMaterials } = useContext(MaterialsContext);
  const [modalName, setModalName] = useState<string>();

  const qualities = useMemo(() => {
    if (!metalMaterials) {
      return undefined;
    }
    const allQualities = metalMaterials
      .filter(({ metal, color }) => metal === 'GOLD' && color === 'WHITE')
      .map(({ quality, metal }) => ({
        label: [quality, metal].map(localize).join(' '),
        // Was previously path: [name, metal, quality].join('.'),
        // to support more than gold in the future
        path: [name, quality].join('.'),
      }));
    return sortBy(uniqBy(allQualities, 'path'), 'path');
  }, [metalMaterials, localize, name]);

  const onSelectQuality = (q: string) => () => setModalName(q);
  const onCloseModal = () => setModalName(undefined);

  return qualities ? (
    <>
      {modalName && (
        <ManufacturerRhodiumModal name={modalName} onClose={onCloseModal} />
      )}
      <StyledManufacturerRhodium>
        {qualities.map(({ label, path }) => (
          <div
            className="manufacturer-finishings__finishing"
            key={`${label}-${path}`}
          >
            <div className="manufacturer-finishings__finishing__label">
              <strong>{label}</strong>
            </div>
            <Button
              color="primary"
              size="small"
              onClick={onSelectQuality(path)}
            >
              Edit
            </Button>
          </div>
        ))}
      </StyledManufacturerRhodium>
    </>
  ) : (
    <Loading />
  );
};
