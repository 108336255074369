import React from 'react';
import { Order } from '@tiary-inc/tiary-shared';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import startCase from 'lodash/startCase';
import styled from 'styled-components';
import { Card, CardContent, Box, TableBody } from '@material-ui/core';

interface OrderPaymentPartProps {
  order: Order;
}

const LINE_ITEM_ORDER = [
  'subtotal',
  'discount',
  'shipping',
  'tax',
  'credit',
  'total',
  'donation',
];

const StyledOrderPaymentPart = styled(Table)`
  .order-payment-part {
    &__label {
      font-weight: 600;
      padding-left: 0;
    }
    &__value {
      padding-right: 0;
      text-align: right;
    }
  }
`;

export const OrderPaymentPart: React.FunctionComponent<OrderPaymentPartProps> = ({
  order,
}) => (
  <Card>
    <CardContent>
      {order.payment.id && (
        <Box paddingBottom={2}>
          <a
            href={`${process.env.REACT_APP_STRIPE_URL_BASE}/payments/${order.payment.id}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            View in Stripe
          </a>
        </Box>
      )}
      <StyledOrderPaymentPart size="small">
        <TableBody>
          {LINE_ITEM_ORDER.map((itemName) => (
            <TableRow key={itemName}>
              <TableCell className="order-payment-part__label">
                {startCase(itemName)}
              </TableCell>
              <TableCell className="order-payment-part__value">
                $
                {(order.pricing[itemName] &&
                  order.pricing[itemName].toFixed(2)) ||
                  '0.00'}{' '}
                USD
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </StyledOrderPaymentPart>
    </CardContent>
  </Card>
);
