import React, { useEffect } from 'react'
import { useAuth } from '@humancollective/human-hooks-firebase'

import Loading from '../Loading'
import { routes } from '../../../configuration/routes'
import { useHistory } from 'react-router-dom'

interface AuthenticatedContentProps {
  redirectTo?: string
}

const AuthenticatedContent: React.FunctionComponent<AuthenticatedContentProps> = ({
  children,
  redirectTo = routes.login.path,
}) => {
  const history = useHistory()
  const { isLoggedIn, isInitialized } = useAuth()

  useEffect(() => {
    if (isInitialized && !isLoggedIn) {
      history.push(redirectTo)
    }
  }, [isInitialized, isLoggedIn, history, redirectTo])

  if (isLoggedIn === true) {
    return <>{children}</>
  }

  return <Loading />
}

export default AuthenticatedContent
