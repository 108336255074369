import React, { useState } from 'react';
import CardHeader from '@material-ui/core/CardHeader';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import firebase from 'firebase';
import { Order, describeOrderStatus } from '@tiary-inc/tiary-shared';
import { useAuthedDocument } from '@humancollective/human-hooks-firebase';
import { useParams } from 'react-router-dom';
import { Typography } from '@material-ui/core';

import Loading from '../../lib/components/Loading';
import { OrderAddressPart } from '../../components/Order/parts/OrderAddressPart';
import { OrderPaymentPart } from '../../components/Order/parts/OrderPaymentPart';
import { OrderProductsPart } from '../../components/Order/parts/OrderProductsPart';
import { CancelOrderModal } from '../../components/Order/modals/cancelOrder';
import { OrderOptionsMenu } from '../../components/Order/parts/OrderOptionsMenu';
import isDevelopment from '../../utils/isDevelopment';

/**
 * View Order Page
 * ---
 *
 * This page displays an order for viewing.
 *
 * Orders are generated during checkout by the tiary-functions project when a
 * consumer purchases a set of products.
 */

export const SingleOrderPage: React.FunctionComponent = () => {
  const { order: orderId } = useParams();
  const [showModal, setShowModal] = useState(false);

  const order: Order = useAuthedDocument<Order>({
    getQueryRef: () => firebase.firestore().collection('orders').doc(orderId),
    includeId: true,
  });

  const renderUsedPromoCodes = (order: Order): React.ReactNode => {
    if (!order.validPriceRules || order.validPriceRules.length === 0) {
      return null;
    }

    const validPromoCodesUsed = order.validPriceRules.filter(
      (priceRule) => priceRule.isValid
    );

    return (
      <Box paddingY={2}>
        <Typography variant="h6">Promo Codes Used</Typography>
        {validPromoCodesUsed.map((validPriceRule) => (
          <Chip
            key={validPriceRule.id}
            variant="outlined"
            size="small"
            label={validPriceRule.code}
          />
        ))}
      </Box>
    );
  };

  const openFirebaseOrder = (): void => {
    const firebaseProject: string = isDevelopment
      ? 'tiary-development'
      : 'tiary-platform';
    const firebaseUrl = `https://console.firebase.google.com/project/${firebaseProject}/firestore/data~2Forders~2F${order.id}`;

    window.open(firebaseUrl, '_blank');
  };

  return order ? (
    <>
      {showModal && (
        <CancelOrderModal order={order} onClose={() => setShowModal(false)} />
      )}
      <Box padding={3}>
        <CardHeader
          style={{ padding: 0 }}
          title={order.id}
          subheader={`${describeOrderStatus(order.status)} - 
            ${order.dateCreated && order.dateCreated.toDate().toDateString()}`}
          action={
            <OrderOptionsMenu
              onCancelOrderClicked={() => {
                setShowModal(true);
              }}
              onOpenFirebaseClicked={openFirebaseOrder}
            />
          }
        />
        <Box paddingY={2}>
          <Typography variant="h6">Shipping</Typography>
          <OrderAddressPart order={order} />
        </Box>

        <Box paddingY={2}>
          <Typography variant="h6">Products</Typography>
          <OrderProductsPart order={order} />
        </Box>

        <Box paddingY={2}>
          <Typography variant="h6">Payment</Typography>
          <OrderPaymentPart order={order} />
        </Box>

        {renderUsedPromoCodes(order)}
      </Box>
    </>
  ) : (
    <Loading />
  );
};
