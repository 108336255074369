import React from 'react'
import firebase from 'firebase'
import { Container, Grid } from '@material-ui/core'

import { CsvCollectionManager } from '../../lib/components/CsvCollectionManager'

/**
 * Reports Page
 * ---
 *
 * This page is for managing database contents.
 * For example, downloading today's orders, ...
 *
 * These components are used to upload orders data to Goldman FTP
 */

const csvProperties = [
  'Customer No',
  'Order Date',
  'Request Date',
  'PO No',
  'Order Type',
  'Consumer Order No',
  'ShipTo No.',
  'ShipTo Name1',
  'ShipTo Name2',
  'ShipTo Address1',
  'ShipTo Address2',
  'ShipTo City',
  'ShipTo State',
  'ShipTo Zip',
  'ShipToCountry',
  'ShipTo Phone',
  'BillTo Name',
  'BillTo Address',
  'BillTo City',
  'BillTo State',
  'BillTo Zip',
  'Ship Method',
  'Line Level',
  'LineNumber',
  'SKU / UPC',
  'FGI Item No.',
  'Size',
  'Description',
  'Quantity',
  'Unit Price',
  'Gift Flag',
  'Msg1',
]

export const ReportsPage: React.FunctionComponent = () => {
  const dataRef = firebase.firestore().collection('orders')
  const today = new Date()
  const startOfDay = new Date(today.getFullYear(), today.getMonth(), today.getDate())
  const endOfDay = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1)

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={4}>
          <CsvCollectionManager
            title="Today's Orders"
            collectionRef={dataRef}
            queryRef={dataRef
              .where('dateCreated', ">=", startOfDay)
              .where('dateCreated', "<=", endOfDay)
            }
            properties={[
              'uid',
              'dateCreated',
              'dateUpdated',
              'shippingAddress',
              'billingAddress',
              'payment',
              'products'
            ]}
            transformProperties={({
              uid,
              dateCreated,
              dateUpdated,
              shippingAddress,
              billingAddress,
              shippingOption,
              products,
              ...rest
            }: any) => {
              const orderDateTime = new Date(dateCreated.seconds * 1000)
              const requestDateTime = new Date(dateUpdated.seconds * 1000)
              const orderDate = `${orderDateTime.getFullYear()}-${(orderDateTime.getMonth() + 1).toString().padStart(2, '0')}-${orderDateTime.getDate().toString().padStart(2, '0')}`
              const requestDate = `${requestDateTime.getFullYear()}-${(requestDateTime.getMonth() + 1).toString().padStart(2, '0')}-${requestDateTime.getDate().toString().padStart(2, '0')}`

              if (products.length > 1) {
                return products.map(product => ({
                  'Customer No': uid,
                  'Order Date': orderDate,
                  'Request Date': requestDate,
                  'PO No': '',
                  'Order Type': 'DS',
                  'Consumer Order No': '',
                  'ShipTo No.': '',
                  'ShipTo Name1': shippingAddress.firstName,
                  'ShipTo Name2': shippingAddress.lastName,
                  'ShipTo Address1': shippingAddress.streetAddress,
                  'ShipTo Address2': '',
                  'ShipTo City': shippingAddress.locality,
                  'ShipTo State': shippingAddress.region,
                  'ShipTo Zip': shippingAddress.postalCode,
                  'ShipToCountry': shippingAddress.country,
                  'ShipTo Phone': shippingAddress.phone,
                  'BillTo Name': `${billingAddress.firstName} ${billingAddress.lastName}`,
                  'BillTo Address': billingAddress.streetAddress,
                  'BillTo City': billingAddress.locality,
                  'BillTo State': billingAddress.region,
                  'BillTo Zip': billingAddress.postalCode,
                  'Ship Method': shippingOption.code,
                  'Line Level': '',
                  'LineNumber': '',
                  'SKU / UPC': product.sku,
                  'FGI Item No.': '',
                  'Size': '',
                  'Description': '',
                  'Quantity': '',
                  'Unit Price': '',
                  'Gift Flag': '',
                  'Msg1': '',
                  ...rest,
                }))
              }

              return ({
                'Customer No': uid,
                'Order Date': orderDate,
                'Request Date': requestDate,
                'PO No': '',
                'Order Type': 'DS',
                'Consumer Order No': '',
                'ShipTo No.': '',
                'ShipTo Name1': shippingAddress.firstName,
                'ShipTo Name2': shippingAddress.lastName,
                'ShipTo Address1': shippingAddress.streetAddress,
                'ShipTo Address2': '',
                'ShipTo City': shippingAddress.locality,
                'ShipTo State': shippingAddress.region,
                'ShipTo Zip': shippingAddress.postalCode,
                'ShipToCountry': shippingAddress.country,
                'ShipTo Phone': shippingAddress.phone,
                'BillTo Name': `${billingAddress.firstName} ${billingAddress.lastName}`,
                'BillTo Address': billingAddress.streetAddress,
                'BillTo City': billingAddress.locality,
                'BillTo State': billingAddress.region,
                'BillTo Zip': billingAddress.postalCode,
                'Ship Method': shippingOption.code,
                'Line Level': '',
                'LineNumber': '',
                'SKU / UPC': products[0].sku,
                'FGI Item No.': '',
                'Size': '',
                'Description': '',
                'Quantity': '',
                'Unit Price': '',
                'Gift Flag': '',
                'Msg1': '',
                ...rest,
              })
            }}
            csvProperties={csvProperties}
            uploadFtp={true}
          />
        </Grid>
      </Grid>
    </Container>
  )
}
