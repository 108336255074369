import React from 'react';
import { Box, Button } from '@material-ui/core';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import { getFilenameForRender } from '../../../../utils/getRendersForProduct';
import { buildImgixUrl } from '../../../../lib/utils/imgix';

// How to create a ZIP file & download remote images
// https://dev.to/sparshed/downloading-remote-images-as-a-zip-file-using-jszip-2kp4

export interface RenderDownloader {
  sku: string;
  renders: { [key: string]: string | object };
}

const RenderDownloader: React.FunctionComponent<RenderDownloader> = ({
  sku,
  renders,
}) => {
  const [isPreparing, setIsPreparing] = React.useState(false);

  const onClick = async () => {
    if (renders) {
      setIsPreparing(true);

      // Where we'll store the renders to download
      const allRenders: {
        filenameOutput: string;
        remoteRenderPath: string;
      }[] = [];
      // Go through each key in the renders field
      Object.keys(renders).forEach((renderOption) => {
        // Get the value for the key
        const renderOptionContent = renders[renderOption];

        // If the value for the key is a string, it's just the URL.
        // An example would be the SHADOW key
        if (typeof renderOptionContent === 'string') {
          const renderFilename = getFilenameForRender({
            sku,
            option: renderOption,
          });
          const existingRenderPath = buildImgixUrl(
            `renders/${renderOptionContent}`
          );
          allRenders.push({
            filenameOutput: renderFilename,
            remoteRenderPath: existingRenderPath,
          });
        } else if (typeof renderOptionContent === 'object') {
          // If the value for the key is an object (e.x METAL_1)
          // Go through each key for the option
          Object.keys(renderOptionContent).forEach((renderOptionType) => {
            const renderFilename = getFilenameForRender({
              sku,
              option: renderOption,
              type: renderOptionType,
            });
            const existingRenderPath = buildImgixUrl(
              `renders/${renderOptionContent[renderOptionType]}`
            );
            allRenders.push({
              filenameOutput: renderFilename,
              remoteRenderPath: existingRenderPath,
            });
          });
        }
      });

      if (allRenders.length > 0) {
        const zipFile = new JSZip();

        // Go through each final render to download
        // Fetch the image blob from the remote location, then add it to the zip file
        for (const render of allRenders) {
          const imageBlob = await fetch(
            render.remoteRenderPath
          ).then((response) => response.blob());

          const imageFile = new File([imageBlob], render.filenameOutput);

          zipFile.file(render.filenameOutput, imageFile);
        }

        zipFile
          .generateAsync({ type: 'blob' })
          .then((content) => {
            saveAs(content, `${sku}-renders.zip`);
            setIsPreparing(false);
          })
          .catch((error) => setIsPreparing(false));
      } else {
        setIsPreparing(false);
      }
    }
  };

  return (
    <Box marginLeft={1} marginRight={1}>
      <Button onClick={onClick} variant="contained" color="primary">
        {isPreparing ? `Preparing` : `Download`} Renders
      </Button>
    </Box>
  );
};

export default RenderDownloader;
