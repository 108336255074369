import React, { FunctionComponent } from 'react';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
import { Box, CircularProgress, Typography } from '@material-ui/core';

import { createCallableFunction } from '../../../utils/firebase/createCallableFunction';
import { UIContext } from '../../../contexts/UI';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalWrapper: {
    maxWidth: '70%',
    width: 350,
    padding: theme.spacing(3),
    background: 'white',
    borderRadius: 4,
  },
  renderContent: {
    position: 'relative',
    border: '1px solid #948296',
  },
  textField: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));

interface CreateInvoiceValues {
  stripeCustomerId: string;
  amount: number;
  description: string;
}

const defaultValues = {
  stripeCustomerId: '',
  amount: 0,
  description: '',
};

export const CreateInvoiceModal: FunctionComponent = () => {
  const [show, toggleShowing] = React.useState(false);
  const classes = useStyles();
  const UI = React.useContext(UIContext);

  const createInvoice = async (values: CreateInvoiceValues): Promise<void> => {
    try {
      // Call the API endpoint

      const callableCreateInvoice = createCallableFunction('createInvoice');
      await callableCreateInvoice(values);

      toggleShowing(false);
      UI.snackbar.show({ message: 'Invoice created!', type: 'success' });
    } catch (error) {
      toggleShowing(false);
      console.log('Error submitting invoice', error);
      UI.snackbar.show({
        message: `Error creating invoice: ${(error as any).message}`,
        type: 'error',
      });
    }
  };

  const onSubmit = async (values: CreateInvoiceValues): Promise<void> => {
    if (!values.stripeCustomerId) {
      alert('Can only accept an order id or stripe customer id. Not both.');
    }

    await createInvoice(values);
  };

  return (
    <>
      <Button onClick={() => toggleShowing(true)}>Create Invoice</Button>
      <Modal
        open={show}
        onClose={() => toggleShowing(false)}
        className={classes.root}
      >
        <div className={classes.modalWrapper}>
          <Typography variant="h5">Create An Invoice</Typography>
          <Typography variant="caption">
            Creates a Stripe invoice. Before you create an invoice, make sure
            the stripe customer has an address set.
          </Typography>
          <Formik
            initialValues={{ ...defaultValues }}
            onSubmit={onSubmit}
            enableReinitialize={true}
          >
            {({ values, handleSubmit, handleChange, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <TextField
                  className={classes.textField}
                  fullWidth={true}
                  type="text"
                  id="stripeCustomerId"
                  name="stripeCustomerId"
                  label="Stripe Customer ID"
                  value={values.stripeCustomerId}
                  onChange={handleChange}
                  required={true}
                />

                <TextField
                  className={classes.textField}
                  fullWidth={true}
                  type="number"
                  id="amount"
                  name="amount"
                  label="Amount (in USD)"
                  value={values.amount}
                  required={true}
                  onChange={handleChange}
                />

                <TextField
                  className={classes.textField}
                  fullWidth={true}
                  type="text"
                  id="description"
                  name="description"
                  label="Description (optional)"
                  placeholder="Invoice for Mr. Smiths bracelet repair"
                  value={values.description}
                  onChange={handleChange}
                  required={true}
                  multiline={true}
                />

                <Box display="flex" flexDirection="row" alignItems="center">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth={true}
                  >
                    Create
                  </Button>
                  <Button
                    type="button"
                    variant="outlined"
                    color="primary"
                    fullWidth={true}
                    onClick={() => toggleShowing(false)}
                  >
                    Cancel
                  </Button>
                  {isSubmitting && (
                    <CircularProgress
                      size={20}
                      color="primary"
                      style={{ marginLeft: 10 }}
                    />
                  )}
                </Box>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </>
  );
};
