import React, { useContext } from 'react';
import firebase from 'firebase';
import uniq from 'lodash/uniq';
import { useAuthedCollection } from '@humancollective/human-hooks-firebase';
import styled from 'styled-components';
import { FindingMaterial, MaterialType } from '@tiary-inc/tiary-shared';

import Loading from '../../../../lib/components/Loading';
import { LocaleContext } from '../../../../contexts/Locale';
import { EditorFormDynamicListField } from '../../../../lib/components/EditorForm/Fields/DynamicListField';
import { EditorFormFieldType } from '../../../../lib/components/EditorForm/Fields/getFormField';

const getFieldsForForm = (
  type: string
): { type: EditorFormFieldType; label: string; name: string }[] => {
  const defaultFields = [
    {
      type: EditorFormFieldType.Number,
      label: 'Quantity',
      name: 'quantity',
    },
  ];

  switch (type) {
    case 'ADDITIONAL_CHAIN':
      defaultFields.push({
        type: EditorFormFieldType.Number,
        label: 'Percent Of Original Chain Cost %',
        name: 'reducedRate',
      });
      break;
    case 'CUSTOM_PRICE':
      defaultFields.push({
        type: EditorFormFieldType.Number,
        label: 'The cost (non-retail)',
        name: 'customPrice',
      });
      break;
    default:
      break;
  }

  return defaultFields;
};

const StyledProductFindingsForm = styled.div`
  .product-metal-weights-form {
    &__label {
      font-size: 16px;
      margin-top: 44px;
      text-transform: uppercase;
      letter-spacing: 0.6px;
    }
    &__inputs {
      display: flex;
      &__input {
        margin-right: 12px;
      }
    }
  }
`;

export const ProductFindingsForm: React.FunctionComponent<any> = ({ name }) => {
  const { localize } = useContext(LocaleContext);

  const findings: FindingMaterial[] = useAuthedCollection({
    getQueryRef: () =>
      firebase
        .firestore()
        .collection('dashboard')
        .doc('data')
        .collection('materials')
        .where('material', '==', MaterialType.Finding),
    defaultValue: [] as FindingMaterial[],
  });

  const findingTypes = uniq(findings.map(({ finding }) => finding)).sort();

  return findingTypes ? (
    <StyledProductFindingsForm>
      <EditorFormDynamicListField
        label="Fixed Costs"
        name={name}
        oneOfEach
        getItemLabel={(typeSpec: any) => (
          <div className="dynamic-form-list__item-label">
            <strong>{typeSpec?.label}</strong>
          </div>
        )}
        types={findingTypes.map((finding) => ({
          id: finding,
          label: localize(finding),
          defaults: {
            quantity: 1,
            reducedRate: 0,
          },
          fields: getFieldsForForm(finding),
        }))}
      />
    </StyledProductFindingsForm>
  ) : (
    <Loading />
  );
};
