import React, { useContext } from 'react'

import sortBy from 'lodash/sortBy'
import compact from 'lodash/compact'
import isArray from 'lodash/isArray'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Box from '@material-ui/core/Box'

import { LocaleContext } from '../../../../contexts/Locale'
import { MaterialsContext } from '../../../../contexts/Materials'
import { SelectChip } from '../../../../lib/components/SelectChip'
import { StoneType } from './'
import {
  stoneTypesFromMaterials,
  hashStoneType,
  expandStoneHash,
} from '../../../../utils/stoneUtils'

// THE STONE TYPE
// --------------

// The type can be hashed into a simple string for easy comparrison.

interface ProductStoneTypesFormProps {
  value: string | StoneType[]
  onChange: (nextValue: string | StoneType[]) => void
}

export const ProductStoneTypesForm: React.FunctionComponent<ProductStoneTypesFormProps> = ({
  value,
  onChange,
}) => {
  const { localize } = useContext(LocaleContext)
  const { stoneMaterials, stoneGroupings } = useContext(MaterialsContext)

  // Now, we separate the stone "type" selection from the stone "size" selection.
  // Selecting a type simply means that the stone options which match this
  // quality, color, stone triad will be available.

  const describeStoneType = ({ quality, color, stone }: StoneType) =>
    compact([quality, color, stone]).map(localize).join(' ')

  const allStoneTypeOptions = sortBy(
    stoneTypesFromMaterials(stoneMaterials).map((stoneType) => ({
      value: hashStoneType(stoneType),
      label: describeStoneType(stoneType),
    })),
    'label',
  )

  return (
    <>
      <Select
        value={typeof value === 'string' ? value : 'CUSTOM'}
        onChange={(e) =>
          e.target.value === 'CUSTOM'
            ? onChange([])
            : onChange(e.target.value as string)
        }
        fullWidth
      >
        <MenuItem value={'CUSTOM'}>Custom</MenuItem>
        {stoneGroupings.map(({ id, name: groupName }) => (
          <MenuItem key={id} value={id}>
            {groupName}
          </MenuItem>
        ))}
      </Select>
      {isArray(value) && (
        <Box marginTop={1}>
          <SelectChip
            options={allStoneTypeOptions}
            value={value.map(hashStoneType)}
            onChangeValues={(hashes) => onChange(hashes.map(expandStoneHash))}
            multiple
            fullWidth
          />
        </Box>
      )}
    </>
  )
}
