import React, { useState} from 'react'
import firebase from 'firebase/app'
import { useHistory } from 'react-router-dom'
import AddIcon from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'
import { Box } from '@material-ui/core'
import { CollectionListTemplate } from '../../lib/templates/ListTemplate/CollectionList'
import Loading from '../../lib/components/Loading'

// *
import { SaleRowView } from '../../components/Sales/RowView'
import { Timestamp } from '@firebase/firestore-types'

type Query = firebase.firestore.Query;
type DocumentData = firebase.firestore.DocumentData;
type CollectionReference = firebase.firestore.CollectionReference;

/**
 * Sales Page
 * 
 * This page displays a list of sales that are available for editing.
 *
 * NOTE: Sales are called "ongoing-sales" in the database
 */

//*
interface SalesItem {
  id: string,
  percentage?: number;
  sku?: string;
  stack?: boolean;
}

// *
interface SalesRules {
  defaultPercent: number,
  endDate: Timestamp,
  startDate: Timestamp
  specificProducts: SalesItem[],
}

export const SalesPage: React.FunctionComponent = () => {


  const [myState, setMyState] = useState(false);
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const [openSort, setOpenSort] = useState(false);
  const [sortSelection, setSortSelection] = useState("Clear Filter")
  const onAddItem = async () => {
    try {
      setLoading(true)
      const ref = await firebase.firestore().collection('ongoing-sales').add({})
      history.push(`/sales/edit/${ref.id}`)
    } catch (error) {
      setLoading(false)
      throw error
    }
  }



  const sortOptions = [
    { 
      label: 'Recent', 
      sortOption: 'recent', 
      firebaseDoc: firebase.firestore().collection('ongoing-sales').orderBy("dateAdded", "desc") 
    },
    { 
      label: 'Oldest', 
      sortOption: 'oldest' ,
      firebaseDoc: firebase.firestore().collection('ongoing-sales').orderBy("dateAdded", "asc")

    },
    { 
      label: 'Clear Filter', 
      sortOption: 'clear' ,
      firebaseDoc: firebase.firestore().collection('ongoing-sales')
    },
  ]
  
  const handleSort = () => {
    setOpenSort(!openSort)
  }

  return !loading ? (
    <CollectionListTemplate
    

      key={sortSelection.toString()}

      title="Sales"
      renderItem={(sale: SalesItem) => (
        <SaleRowView sale={sale} key={sale.id}/>
      )}
      // getQueryRef={() => sortSelection }
      getQueryRef={() => sortSelection === "Clear Filter" 
        ? firebase.firestore().collection('ongoing-sales') 
        : sortSelection === "Oldest" 
          ? firebase.firestore().collection('ongoing-sales').orderBy("dateAdded", "asc") 
          : firebase.firestore().collection('ongoing-sales').orderBy("dateAdded", "desc") }
      controls={
        <>
          {
            <>
              <Button onClick={onAddItem} startIcon={<AddIcon />}>
                Add
              </Button>
              <Button onClick={()=> setMyState(!myState)}>change</Button>
              <Button onClick={handleSort} color={openSort ? "primary" : "default"}>
                {openSort ? "Close Sort" : "Sort"}
              </Button>
              {openSort && 
                <Box display="flex">
                  {sortOptions.map((option) => {
                    return(
                      <Button 
                        key={option.label}
                        onClick={()=>{setSortSelection(option.label)}}
                      >
                        {option.label}
                      </Button>
                    )
                  })}
                </Box>
              }
            </>
          }
        </>
      }
      searchKeys={['code']} //? not sure if we need this since theres no code for sales
      breadcrumbs={[{ label: 'Home', to: '/' }, { label: 'Sales' }]}
      includeIds
    />
  ) : (
    <Loading />
  )
}
