import { Localizer } from '../contexts/Locale';
import { ManufacturerCode } from '@tiary-inc/tiary-shared';

export const valuesAsOptions = (
  values: string[],
  localize: Localizer = (a: string) => a
) => values.map((value) => ({ value, label: localize(value) }));

export const getNameFromManufacturerCode = (
  manufacturerCode: ManufacturerCode
): string => {
  switch (manufacturerCode) {
    case ManufacturerCode.MasterCasting:
      return 'Master Casting and CAD';
    case ManufacturerCode.Inventory:
      return 'Inventory';
    default:
      return '';
  }
};
