import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Insight } from '@tiary-inc/tiary-shared';
import flattenDeep from 'lodash/flattenDeep';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import sortBy from 'lodash/sortBy';

import { getProductMetadata } from '../../utils/firebase/products';

interface PopularProductsCardProps {
  insights: Insight[];
}

const useStyles = makeStyles((theme) => ({
  rootCard: {
    minWidth: 375,
  },
  cardContent: {
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
  },
  headingBox: {
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: 0,
  },
  chartContent: {
    height: 310,
    margin: theme.spacing(1),
  },
}));

type PopularProductData = { sku: string; amount: number };

const PopularProductRow: React.FunctionComponent<PopularProductData> = ({
  sku,
  amount,
}) => {
  const [productName, setProductName] = React.useState<string>(sku);

  React.useEffect(() => {
    getProductMetadata(sku).then((meta) => {
      setProductName(meta.name);
    });
  }, [sku]);

  return (
    <ListItem>
      <ListItemText primary={productName} secondary={`Quantity: ${amount}`} />
      <ListItemAvatar>
        <Avatar
          alt="Product Image"
          src={`https://tiary.imgix.net/marketing-renders/${sku}.jpg?fit=crop`}
        />
      </ListItemAvatar>
    </ListItem>
  );
};

const PopularProductsCard: React.FunctionComponent<PopularProductsCardProps> = ({
  insights,
}) => {
  const [popularProducts, setPopularProducts] = React.useState<
    PopularProductData[]
  >([]);
  const styles = useStyles();

  React.useEffect(() => {
    // get all the popular skus
    const roughAllPopularSKUs = insights.map(
      (insight) => insight.data.products.popularSkus
    );

    // flatten it so it's one array
    const allPopularSKUs = flattenDeep(roughAllPopularSKUs);

    // tally up the amounts for each SKU
    const talliedPopularSKUs: PopularProductData[] = allPopularSKUs.reduce<
      PopularProductData[]
    >((prev, curr) => {
      // Check if the item exists already in the tally
      const existingData = prev.find((prev) => prev.sku === curr.sku);
      let previousValues = prev;

      // if it exists, update it
      if (existingData) {
        const popularSKUsWithoutDuplicate = previousValues.filter(
          (tempPopularProd) => tempPopularProd.sku !== existingData.sku
        );

        previousValues = [
          ...popularSKUsWithoutDuplicate,
          { sku: curr.sku, amount: existingData.amount + curr.amount },
        ];
      } else {
        // If it doesn't exist, add it
        previousValues.push({ sku: curr.sku, amount: curr.amount });
      }

      return previousValues;
    }, []);

    // sort it, then reverse it so we're in desc
    const sortedPopularProducts = sortBy(
      talliedPopularSKUs,
      'amount'
    ).reverse();
    setPopularProducts(sortedPopularProducts);
  }, [insights]);

  return (
    <Card variant="outlined" className={styles.rootCard}>
      <CardContent className={styles.cardContent}>
        <Paper className={styles.headingBox}>
          <Typography variant="h6">Popular Products</Typography>
        </Paper>
        <List>
          {popularProducts.slice(0, 7).map((popularProduct) => (
            <PopularProductRow {...popularProduct} key={popularProduct.sku} />
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default PopularProductsCard;
