import React from 'react';
import {
    EditorFormFieldType,
    EditorFormField,
} from '../../components/EditorForm/Fields/getFormField';
import { ProductPricingForm } from '../../../components/Product/forms/Pricing';
import { ProductRendersForm } from '../../../components/Product/forms/Renders';
import { ProductFindingsForm } from '../../../components/Product/forms/Findings';
import { ProductMetalWeightsForm } from '../../../components/Product/forms/MetalWeights';
import { ProductMetalValuesForm } from '../../../components/Product/forms/MetalValues';
import { ProductStoneValuesForm } from '../../../components/Product/forms/StoneValues';
import { ProductChainValuesForm } from '../../../components/Product/forms/Chain';
import { ProductGroupedValuesForm } from '../../../components/Product/forms/Grouped';

const nameAndLabelFields: EditorFormField[] = [
    {
      type: EditorFormFieldType.Text,
      label: 'Label',
      name: 'label',
    },
    {
      type: EditorFormFieldType.Text,
      label: 'Option ID',
      name: 'name',
    },
  ];

export const DefaultSchema = (product:string)=>{
    return(
        [
            {
              type: EditorFormFieldType.Heading,
              label: 'Retail Pricing',
            },
            {
              type: EditorFormFieldType.FieldComponent,
              FieldComponent: ProductPricingForm,
              name: 'pricing',
            },
           
            { type: EditorFormFieldType.Divider },
            {
              type: EditorFormFieldType.Heading,
              label: 'Renders',
              description:
                'Upload renders below for processing. Renders will be automatically recognized by the standard Tiary naming convention.',
            },
            {
              type: EditorFormFieldType.FieldComponent,
              FieldComponent: ProductRendersForm,
              name: 'renders',
              sku: product,
            },
        
            { type: EditorFormFieldType.Divider },
            {
              type: EditorFormFieldType.Heading,
              label: 'Customizer Options',
              description:
                'These are the dynamic options available to the user in the customizer.',
            },
            {
              type: EditorFormFieldType.DynamicList,
              label: 'Options',
              name: 'options',
              getItemLabel: (typeSpec: any, value: any) => (
                <>
                <div className="dynamic-form-list__item-label">
                  <strong>{typeSpec?.label} Option</strong>
                  <span className="dynamic-form-list__item-label__name">
                    &nbsp;&nbsp;&mdash;&nbsp;&nbsp;
                    {value?.label || '(missing label)'}
                  </span>
                </div>
                </>
              ),
              types: [
                {
                  id: 'METAL',
                  label: 'Metal',
                  defaults: {
                    label: 'Metal',
                    name: 'METAL_#',
                    weights: {},
                    values: [],
                  },
                  fields: [
                    ...nameAndLabelFields,
                    {
                      type: EditorFormFieldType.Hidden,
                      name: 'type',
                      value: 'METAL',
                    },
                    {
                      type: EditorFormFieldType.FieldComponent,
                      FieldComponent: ProductMetalWeightsForm,
                      name: 'weights',
                    },
                    {
                      type: EditorFormFieldType.FieldComponent,
                      FieldComponent: ProductMetalValuesForm,
                      name: 'values',
                    },
                  ],
                },
                {
                  id: 'STONE',
                  label: 'Stone',
                  defaults: {
                    label: 'Stone',
                    name: 'STONE_#',
                    values: {},
                  },
                  fields: [
                    ...nameAndLabelFields,
                    {
                      type: EditorFormFieldType.Select,
                      label: 'Stone Setting',
                      name: 'stoneSetting',
                      options: [
                        {
                          label: 'Bezel',
                          value: 'BEZEL',
                        },
                        {
                          label: 'Prong',
                          value: 'PRONG',
                        },
                        {
                          label: 'Opaque',
                          value: 'OPAQUE',
                        },
                      ],
                    },
                    {
                      type: EditorFormFieldType.FieldComponent,
                      FieldComponent: ProductStoneValuesForm,
                      name: 'values',
                    },
                  ],
                },
                {
                  id: 'CHAIN',
                  label: 'Chain',
                  defaults: {
                    label: 'Chain',
                    name: 'CHAIN',
                    values: [],
                  },
                  fields: [
                    ...nameAndLabelFields,
                    {
                      type: EditorFormFieldType.FieldComponent,
                      FieldComponent: ProductChainValuesForm,
                      name: 'values',
                    },
                  ],
                },
                {
                  id: 'TEXT',
                  label: 'Text',
                  defaults: {
                    label: 'Name',
                    name: 'TEXT_#',
                    minLength: 3,
                    maxLength: 9,
                    regex: '[a-zA-Z0-9]*',
                    required: true,
                  },
                  fields: [
                    ...nameAndLabelFields,
                    {
                      type: EditorFormFieldType.Number,
                      label: 'Maximum Length',
                      name: 'maxLength',
                    },
                    {
                      type: EditorFormFieldType.Number,
                      label: 'Minimum Length',
                      name: 'minLength',
                    },
                    {
                      type: EditorFormFieldType.Text,
                      label: 'Regex Pattern',
                      name: 'regex',
                    },
                    {
                      type: EditorFormFieldType.Switch,
                      label: 'Required',
                      name: 'required',
                    },
                  ],
                },
                {
                  id: 'ENGRAVING',
                  label: 'Engraving',
                  defaults: {
                    label: 'Engraving',
                    name: 'ENGRAVING',
                    minLength: 0,
                    maxLength: 9,
                    regex: '[a-zA-Z0-9]*',
                  },
                  fields: [
                    ...nameAndLabelFields,
                    {
                      type: EditorFormFieldType.Number,
                      label: 'Maximum Length',
                      name: 'maxLength',
                    },
                    {
                      type: EditorFormFieldType.Number,
                      label: 'Minimum Length',
                      name: 'minLength',
                    },
                  ],
                },
                {
                  id: 'RING_SIZE',
                  label: 'Ring Size',
                  defaults: {
                    label: 'Size',
                    name: 'RING_SIZE',
                    values: [],
                  },
                  fields: [
                    ...nameAndLabelFields,
                    {
                      type: EditorFormFieldType.SelectChip,
                      label: 'Select',
                      name: 'values',
                      options: [
                        2,
                        2.5,
                        3,
                        3.5,
                        4,
                        4.5,
                        5,
                        5.5,
                        6,
                        6.5,
                        7,
                        7.5,
                        8,
                        8.5,
                        9,
                        9.5,
                        10,
                      ].map((size) => ({ value: `${size}` })),
                    },
                  ],
                },
                {
                  id: 'BRACELET_SIZE',
                  label: 'Bracelet Size',
                  defaults: {
                    label: 'Size',
                    name: 'BRACELET_SIZE',
                    values: [],
                  },
                  fields: [
                    ...nameAndLabelFields,
                    {
                      type: EditorFormFieldType.SelectChip,
                      label: 'Select',
                      name: 'values',
                      options: [
                        { value: 'S', label: 'Small' },
                        { value: 'M', label: 'Medium' },
                        { value: 'L', label: 'Large' },
                      ],
                    },
                  ],
                },
                {
                  id: 'QUANTITY',
                  label: 'Quantity',
                  defaults: {
                    label: 'Quantity',
                    name: 'QUANTITY',
                    values: [],
                  },
                  fields: [
                    ...nameAndLabelFields,
                    {
                      type: EditorFormFieldType.SelectChip,
                      label: 'Select',
                      name: 'values',
                      options: [
                        { value: '2', label: 'Pair' },
                        { value: '1', label: 'Single' },
                      ],
                    },
                  ],
                },
                {
                  id: 'GROUPED',
                  label: 'Grouped',
                  defaults: {
                    label: 'Grouped',
                    name: 'GROUPED',
                    values: [],
                  },
                  fields: [
                    ...nameAndLabelFields,
                    {
                      type: EditorFormFieldType.FieldComponent,
                      FieldComponent: ProductGroupedValuesForm,
                      name: 'values',
                    },
                  ],
                },
              ],
            },
            { type: EditorFormFieldType.Divider },
            {
              type: EditorFormFieldType.Heading,
              label: 'Findings',
              description:
                "These values are used for calculating margins and transmitting raw costs to the manufacturer. Modifying a value will not effect a product's price on the website.",
            },
            {
              type: EditorFormFieldType.FieldComponent,
              FieldComponent: ProductFindingsForm,
              name: 'findings',
            },
            {
              filename: `product-${product}`,
              type: EditorFormFieldType.ImportExport,
            },
          ]
        
    )
}
  