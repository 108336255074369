import React, { useState } from 'react';
import firebase from 'firebase';
import { Deposit } from '@tiary-inc/tiary-shared';

import { CollectionListTemplate } from '../../lib/templates/ListTemplate/CollectionList';
import { DepositRowView } from '../../components/Deposits/RowView';
import Loading from '../../lib/components/Loading';

/**
 * Deposits Page
 * ---
 *
 * This page displays a list of deposits that have been purchased.
 * Deposits can be applied to orders for discounts at checkout.
 *
 * NOTE: Gift cards are called "deposits" in the database for legacy reasons
 */

export const DepositsPage: React.FunctionComponent = () => {
  const [loading] = useState(false);

  return !loading ? (
    <CollectionListTemplate
      title="Deposits (Gift Cards)"
      renderItem={(deposit: Deposit) => <DepositRowView deposit={deposit} />}
      getQueryRef={() => firebase.firestore().collection('deposits')}
      searchKeys={['code']}
      breadcrumbs={[{ label: 'Home', to: '/' }, { label: 'Deposits' }]}
      includeIds
    />
  ) : (
    <Loading />
  );
};
