import React, { FunctionComponent, ReactNode } from 'react'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import { Form } from 'formik'
import styled from 'styled-components'
import { UseAuthedDocumentOptions } from '@humancollective/human-hooks-firebase'

import AuthenticatedContent from '../../components/AuthenticatedContent'
import { Breadcrumb } from '../../components/Breadcrumbs'
import { TemplateMeta } from '../../components/Template/TemplateMeta'
import { FormikEditorForm } from '../../components/EditorForm/FormikEditorForm'
import { EditorFormFields } from '../../components/EditorForm/Fields'
import { EditorFormSaveButtonControl } from '../../components/EditorForm/Controls/SaveButtonControl'
import { EditorFormRemoveButtonControl } from '../../components/EditorForm/Controls/RemoveButtonControl'
import { EditorFormField } from '../../components/EditorForm/Fields/getFormField'
import theme from '../../../configuration/theme'

interface EditorTemplateProps extends UseAuthedDocumentOptions {
  formSchema: EditorFormField[]
  title?: string
  breadcrumbs?: Breadcrumb[]
  validationSchema?: any
  className?: string
  beforeForm?: ReactNode
  afterForm?: ReactNode
  defaultValues?: any
  onSubmitSuccess?: (values:any) => void
}

const StyledEditorTemplate = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .editor-template {
    &__fields {
      flex: 1;
    }
    &__form {
      display: flex;
      flex: 1;
      flex-shrink: 1;
      flex-direction: column;
      overflow: hidden;
    }
    &__contents-container {
      display: flex;
      flex: 1;
      overflow: auto;
      background-color: ${theme.palette.background.default};
    }
    &__contents {
      display: flex;
      flex-wrap: wrap;
    }
    &__controls {
      border-top: 1px solid ${theme.palette.background.paper};
      display: flex;
      justify-content: center;
    }
  }

  .editor-form {
    &__description {
      max-width: 540px;
    }
    &__input,
    &__image-uploader {
      margin-bottom: 24px;
    }
    &__submit,
    &__remove {
      margin-top: 24px;
    }
    &__submit {
      margin-right: 16px;
    }
    &__fields {
      flex-shrink: 1;
      flex: 1;
      overflow-x: hidden;
    }
  }
`

const EditorTemplate: FunctionComponent<EditorTemplateProps> = ({
  title = '',
  breadcrumbs,
  beforeForm,
  afterForm,
  getQueryRef,
  formSchema,
  defaultValues,
  ...rest
}) => (
  <AuthenticatedContent>
    <StyledEditorTemplate>
      <Container>
        <TemplateMeta title={title} breadcrumbs={breadcrumbs} />
      </Container>
      <FormikEditorForm
        getQueryRef={getQueryRef}
        defaultValues={defaultValues}
        {...rest}
      >
        <Form className="editor-template__form">
          <Box className="editor-template__contents-container" paddingY={2}>
            <Container className="editor-template__contents">
              {beforeForm && <div>{beforeForm}</div>}
              <div className="editor-template__fields">
                <EditorFormFields formSchema={formSchema} />
              </div>
              {afterForm && <div>{afterForm}</div>}
            </Container>
          </Box>
          <Box className="editor-template__controls" paddingY={2}>
            <EditorFormSaveButtonControl />
            <EditorFormRemoveButtonControl getQueryRef={getQueryRef} />
          </Box>
        </Form>
      </FormikEditorForm>
    </StyledEditorTemplate>
  </AuthenticatedContent>
)

export default EditorTemplate
