import React, { useRef, useState } from 'react'
import Button from '@material-ui/core/Button'
import firebase from 'firebase/app'
import 'firebase/storage'
import compact from 'lodash/compact'
import { v4 as uuid } from 'uuid'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'

import { useAuth } from '@humancollective/human-hooks-firebase'
import { getFilenameForRender } from '../../../../utils/getRendersForProduct'

interface RenderLookup {
  [optionPath: string]: string
}

export interface RenderUploader {
  expectedRenders: { sku: string; option: string; type?: string }[]
  onUploadSuccess: (renders: RenderLookup) => void
}

const RenderUploader: React.FunctionComponent<RenderUploader> = ({
  expectedRenders,
  onUploadSuccess,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [uploadProgress, setUploadProgress] = useState(0)

  const { firebaseUser } = useAuth()

  const onClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleSuccess = (renders: RenderLookup) => {
    setUploadProgress(0)
    onUploadSuccess(renders)
  }

  const uploadFile = (file: File) =>
    new Promise<string>((resolve, reject) => {
      const fileHash = uuid()
      // Sets the file name to a random hash & uses the original image extension
      const filePath = `${firebaseUser.uid}/${fileHash}`
      const uploadTask = firebase.storage().ref(`renders/${filePath}`).put(file)

      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        (progress) => {
          const uploadPercent =
            (progress.bytesTransferred / progress.totalBytes) * 100
          setUploadProgress(Number(uploadPercent.toFixed(0)))
        },
        reject,
        () => resolve(filePath),
      )
    })

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target

    if (!files) {
      return
    }

    if (!firebaseUser) {
      throw new Error('attempted upload without a firebase user')
    }

    const expectedFiles = expectedRenders.map(({ sku, option, type }) => ({
      sku,
      option,
      type,
      fileName: getFilenameForRender({ sku, option, type }),
    }))

    const uploads: { [optionPath: string]: string } = {}

    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      const match = expectedFiles.find(({ fileName }) => file.name === fileName)
      if (match) {
        const renderPath = compact([match.option, match.type]).join('.')
        const filePath = await uploadFile(file)
        uploads[renderPath] = filePath
      } else {
        console.error(`file ${file.name} not recognized`)
      }
    }

    handleSuccess(uploads)
  }

  return (
    <div>
      <input
        style={{ display: 'none' }}
        type="file"
        multiple
        ref={fileInputRef}
        onChange={handleChange}
      />
      <Button
        onClick={onClick}
        startIcon={<CloudUploadIcon />}
        variant="contained"
        color="primary"
      >
        {uploadProgress > 0
          ? `Uploading ${uploadProgress}%...`
          : 'Upload Renders'}
      </Button>
    </div>
  )
}

export default RenderUploader
