import React, { useState, useContext, useMemo } from 'react'
import Typography from '@material-ui/core/Typography'
import { Field, FieldProps } from 'formik'
import find from 'lodash/find'
import { ChainMaterial, DashboardUtilsBag } from '@tiary-inc/tiary-shared'

import Loading from '../../../../lib/components/Loading'
import { ManufacturerChainsModal } from './Modal'
import { LocaleContext } from '../../../../contexts/Locale'
import { MaterialsContext } from '../../../../contexts/Materials'
import { PricingContext } from '../../../../contexts/Pricing'
import { StyledManufacturerChains } from './style'
import { groupChains } from './utils'
import { getMissingChainValues } from '../../../../utils/validateManufacturer'
import { ManufacturerChainRow } from './Row'

interface ManufacturerChainsProps {
  name: string
}

export const ManufacturerChains: React.FunctionComponent<ManufacturerChainsProps> = ({
  name,
}) => {
  const { localize } = useContext(LocaleContext)
  const { chainMaterials, materials, groupings } = useContext(MaterialsContext)
  const { pricing } = useContext(PricingContext)
  const bag: DashboardUtilsBag = { materials, groupings, localize, pricing }

  const groupedChains = useMemo(() => groupChains(chainMaterials), [
    chainMaterials,
  ])

  const [modalValues, setModalValues] = useState<ChainMaterial[]>()

  return chainMaterials ? (
    <Field name={name}>
      {({ form }: FieldProps) => {
        const missingChains = groupChains(
          getMissingChainValues({ manufacturer: form.values }, bag),
        )
        return (
          <>
            {modalValues && (
              <ManufacturerChainsModal
                name={name}
                onClose={() => setModalValues(undefined)}
                values={modalValues}
              />
            )}
            <StyledManufacturerChains>
              {Object.keys(groupedChains).map((style) => (
                <div key={`chain-${style}`}>
                  <Typography variant="h6">
                    <strong>{localize(style)}</strong>
                  </Typography>
                  {groupedChains[style].map(({ size, materials }) => {
                    return (
                      <ManufacturerChainRow
                        key={`${style}.${size}`}
                        label={localize(size)}
                        onEdit={() => setModalValues(materials)}
                        missingCount={
                          find(missingChains[style], { size })?.materials
                            .length || 0
                        }
                      />
                    )
                  })}
                </div>
              ))}
            </StyledManufacturerChains>
          </>
        )
      }}
    </Field>
  ) : (
    <Loading />
  )
}
