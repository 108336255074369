import * as React from 'react';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Box from '@material-ui/core/Box';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core/styles';

interface InsightDatePickerProps {
  startDate: Date;
  endDate: Date;
  onStartDateChanged: (updatedDate: Date) => void;
  onEndDateChanged: (updatedDate: Date) => void;
}

const useStyles = makeStyles((theme) => ({
  rootContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
  },
  arrowIcon: {
    margin: '0 10px',
  },
}));

const InsightDatePicker: React.FunctionComponent<InsightDatePickerProps> = ({
  startDate,
  endDate,
  onEndDateChanged,
  onStartDateChanged,
}) => {
  const styles = useStyles();

  const onDateChanged = (date: Date | null, type: 'start' | 'end'): void => {
    if (date) {
      if (type === 'start') {
        onStartDateChanged(date);
      }

      if (type === 'end') {
        onEndDateChanged(date);
      }
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Box className={styles.rootContent}>
        <KeyboardDatePicker
          margin="normal"
          id="date-picker-dialog"
          label="Start Date"
          format="MM/dd/yyyy"
          value={startDate}
          onChange={(value) => onDateChanged(value, 'start')}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        <ArrowRightAltIcon className={styles.arrowIcon} />
        <KeyboardDatePicker
          margin="normal"
          id="date-picker-dialog"
          label="End Date"
          format="MM/dd/yyyy"
          value={endDate}
          onChange={(value) => onDateChanged(value, 'end')}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          disableFuture={true}
        />
      </Box>
    </MuiPickersUtilsProvider>
  );
};

export default InsightDatePicker;
