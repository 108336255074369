import React from 'react';
import firebase from 'firebase';

import EditorTemplate from '../../lib/templates/EditorTemplate';
import { useParams } from 'react-router-dom';
import { EditorFormFieldType } from '../../lib/components/EditorForm/Fields/getFormField';
import { UIContext } from '../../contexts/UI';
import { FirestoreCollections } from '@tiary-inc/tiary-shared';

/**
 * Edit Promotion Page
 * ---
 *
 * This page is for editing a single promotion. Promotions can be applied to
 * orders for discounts at checkout.
 *
 * NOTE: Promotions are called "price-rules" in the database for legacy reasons
 * (this is what PrestaShop called them).
 */

export const EditReviewPage: React.FunctionComponent = () => {
  const { review } = useParams();
  const ui = React.useContext(UIContext);

  return (
    <EditorTemplate
      breadcrumbs={[
        { label: 'Reviews', to: '/product-reviews' },
        { label: 'Edit Review' },
      ]}
      onSubmitSuccess={() => {
        ui.snackbar.show({ message: 'Review saved!', type: 'success' });
      }}
      getQueryRef={() =>
        firebase
          .firestore()
          .collection(FirestoreCollections.PRODUCT_REVIEWS)
          .doc(review)
      }
      formSchema={[
        {
          name: 'author.name',
          label: 'Author Name',
          type: EditorFormFieldType.Text,
        },
        {
          name: 'author.id',
          label: 'Author ID',
          type: EditorFormFieldType.Text,
        },
        {
          type: EditorFormFieldType.Divider,
        },
        {
          name: 'title',
          label: 'Title',
          type: EditorFormFieldType.Text,
        },
        {
          name: 'description',
          label: 'Description',
          type: EditorFormFieldType.Text,
        },
        {
          name: 'sku',
          label: 'SKU',
          type: EditorFormFieldType.Text,
        },
        {
          name: 'rating',
          label: 'Rating',
          type: EditorFormFieldType.Select,
          options: [
            {
              label: '1',
              value: '1',
            },
            {
              label: '2',
              value: '2',
            },
            {
              label: '3',
              value: '3',
            },
            {
              label: '4',
              value: '4',
            },
            {
              label: '5',
              value: '5',
            },
          ],
        },
        {
          type: EditorFormFieldType.Divider,
        },
        {
          name: 'dateCreated',
          label: 'Date Created',
          type: EditorFormFieldType.Date,
        },
      ]}
    />
  );
};
