import React from 'react';
import firebase from 'firebase';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import Modal from '@material-ui/core/Modal';
import { Typography, Box } from '@material-ui/core';
import { DashboardProduct } from '@tiary-inc/tiary-shared';
import { getConsumerProduct } from '../../utils/getConsumerProduct';
import { UIContext } from '../../contexts/UI';

export const StyledProductDeployModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;

  .bulk-update-modal {
    &__wrapper {
      max-width: 70%;
      width: 600px;
      padding: 48px;
      background: white;
    }
    &__log {
      margin-top: 44px;
      height: 300px;
      overflow: auto;
      background: #efefef;
      font-family: monospace;
      font-size: 14px;
      padding: 12px;
    }
  }
`;

interface ProductDeployModalProps {
  modifiedProduct:any;
  sku: string;
  onClose: () => void;
}

export const ProductDeployModal: React.FunctionComponent<ProductDeployModalProps> = ({
  modifiedProduct,
  sku,
  onClose,
}) => {
  const { snackbar } = React.useContext(UIContext);

  const onDeploy = async (): Promise<void> => {
    
    const productRef = firebase
    .firestore()
    .collection('dashboard')
    .doc('data')
    .collection('products')
    .doc(sku);
    const productDoc = await productRef.get();

    let consumerProduct = {};
    if(modifiedProduct){
      consumerProduct = getConsumerProduct(modifiedProduct);
    }else{
      const consumerProduct = getConsumerProduct({
        ...(productDoc.data() as DashboardProduct),
        id: productDoc.id,
      });
      if (!consumerProduct) {
        snackbar.show({ message: 'Error deploying product', type: 'error' });
        throw new Error('product could not be deployed');
      }
    }

    await firebase
    .firestore()
    .collection('products')
    .doc(sku)
    .set(consumerProduct);
    await productRef.update({
      lastDeployed: firebase.firestore.Timestamp.now(),
    });
    
    onClose();
    snackbar.show({ message: 'Product deployed!', type: 'success' });
  };

  return (
    <StyledProductDeployModal open onClose={onClose}>
      <div className="bulk-update-modal__wrapper">
        <Typography variant="h5">Draft Saved</Typography>
        <Typography>would you like to deploy these changes?</Typography>
        <Box marginTop={4}>
          <Button onClick={onDeploy} variant="contained" color="primary">
            Yes, Deploy
          </Button>
          <Button onClick={onClose}>No, Not Yet</Button>
        </Box>
      </div>
    </StyledProductDeployModal>
  );
};
