import React from 'react'
import { Field } from 'formik'
import { FormControlProps } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'

import { LocationSelector } from './index'

interface FormikLocationSelectorProps {
  name: string
  label?: string
  type?: string
  required?: boolean
}

export const FormikLocationSelector: React.FunctionComponent<
  FormikLocationSelectorProps & FormControlProps
> = ({ name, type = 'text', label, className, ...rest }) => (
  <div className={className}>
    <Field name={name}>
      {() => (
        <FormControl {...rest} style={{ width: '100%' }}>
          <LocationSelector
            label={label}
            onChange={(value) => {
              console.log('would set value', value)
              // form.setFieldValue(name, value)
            }}
          />
        </FormControl>
      )}
    </Field>
  </div>
)
