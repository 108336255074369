import React from 'react';
import { ProductMetadata } from '@tiary-inc/tiary-shared';
import Chip from '@material-ui/core/Chip';
import styled from 'styled-components';

interface ProductContentfulPreviewProps {
  metadata: ProductMetadata;
}

const StyledProductContentfulPreview = styled.div`
  .product-contentful-preview {
    &__tag {
      margin-right: 4px;
    }
  }
`;

const ProductContentfulPreview: React.FunctionComponent<ProductContentfulPreviewProps> = ({
  metadata,
}) => {
  return (
    <StyledProductContentfulPreview>
      <div>
        {([
          'category',
          'collection',
          'type',
        ] as (keyof ProductMetadata)[]).map((tag) =>
          metadata && metadata[tag] ? (
            <Chip
              key={`metadata-${String(tag)}`}
              className="product-contentful-preview__tag"
              variant="outlined"
              color="primary"
              label={metadata[tag]}
              size="small"
            />
          ) : null
        )}
      </div>
    </StyledProductContentfulPreview>
  );
};

export default ProductContentfulPreview;
