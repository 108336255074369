import React, { FunctionComponent, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import styled from 'styled-components';
import Pagination from '@material-ui/lab/Pagination';
import { parse } from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';

import AuthenticatedContent from '../../components/AuthenticatedContent';
import { Breadcrumb } from '../../components/Breadcrumbs';
import Loading from '../../components/Loading';
import { useSearch } from '../../hooks/useSearch';
import { TemplateSearchBar } from '../../components/Template/SearchBar';
import { usePagination } from '../../hooks/usePagination';
import { TemplateMeta } from '../../components/Template/TemplateMeta';
import theme from '../../../configuration/theme';

export interface ListTemplateSection {
  title: string;
  items: any[];
}

export interface SharedListTemplateProps {
  renderItem: (item: any) => React.ReactNode;
  onAddItem?: () => void;
  breadcrumbs?: Breadcrumb[];
  title: string;
  controls?: React.ReactNode;
  section?: {
    sort: (items: any) => ListTemplateSection[];
    renderSection: (section: ListTemplateSection) => React.ReactNode;
  }
}

export interface SearchableTemplate {
  searchKeys?: string[];
}

export interface TemplateData {
  data: any[] | null;
}

const StyledListTemplate = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .list-template {
    &__controls {
      display: flex;
    }
    &__search {
      flex: 1;
    }
    &__table-container {
      border-top: 1px solid ${theme.palette.background.paper};
      background-color: ${theme.palette.background.default};
      flex: 1;
    }
    &__search-container {
      display: flex;
      align-items: center;
    }
    &__search-icon {
      margin-right: 8px;
    }
    &__pagination-container {
      border-top: 1px solid ${theme.palette.background.paper};
      display: flex;
      justify-content: center;
    }
  }
`;

export const ListTemplate: FunctionComponent<
  SharedListTemplateProps & SearchableTemplate & TemplateData
> = ({ title, renderItem, data, breadcrumbs, controls, searchKeys, section }) => {
  const location = useLocation();
  const { pathname } = location;
  const history = useHistory();
  const [queryLength, setQueryLength] = useState(0);

  const query = parse(location.search);
  const page = Number(query.page) || 1;

  const search = useSearch({
    data,
    keys: searchKeys || [],
  });

  const pagination = usePagination({
    data: search.results,
    itemsPerPage: 20,
    initialPage: page,
  });

  useEffect(() => {
    if (page !== pagination.index) {
      pagination.setIndex(page);
    }
    // we get an eslint error for pagination missing from this hook,
    // but if we add it as a dep it breaks pagination
    // eslint-disable-next-line
  }, [page]);

  useEffect(() => {
    if (queryLength !== search.query.length && page !== 1) {
      setQueryLength(search.query.length);
      history.push(`${pathname}?page=1`);
    }
    // we get an eslint error for pagination missing from this hook,
    // but if we add it as a dep it breaks pagination
    // eslint-disable-next-line
  }, [search.query]);

  const renderRow = () => {
    if (search.query) {
      return pagination.pageData.map(renderItem);
    }

    if (!section) {
      return pagination.pageData.map(renderItem);
    }

    if (section) {
      const sectionData = section.sort(pagination.pageData);

      const renderedSections = sectionData.map((newSection) => {
        return section.renderSection(newSection);
      })
    
      return renderedSections;
    }
  }

  return (
    <AuthenticatedContent>
      <StyledListTemplate>
        <Box paddingX={3}>
          <TemplateMeta title={title} breadcrumbs={breadcrumbs} />
          <div className="list-template__controls">
            {search.isReady && (
              <TemplateSearchBar
                className="list-template__search"
                query={search.query}
                setQuery={search.setQuery}
              />
            )}
            {controls}
          </div>
        </Box>
        {data ? (
          <TableContainer className="list-template__table-container">
            <Table size="small">
              <TableBody>{renderRow()}</TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Loading />
        )}
        {pagination.displayPagination && (
          <Box className="list-template__pagination-container" padding={3}>
            <Pagination
              count={pagination.count}
              color="primary"
              onChange={(_e, val) => {
                history.push(`${pathname}?page=${val}`);
              }}
              page={pagination.index}
            />
          </Box>
        )}
      </StyledListTemplate>
    </AuthenticatedContent>
  );
};
