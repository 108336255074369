import React, { useState } from 'react';
import firebase from 'firebase';
import { FirestoreCollections, ProductReview } from '@tiary-inc/tiary-shared';

import { CollectionListTemplate } from '../../lib/templates/ListTemplate/CollectionList';
import { ReviewRowView } from '../../components/Reviews/RowView';
import Loading from '../../lib/components/Loading';

/**
 * Review Page
 * ---
 *
 * This page displays a list of reviews for products.
 */

export const ReviewsPage: React.FunctionComponent = () => {
  const [loading] = useState(false);

  return !loading ? (
    <CollectionListTemplate
      title="Product Reviews"
      renderItem={(review: ProductReview) => (
        <ReviewRowView review={review} key={review.id} />
      )}
      getQueryRef={() =>
        firebase
          .firestore()
          .collection(FirestoreCollections.PRODUCT_REVIEWS)
          .orderBy('dateCreated', 'desc')
      }
      searchKeys={['title', 'sku']}
      breadcrumbs={[{ label: 'Home', to: '/' }, { label: 'Product Reviews' }]}
      includeIds
    />
  ) : (
    <Loading />
  );
};
