import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { OrdersPage } from './orders';
import { ComponentsPage } from './components';
import { LocalesPage } from './locales';
import { EditLocalePage } from './locales/edit';
import { ManufacturersPage } from './manufacturers';
import { EditManufacturerPage } from './manufacturers/edit';
import { ProductsPage } from './products';
import { EditProductPage } from './products/edit';
import { ReportsPage } from './reports';
import { InventoryPage } from './inventory';
import { EditInventoryPage } from './inventory/edit';
import { PromotionsPage } from './promotions';
import { EditPromotionPage } from './promotions/edit';
import { SingleOrderPage } from './orders/order';
import { PricingPage } from './pricing';
import { InsightsPage } from './insights';
import { DepositsPage } from './deposits';
import { ReviewsPage } from './reviews';
import { EditReviewPage } from './reviews/edit';
import { UsersPage } from './users';
import { EditDashboardUserPage } from './users/edit';


import { SalesPage } from './sales';
import { EditSalesPage } from './sales/edit'


import LogoutTemplate from '../lib/templates/LogoutTemplate/logout';
import LoginTemplate from '../lib/templates/LoginTemplate';

export const ALL_TABS_LIST = [
  'ORDERS',
  'PRODUCTS',
  'REPORTS',
  'INVENTORY',
  'PROMOTIONS',
  'DEPOSITS',
  'REVIEWS',
  'PRICING',
  'COMPONENTS',
  'MANUFACTURERS',
  'INSIGHTS',
  'LOCALES',
  'USERS',
  'SALES',
];

export type DashboardTab =
  | 'ORDERS'
  | 'PRODUCTS'
  | 'REPORTS'
  | 'INVENTORY'
  | 'PROMOTIONS'
  | 'DEPOSITS'
  | 'REVIEWS'
  | 'PRICING'
  | 'COMPONENTS'
  | 'MANUFACTURERS'
  | 'INSIGHTS'
  | 'LOCALES'
  | 'USERS';

export const AppRoutes = () => (
  <Switch>
    {/* Orders */}
    <Route exact path={['/', '/orders/']}>
      <OrdersPage />
    </Route>
    <Route exact path="/orders/order/:order">
      <SingleOrderPage />
    </Route>
    {/* Components */}
    <Route path="/components/">
      <ComponentsPage />
    </Route>
    {/* Locales */}
    <Route exact path="/locales/">
      <LocalesPage />
    </Route>
    <Route exact path="/locales/edit/:locale">
      <EditLocalePage />
    </Route>
    {/* Manufacturers */}
    <Route exact path="/manufacturers/">
      <ManufacturersPage />
    </Route>
    <Route exact path="/manufacturers/edit/:manufacturer">
      <EditManufacturerPage />
    </Route>
    {/* Pricing */}
    <Route exact path="/pricing/">
      <PricingPage />
    </Route>
    {/* Products */}
    <Route exact path="/products/">
      <ProductsPage />
    </Route>
    <Route exact path="/products/edit/:product">
      <EditProductPage />
    </Route>
    {/* Reports */}
    <Route exact path="/reports/">
      <ReportsPage />
    </Route>
    {/* Inventory */}
    <Route exact path="/inventory/">
      <InventoryPage />
    </Route>
    <Route exact path="/inventory/edit/:product">
      <EditInventoryPage />
    </Route>
    {/* Promotions */}
    <Route exact path="/promotions/">
      <PromotionsPage />
    </Route>
    <Route exact path="/promotions/edit/:promotion">
      <EditPromotionPage />
    </Route>



    {/* Sales */}
    <Route exact path="/sales/">
      <SalesPage />
    </Route>
    <Route exact path="/sales/edit/:sale">
      <EditSalesPage/>
    </Route>


    {/* Reviews */}
    <Route exact path="/product-reviews/">
      <ReviewsPage />
    </Route>
    <Route exact path="/product-reviews/edit/:review">
      <EditReviewPage />
    </Route>
    {/* Users/Admins */}
    <Route exact path="/users">
      <UsersPage />
    </Route>
    <Route exact path="/users/edit/:user">
      <EditDashboardUserPage />
    </Route>
    {/* Insights */}
    <Route exact path="/insights/">
      <InsightsPage />
    </Route>
    {/* Deposits */}
    <Route exact path="/deposits/">
      <DepositsPage />
    </Route>
    <Route exact path="/deposits/edit/:deposits">
      <EditPromotionPage />
    </Route>
    {/* Login */}
    <Route path="/login/">
      <LoginTemplate />
    </Route>
    {/* Logout */}
    <Route path="/logout/">
      <LogoutTemplate />
    </Route>
  </Switch>
);
