import React, { useContext, useMemo } from 'react'
import InputAdornment from '@material-ui/core/InputAdornment'
import uniqBy from 'lodash/uniqBy'
import sortBy from 'lodash/sortBy'
import { MetalMaterial } from '@tiary-inc/tiary-shared'

import { FormikMultiInput } from '../../../../lib/components/FormikMultiInput'
import Loading from '../../../../lib/components/Loading'
import { LocaleContext } from '../../../../contexts/Locale'
import { MaterialsContext } from '../../../../contexts/Materials'

interface ManufacturerMetalsProps {
  name: string
}

export const ManufacturerMetals: React.FunctionComponent<ManufacturerMetalsProps> = ({
  name,
}) => {
  const { localize } = useContext(LocaleContext)
  const { metalMaterials } = useContext(MaterialsContext)

  // Since pricing is (currently) flat accross all qualities, we'll create
  // just one input per quality and apply it's value accross all matching
  // material IDs.

  const qualities = useMemo(() => {
    const allQualities = metalMaterials.map(({ quality, metal }) => ({
      quality,
      metal,
      label: `${localize(quality)} ${localize(metal)}`,
    }))
    return sortBy(uniqBy(allQualities, 'quality'), 'quality')
  }, [metalMaterials, localize])

  // Here we get all of the material IDs that will be impacted by a change
  // in the price of a metal quality.

  const materialsByQuality = useMemo(() => {
    if (!metalMaterials) {
      return undefined
    }
    const materials: { [quality: string]: MetalMaterial[] } = {}
    qualities.forEach(({ quality }) => {
      materials[quality] = metalMaterials.filter((m) => m.quality === quality)
    })
    return materials
  }, [metalMaterials, qualities])

  return materialsByQuality ? (
    <>
      {qualities.map(({ quality, label }) => {
        const materials = materialsByQuality[quality]
        return (
          <FormikMultiInput
            className="editor-form__input"
            key={quality}
            names={materials.map(({ id }) => [name, id].join('.'))}
            label={label}
            type="number"
            helperText={`The raw cost of ${label} per dwt.`}
            fullWidth
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            endAdornment={<InputAdornment position="end">USD</InputAdornment>}
          />
        )
      })}
    </>
  ) : (
    <Loading />
  )
}
