import React,{useMemo} from 'react'
import { useAuthedCollection, useAuthedDocument } from '@humancollective/human-hooks-firebase'
import firebase from 'firebase/app'
import { DashboardPricing } from '@tiary-inc/tiary-shared'

const defaultValue = {
  pricing: {} as DashboardPricing,
  goldmanReference:{}
}

export const PricingContext = React.createContext(defaultValue)
export const PricingContextProvider: React.FunctionComponent = ({
  children,
}) => {
  // Connect to the default locale
  const pricing = useAuthedDocument({
    getQueryRef: () =>
      firebase.firestore().collection('dashboard').doc('pricing'),
  })

  // goldmancosts here
  const manufacturers = useAuthedCollection({
    getQueryRef: () =>
      firebase.firestore().collection('dashboard').doc('data').collection('manufacturers'),
      defaultValue: [],
      includeIds:true
  })

  const goldmanReference = useMemo(()=>manufacturers.filter(({name})=>name==='Goldman'),
    [manufacturers]
  )

  return (
    <PricingContext.Provider value={{ pricing, goldmanReference}}>
      {children}
    </PricingContext.Provider>
  )
}
