import React from 'react';
import { Button, CardProps, Grid, TextField } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Chip from '@material-ui/core/Chip';
import ErrorIcon from '@material-ui/icons/Error';

import styled from 'styled-components';
import {
  ProductMetadata,
  ProductSpec,
  RendererLayoutMode,
} from '@tiary-inc/tiary-shared';
import { useHistory } from 'react-router-dom';
import { TiaryRenderer } from '@tiary-inc/tiary-components';

const StyledProductRowView = styled(TableRow)`
  cursor: pointer;

  .product-row-view {
    &__renderer {
      margin-right: 24px;
    }
  }
`;

interface ProductRowViewProps {
  sku: string;
  product?: ProductSpec;
  metadata?: ProductMetadata;
  onSelect: () => void;
  selected?: boolean;
  percentage?: number;
  error?: boolean;
  isSales?: boolean;
  onProductClick?: any;
  handleRemoveProduct?: (sku: string) => void;
  updateSaleDetails?: (sku: string, newSaleDetails: number) => void;
}

const INITIAL_CONFIG = {
  STONE_1: { quality: 'GENUINE', type: 'DIAMOND_WHITE' },
  STONE_2: { quality: 'GENUINE', type: 'DIAMOND_WHITE' },
  METAL_1: { quality: 'KARAT_14', type: 'GOLD_YELLOW' },
};

export const ProductRowView: React.FunctionComponent<
  ProductRowViewProps & CardProps
> = ({ 
    sku, 
    product, 
    metadata, 
    selected, 
    error, 
    percentage,
    isSales, 
    onProductClick, 
    handleRemoveProduct, 
    updateSaleDetails 
  }) => {
  
  const handleClick = () => {
    if (onProductClick) {
      onProductClick({sku, product, metadata});
    }
  }

  const handleClickEvent = () => {
    if (isSales) {
        handleClick();
    } else {
      // original navigation function
      history.push(`/products/edit/${sku}`);
    }
  }

  const history = useHistory();

  return (
    <StyledProductRowView
      hover
      onClick={ handleClickEvent }
      selected={selected}
    >
      <TableCell>
        <ListItem>
          {product && (
            <TiaryRenderer
              className="product-row-view__renderer"
              productSpec={product}
              configuration={INITIAL_CONFIG}
              layout={{
                mode: RendererLayoutMode.Fixed,
                height: 108,
                width: 108,
                focalPointY: 0.8,
              }}
            />
          )}
          <ListItemText style={{minWidth: '300px'}} primary={metadata?.name} secondary={sku} />
          {error && (
            <Chip label="error" icon={<ErrorIcon />} color="secondary" />
          )}

          {isSales && handleRemoveProduct && (
            <Grid container spacing={2} alignItems="center" direction="row">
              <Grid item>
                <TextField
                  style={{maxWidth: '130px'}}
                  label="Percentage"
                  variant="outlined"
                  size="small"
                  value={percentage}
                  type="number"
                  inputProps={{min: 0, step: 0.0001}}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => {
                    if (updateSaleDetails) {
                      const value = parseFloat(e.target.value);
                      if (!isNaN(value)) {
                        updateSaleDetails(sku, Math.abs(value))
                      }
                    }
                  }}
                />
              </Grid>
              <Grid item>
                <Button
                  style={{ width: '20px' }}
                  variant="contained"
                  color="secondary"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemoveProduct(sku)
                  }}
                >
                  Remove
                </Button>
              </Grid>
            </Grid>
          )}
        </ListItem>
      </TableCell>
    </StyledProductRowView>
  );
};
