import React, { useState} from 'react'
import firebase from 'firebase/app'
import { PriceRule } from '@tiary-inc/tiary-shared'
import { useHistory } from 'react-router-dom'
import AddIcon from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'
import { Box } from '@material-ui/core'
import { CollectionListTemplate } from '../../lib/templates/ListTemplate/CollectionList'
import { PromotionRowView } from '../../components/Promotion/RowView'
import Loading from '../../lib/components/Loading'

type Query = firebase.firestore.Query;
type DocumentData = firebase.firestore.DocumentData;
type CollectionReference = firebase.firestore.CollectionReference;

/**
 * Promotions Page
 * ---
 *
 * This page displays a list of promotions that are available for editing.
 * Promotions can be applied to orders for discounts at checkout.
 *
 * NOTE: Promotions are called "price-rules" in the database for legacy reasons
 * (this is what PrestaShop called them).
 */

export const PromotionsPage: React.FunctionComponent = () => {

  const [clicked, setClicked] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const [openSort, setOpenSort] = useState(false);
  const [sortSelection, setSortSelection] = useState("Clear Filter")

  const onAddItem = async () => {
    try {
      setLoading(true)
      const ref = await firebase.firestore().collection('price-rules').add({})
      history.push(`/promotions/edit/${ref.id}`)
    } catch (error) {
      setLoading(false)
      throw error
    }
  }


  const sortOptions = [
    { 
      label: 'Recent', 
      sortOption: 'recent', 
      firebaseDoc: firebase.firestore().collection('price-rules').orderBy("dateAdded", "desc") 
    },
    { 
      label: 'Oldest', 
      sortOption: 'oldest' ,
      firebaseDoc: firebase.firestore().collection('price-rules').orderBy("dateAdded", "asc")

    },
    { 
      label: 'Clear Filter', 
      sortOption: 'clear' ,
      firebaseDoc: firebase.firestore().collection('price-rules')
    },
  ]
  
  const handleSort = () => {
    setOpenSort(!openSort)
  }

  return !loading ? (
    <CollectionListTemplate
    
    //! brute force fix because i cant see this deprecated component
      key={sortSelection.toString()}

      title="Promotions"
      renderItem={(promotion: PriceRule) => (
        <PromotionRowView promotion={promotion} />
      )}
      // getQueryRef={() => sortSelection }
      getQueryRef={() => sortSelection === "Clear Filter" 
        ? firebase.firestore().collection('price-rules') 
        : sortSelection === "Oldest" 
          ? firebase.firestore().collection('price-rules').orderBy("dateAdded", "asc") 
          : firebase.firestore().collection('price-rules').orderBy("dateAdded", "desc") }
      controls={
        <>
          {
            <>
              <Button onClick={onAddItem} startIcon={<AddIcon />}>
                Add
              </Button>
              <Button onClick={handleSort} color={openSort ? "primary" : "default"}>
                {openSort ? "Close Sort" : "Sort"}
              </Button>
              {openSort && 
                <Box display="flex">
                  {sortOptions.map((option) => {
                    return(
                      <Button style={clicked === option.label ? {border: "1px solid grey"} : {}}
                        key={option.label}
                        onClick={() => { 
                          setSortSelection(option.label)
                          setClicked(option.label)
                        }
                        }
                      >
                        {option.label}
                      </Button>
                    )
                  })}
                </Box>
              }
            </>
          }
        </>
      }
      searchKeys={['code']}
      breadcrumbs={[{ label: 'Home', to: '/' }, { label: 'Promotions' }]}
      includeIds
    />
  ) : (
    <Loading />
  )
}
