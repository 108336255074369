import * as React from 'react';
import { Chip, Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InfoIcon from '@material-ui/icons/Info';

const NonJewellryModal: React.FunctionComponent = () => {
  const [isOpen, setOpen] = React.useState(false);

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <Chip
        icon={<InfoIcon />}
        label="How can I add a non-jewellry product?"
        onClick={() => setOpen(true)}
      />
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'How to add a non-jewellry product'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            In order for a product to be considered non-jewellry, it must have
            no customizer options and one price. Follow the steps below to get
            started.
          </DialogContentText>
          <ul>
            <li>
              <DialogContentText>
                Step 1: Ensure the product has no customizer options
              </DialogContentText>
            </li>
            <li>
              <DialogContentText>
                Step 2: In the findings section, add a finding named
                {` "custom
                price"`}
                . Then set the cost of the product.
              </DialogContentText>
            </li>
            <li>
              <DialogContentText>
                Step 3: The base price of a product defaults to $34. I suggest
                setting the base price to {`"0"`} since base pricing is only
                applies to jewellry products
              </DialogContentText>
            </li>
            <li>
              <DialogContentText>
                Step 4: Click {`"Generate Pricing"`} so the pricing will be
                updated
              </DialogContentText>
            </li>
            <li>
              <DialogContentText>
                Step 5: Upload the 2 renders for the product. You'll need the
                shadow & base render.
              </DialogContentText>
            </li>
            <li>
              <DialogContentText>Done!</DialogContentText>
            </li>
          </ul>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Got It</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NonJewellryModal;
