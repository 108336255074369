import ReceiptIcon from '@material-ui/icons/Receipt';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import BusinessIcon from '@material-ui/icons/Business';
import SettingsIcon from '@material-ui/icons/Settings';
import StarsIcon from '@material-ui/icons/Stars';
import LocaleIcon from '@material-ui/icons/Translate';
import PricingIcon from '@material-ui/icons/MonetizationOn';
import TimelineIcon from '@material-ui/icons/Timeline';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import RateReviewIcon from '@material-ui/icons/RateReview';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import StorageIcon from '@material-ui/icons/Storage';
import AssessmentIcon from '@material-ui/icons/Assessment';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import { NavbarRouteProps } from '../components/Navbar/Menu';

export const routes: {
  [name: string]: NavbarRouteProps;
} = {
  SALES: {
    title: 'Sales',
    path: '/sales/',
    Icon: LocalOfferIcon,
  },
  ORDERS: {
    title: 'Orders',
    path: '/orders/',
    Icon: ReceiptIcon,
  },
  PRODUCTS: {
    title: 'Products',
    path: '/products/',
    Icon: StarsIcon,
  },
  REPORTS: {
    title: 'Reports',
    path: '/reports/',
    Icon: AssessmentIcon,
  },
  INVENTORY: {
    title: 'Inventory',
    path: '/inventory/',
    Icon: StorageIcon,
  },
  PROMOTIONS: {
    title: 'Promotions',
    path: '/promotions/',
    Icon: LoyaltyIcon,
  },
  INSIGHTS: {
    title: 'Insights',
    path: '/insights/',
    Icon: TimelineIcon,
  },
  DEPOSITS: {
    title: 'Deposits (Gift Cards)',
    path: '/deposits/',
    Icon: CardGiftcardIcon,
  },
  REVIEWS: {
    title: 'Product Reviews',
    path: '/product-reviews/',
    Icon: RateReviewIcon,
  },
  MANUFACTURERS: {
    title: 'Manufacturers',
    path: '/manufacturers/',
    Icon: BusinessIcon,
  },
  COMPONENTS: {
    title: 'Components',
    path: '/components/',
    Icon: SettingsIcon,
  },
  PRICING: {
    title: 'Pricing',
    path: '/pricing/',
    Icon: PricingIcon,
  },
  LOCALES: {
    title: 'Locales',
    path: '/locales/',
    Icon: LocaleIcon,
  },
  USERS: {
    title: 'Users/Admins',
    path: '/users',
    Icon: ContactMailIcon,
  },
  login: {
    title: 'Login',
    path: '/login/',
  },
  logout: {
    title: 'Logout',
    path: '/logout/',
  },
};
