import React, { forwardRef } from 'react'
import MaterialTable from 'material-table'

import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'

const tableIcons = {
  Add: forwardRef((props, ref) => (
    <AddBox component={AddBox} {...props} ref={ref as any} />
  )),
  Check: forwardRef((props, ref) => (
    <Check component={Check} {...props} ref={ref as any} />
  )),
  Clear: forwardRef((props, ref) => (
    <Clear component={Clear} {...props} ref={ref as any} />
  )),
  Delete: forwardRef((props, ref) => (
    <DeleteOutline component={DeleteOutline} {...props} ref={ref as any} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight component={ChevronRight} {...props} ref={ref as any} />
  )),
  Edit: forwardRef((props, ref) => (
    <Edit component={Edit} {...props} ref={ref as any} />
  )),
  Export: forwardRef((props, ref) => (
    <SaveAlt component={SaveAlt} {...props} ref={ref as any} />
  )),
  Filter: forwardRef((props, ref) => (
    <FilterList component={FilterList} {...props} ref={ref as any} />
  )),
  FirstPage: forwardRef((props, ref) => (
    <FirstPage component={FirstPage} {...props} ref={ref as any} />
  )),
  LastPage: forwardRef((props, ref) => (
    <LastPage component={LastPage} {...props} ref={ref as any} />
  )),
  NextPage: forwardRef((props, ref) => (
    <ChevronRight component={ChevronRight} {...props} ref={ref as any} />
  )),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft component={ChevronLeft} {...props} ref={ref as any} />
  )),
  ResetSearch: forwardRef((props, ref) => (
    <Clear component={Clear} {...props} ref={ref as any} />
  )),
  Search: forwardRef((props, ref) => (
    <Search component={Search} {...props} ref={ref as any} />
  )),
  SortArrow: forwardRef((props, ref) => (
    <ArrowDownward component={ArrowDownward} {...props} ref={ref as any} />
  )),
  ThirdStateCheck: forwardRef((props, ref) => (
    <Remove component={Remove} {...props} ref={ref as any} />
  )),
  ViewColumn: forwardRef((props, ref) => (
    <ViewColumn component={ViewColumn} {...props} ref={ref as any} />
  )),
}

export const MaterialTableWithIcons = (props: any) => (
  <MaterialTable icons={tableIcons} {...props} />
)
