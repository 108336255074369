import { join } from 'path'
import includes from 'lodash/includes'

interface BuildImgixUrlArgs {
  width?: number
  height?: number
  fit?: ImgixFitMode
  auto?: string[]
  autoCompress?: boolean
  autoFormat?: boolean
  pixelRatio?: number
  backgroundColor?: string
  sourceRectangleRegion?: string
}

export enum ImgixFitMode {
  Clamp = 'clamp',
  Clip = 'clip',
  Crop = 'crop',
  FaceArea = 'facearea',
  Fill = 'fill',
  FillMax = 'fillmax',
  Max = 'max',
  Min = 'min',
  Scale = 'scale',
}

export const buildImgixUrl = (
  path: string,
  {
    width,
    height,
    fit,
    auto,
    autoCompress = true,
    autoFormat = true,
    pixelRatio,
    backgroundColor,
    sourceRectangleRegion,
  }: BuildImgixUrlArgs = {},
) => {
  if (!process.env.REACT_APP_IMGIX_URL) {
    throw new Error('Missing "REACT_APP_IMGIX_URL" from env')
  }

  const params: string[] = []

  if (width) {
    params.push(`w=${width}`)
  }
  if (height) {
    params.push(`h=${height}`)
  }
  if (fit) {
    params.push(`fit=${fit}`)
  }
  if (pixelRatio) {
    params.push(`dpr=${pixelRatio}`)
  }
  if (backgroundColor) {
    params.push(`bg=${backgroundColor}`)
  }
  if (sourceRectangleRegion) {
    params.push(`rect=${sourceRectangleRegion}`)
  }

  // Automatic features (e.g., compression and format) let ImgIX choose the best
  // option based on the device and browser.

  const autoParams = auto || []
  if (autoCompress && !includes(autoParams, 'compress')) {
    autoParams.push('compress')
  }
  if (autoFormat && !includes(autoParams, 'format')) {
    autoParams.push('format')
  }
  params.push(`auto=${autoParams.join(',')}`)

  let url = join(process.env.REACT_APP_IMGIX_URL, path)
  if (params.length) {
    url = `https://${url}?${params.join('&')}`
  }
  return url
}
