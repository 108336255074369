import React, { useState, useMemo } from 'react';
import firebase from 'firebase';
import { useAuthedCollection } from '@humancollective/human-hooks-firebase';

import {
  ProductSpec,
  Inventory,
  ProductMetadata,
} from '@tiary-inc/tiary-shared';
import AddIcon from '@material-ui/icons/Add';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  LinearProgress,
} from '@material-ui/core';
import fileDownload from 'js-file-download';

import { ListTemplate } from '../../lib/templates/ListTemplate';
import { InventoryRowView } from '../../components/Inventory/RowView';
import { AddInventoryTransactionModal } from '../../components/Inventory/AddInventoryTransactionModal';

import { ImportCsv } from '../../lib/components/ImportCsv';
import { AddedRow } from '../../lib/components/CsvCollectionManager/RowAdded';
import { RemovedRow } from '../../lib/components/CsvCollectionManager/RowRemoved';
import { ChangedRow } from '../../lib/components/CsvCollectionManager/RowChanged';

import {
  generateChangeSet,
  executeChangeSet,
  generateCsv,
  expandCsv,
} from '../../lib/components/CsvCollectionManager/utils';
import { Modification, ModificationType } from '../../lib/components/CsvCollectionManager/types';

/**
 * Inventory Page
 * ---
 *
 * This page is for managing inventory of the ready to ship items.
 */

export const InventoryPage: React.FunctionComponent = () => {
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [changes, setChanges] = useState<Modification[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [importProgress, setImportProgress] = useState<number>();
  
  const properties = ['product', 'type', 'fallback', 'count'];

  const inventories = useAuthedCollection<Inventory>({
    getQueryRef: () => firebase.firestore().collection('inventory'),
    includeIds: true,
  });

  const products = useAuthedCollection<ProductMetadata>({
    getQueryRef: () => firebase.firestore().collection('products'),
    includeIds: true,
  });

  const data = useMemo<Inventory & ProductSpec[]>(
    () => inventories?.map((inventory: Inventory) => {
      const spec = products?.find((product: ProductSpec) => product.sku === inventory.product)
      return {
        inventory,
        spec,
      }
    }) || null,
    [inventories, products]
  );

  const onDownload = async () => {
    const csv = await generateCsv(inventories || [], properties);
    fileDownload(csv || '', 'values.csv');
  };

  const onUpload = async (csvObj: any[]) => {
    const newValues = expandCsv(csvObj, properties);
    const nextChanges = await generateChangeSet(
      inventories,
      newValues,
      properties,
    );
    setChanges(nextChanges);
    setShowModal(true);
  };

  const onExecute = async () => {
    setShowModal(false);
    await executeChangeSet({
      changes,
      collectionRef: firebase.firestore().collection('inventory'),
      onProgress: setImportProgress,
    });
    setImportProgress(undefined);
  };

  const renderChange = (change: Modification) => {
    switch (change.type) {
      case ModificationType.Add:
        return (
          <AddedRow
            properties={properties}
            {...change}
          />
        )
      case ModificationType.Remove:
        return (
          <RemovedRow
            properties={properties}
            {...change}
          />
        )
      case ModificationType.Change:
        return (
          <ChangedRow
            properties={properties}
            {...change}
          />
        )
      default:
        return null
    }
  }

  return (
    <>
      {addModalOpen && (
        <AddInventoryTransactionModal
          onClose={() => setAddModalOpen(false)}
          products={products.map((product: ProductSpec) => product.sku)}
        />
      )}
      {!!importProgress && (
        <Box paddingBottom={2}>
          <Typography gutterBottom>{importProgress}% imported</Typography>
          <LinearProgress variant="determinate" value={importProgress} />
        </Box>
      )}
      <ListTemplate
        title="Inventory"
        data={data}
        searchKeys={['product.id']}
        controls={
          <>
            <Button onClick={() => setAddModalOpen(true)} startIcon={<AddIcon />}>
              Add
            </Button>
            <Button onClick={onDownload} startIcon={<ImportExportIcon />}>
              Export
            </Button>
            <Box display="flex" justifyContent="center" alignItems="center">
              <ImportCsv buttonLabel="Import" onSuccess={onUpload}/>
            </Box>
          </>
        }
        renderItem={({
          inventory,
          spec
        }: {
          inventory: Inventory;
          spec: ProductSpec;
        }) => {
          return (
            <InventoryRowView
              key={inventory.product}
              sku={inventory.product}
              product={spec}
              inventory={inventory}
              selected={false}
              onSelect={() => {}}
              error={false}
            />
          )
        }}
      />

      <Dialog open={showModal} onClose={() => setShowModal(false)}>
        <DialogTitle id="simple-dialog-title">Confirm Changes</DialogTitle>
        <DialogContent>
          {changes.length !== 0 ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Change</TableCell>
                  {properties.map((property) => (
                    <TableCell key={`${property}_${property}`}>{property}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {changes.map(renderChange)}
              </TableBody>
            </Table>
          ) : (
            <Typography>This import did not result in any changes.</Typography>
          )}
        </DialogContent>
        <DialogActions>
          {changes.length !== 0 ? (
            <>
              <Button onClick={() => setShowModal(false)}>Close</Button>
              <Button onClick={onExecute} color="primary">
                Execute Changes
              </Button>
            </>
          ) : (
            <Button onClick={() => setShowModal(false)} color="primary">
              Close
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
