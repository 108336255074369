import React from 'react';
import styled from 'styled-components';
import { Field, FieldProps } from 'formik';

import { FormikSelectChip } from '../../../../lib/components/FormikSelectChip';

interface ProductChainValuesFormProps {
  name: string;
}

const StyledProductChainValuesForm = styled.div`
  .product-chain-values-form {
    &__label {
      font-size: 16px;
      margin-top: 44px;
      text-transform: uppercase;
      letter-spacing: 0.6px;
    }
  }
`;

export const ProductGroupedValuesForm: React.FunctionComponent<ProductChainValuesFormProps> = ({
  name,
}) => {
  return (
    <Field name={name}>
      {({ form }: FieldProps) => {
        const valuesToUse = form.values.options.map((value) => value.name);

        return (
          <StyledProductChainValuesForm>
            <h2 className="product-grouped-values-form__label">Values</h2>
            <FormikSelectChip
              name={name}
              options={valuesToUse.map((name) => ({
                label: name,
                value: name,
              }))}
              multiple
              fullWidth
            />
          </StyledProductChainValuesForm>
        );
      }}
    </Field>
  );
};
