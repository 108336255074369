import React, { FunctionComponent } from 'react'
import {
  useAuthedCollection,
  UseAuthedCollectionOptions,
} from '@humancollective/human-hooks-firebase'

import {
  ListTemplate,
  SharedListTemplateProps,
  SearchableTemplate,
} from './index'

type CombinedProps = SharedListTemplateProps &
  UseAuthedCollectionOptions &
  SearchableTemplate

export const CollectionListTemplate: FunctionComponent<CombinedProps> = (
  props,
) => {
  const data = useAuthedCollection({ ...props, includeIds: true })
  // const dataWithoutId = useAuthedCollection({ ...props, includeIds: false })
  return <ListTemplate data={data} {...props} />
}
