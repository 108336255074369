import React from 'react'
import { Field, FieldProps } from 'formik'
import { FormControlProps } from '@material-ui/core'
import get from 'lodash/get'
import { SelectChip } from '../SelectChip'

interface FormikSelectChipOption {
  value: string
  label?: string
}

interface FormikSelectChipProps {
  name: string
  options: FormikSelectChipOption[]
  label?: string
  type?: string
  placeholder?: string
  required?: boolean
  helperText?: string
  multiple?: boolean
}

export const FormikSelectChip: React.FunctionComponent<
  FormikSelectChipProps & FormControlProps
> = ({ name, options, label, className, helperText, multiple, ...rest }) => (
  <Field>
    {({ form }: FieldProps) => {
      const error = form.errors[name] as string | undefined
      return (
        <SelectChip
          className={className}
          label={label}
          value={get(form.values, name) || []}
          onChangeValues={(values) => form.setFieldValue(name, values)}
          options={options}
          multiple={multiple}
          helperText={form.touched && error ? error : helperText}
          {...rest}
        />
      )
    }}
  </Field>
)
