import * as React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Insight } from '@tiary-inc/tiary-shared';
import { subDays, startOfDay, endOfDay } from 'date-fns';

import Loading from '../../lib/components/Loading';
import { TemplateMeta } from '../../lib/components/Template/TemplateMeta';
import SummaryCard from '../../components/Insights/SummaryCard';
import InsightDatePicker from '../../components/Insights/DatePicker';
import SalesOverviewCard from '../../components/Insights/SalesOverviewCard';
import PopularProductsCard from '../../components/Insights/PopularProductsCard';
import PopularConfigurationsCard from '../../components/Insights/PopularConfigurationsCard';

import { fetchInsights } from '../../utils/firebase/fetchInsights';
/**
 * Insights Page
 * ---
 *
 * This page displays a list of insights (analytics)
 */

const getInitialDateRange = (): { startDate: Date; endDate: Date } => {
  const now = new Date();
  // The start date should be a week ago
  const startDate = subDays(now, 7);
  // set the end date to yesterday
  const endDate = subDays(now, 1);

  return { startDate, endDate };
};

export const InsightsPage: React.FunctionComponent = () => {
  const [insights, setInsights] = React.useState<Insight[]>([]);
  const [dateRange, setDateRange] = React.useState(getInitialDateRange());
  const [isLoading, showLoading] = React.useState(false);

  const loadInsights = async (): Promise<void> => {
    showLoading(true);
    try {
      const insightsToAdd: Insight[] = await fetchInsights({
        startDate: startOfDay(dateRange.startDate),
        endDate: endOfDay(dateRange.endDate),
      });

      setInsights(insightsToAdd);
    } catch (error) {
      console.log('Error loading insights', error);
    }
    showLoading(false);
  };

  React.useEffect(() => {
    loadInsights();
    // eslint-disable-next-line
  }, [dateRange]);

  if (isLoading) {
    return <Loading label="Loading insights..." />;
  }

  return (
    <Box paddingX={3}>
      <TemplateMeta
        title="Insights"
        breadcrumbs={[{ label: 'Home', to: '/' }, { label: 'Insights' }]}
      />
      <InsightDatePicker
        {...dateRange}
        onStartDateChanged={(value) =>
          setDateRange((prevState) => ({
            ...prevState,
            startDate: value,
          }))
        }
        onEndDateChanged={(value) =>
          setDateRange((prevState) => ({
            ...prevState,
            endDate: value,
          }))
        }
      />
      <Grid container={true} spacing={2}>
        <Grid item={true}>
          <SummaryCard insights={insights} />
        </Grid>
        <Grid item={true}>
          <SalesOverviewCard insights={insights} />
        </Grid>
        <Grid item={true}>
          <PopularProductsCard insights={insights} />
        </Grid>
        <Grid item={true}>
          <PopularConfigurationsCard insights={insights} />
        </Grid>
      </Grid>
    </Box>
  );
};
