import React from 'react'
import { Field, FieldProps } from 'formik'
import { FormControlProps, FormControl, Typography } from '@material-ui/core'
import get from 'lodash/get'

import { buildImgixUrl } from '../../utils/imgix'
import ImageUploader from './index'

interface FormikImageUploaderProps {
  name: string
  label?: string
  type?: string
  required?: boolean
}

export const FormikImageUploader: React.FunctionComponent<
  FormikImageUploaderProps & FormControlProps
> = ({ name, type = 'text', label, className, ...rest }) => (
  <div className={className}>
    <Field name={name}>
      {({ form }: FieldProps) => (
        <FormControl {...rest} style={{ width: '100%' }}>
          <Typography>{label}</Typography>
          <img
            alt="upload result"
            src={buildImgixUrl(get(form.values, name) || '', { width: 500 })}
            style={{ width: 250 }}
          />
          <ImageUploader
            onUploadSuccess={(filePath) => {
              form.setFieldValue(name, filePath)
            }}
          />
        </FormControl>
      )}
    </Field>
  </div>
)
