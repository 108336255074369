import React, { FunctionComponent } from 'react'
import { Button } from '@material-ui/core'

export const EditorFormSaveButtonControl: FunctionComponent = () => (
  <Button
    color="primary"
    variant="contained"
    disableElevation
    size="large"
    type="submit"
  >
    Save Changes
  </Button>
)
