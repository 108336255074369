import React, { useState, useContext, useMemo } from 'react'
import { useFormikContext } from 'formik'
import {
  FindingMaterial,
  Manufacturer,
  DashboardUtilsBag,
} from '@tiary-inc/tiary-shared'

import Loading from '../../../../lib/components/Loading'
import { ManufacturerFindingsModal } from './Modal'
import { LocaleContext } from '../../../../contexts/Locale'
import { MaterialsContext } from '../../../../contexts/Materials'
import { PricingContext } from '../../../../contexts/Pricing'
import { StyledManufacturerFindings } from './style'
import { groupFindings } from './utils'
import { getMissingFindingValues } from '../../../../utils/validateManufacturer'
import { ManufacturerFindingRow } from './Row'

interface ManufacturerFindingsProps {
  name: string
}

export const ManufacturerFindings: React.FunctionComponent<ManufacturerFindingsProps> = ({
  name,
}) => {
  const { values } = useFormikContext()
  const { localize } = useContext(LocaleContext)
  const { pricing } = useContext(PricingContext)
  const { findingMaterials, materials, groupings } = useContext(
    MaterialsContext,
  )
  const bag: DashboardUtilsBag = { materials, groupings, localize, pricing }

  const [modalValues, setModalValues] = useState<FindingMaterial[]>()

  const findings = useMemo(() => groupFindings(findingMaterials), [
    findingMaterials,
  ])

  const missingFindings = useMemo(
    () => groupFindings(getMissingFindingValues(values as Manufacturer, bag)),
    [values, bag],
  )

  return findings ? (
    <>
      {modalValues && (
        <ManufacturerFindingsModal
          name={name}
          onClose={() => setModalValues(undefined)}
          values={modalValues}
        />
      )}
      <StyledManufacturerFindings>
        {findings.map(({ finding, materials }) => (
          <ManufacturerFindingRow
            key={finding}
            label={localize(finding)}
            onEdit={() => setModalValues(materials)}
            missingCount={
              missingFindings.find((f) => f.finding === finding)?.materials
                .length || 0
            }
          />
        ))}
      </StyledManufacturerFindings>
    </>
  ) : (
    <Loading />
  )
}
