import { Order, OrderProduct } from '@tiary-inc/tiary-shared';
import React from 'react';
import OrderProductCard from '../OrderProductCard';
import { Typography } from '@material-ui/core';

interface UnassignedManufacturerProductProps {
  order: Order;
  product: OrderProduct;
}
export const UnassignedManufacturerProduct: React.FunctionComponent<UnassignedManufacturerProductProps> = ({
  order,
  product,
}) => (
  <OrderProductCard
    product={product}
    extraContent={
      <>
        <Typography>No manufacturer assigned.</Typography>
      </>
    }
  />
);
