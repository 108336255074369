import React from 'react'
import { FormControlProps } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import FormHelperText from '@material-ui/core/FormHelperText'
import Select from '@material-ui/core/Select'
import Chip from '@material-ui/core/Chip'
import MenuItem from '@material-ui/core/MenuItem'
import Box from '@material-ui/core/Box'

interface SelectChipOption {
  value: string
  label?: string
}

interface SelectChipProps {
  options: SelectChipOption[]
  onChangeValues: (values: string[]) => void
  value?: string[]
  label?: string
  type?: string
  placeholder?: string
  required?: boolean
  helperText?: string
  multiple?: boolean
}

export const SelectChip: React.FunctionComponent<
  SelectChipProps & FormControlProps
> = ({
  options,
  value = [],
  onChangeValues,
  label,
  className,
  helperText,
  multiple,
  ...rest
}) => {
  const SelectValue = ({ selected }: { selected: string[] }) => (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {selected.map((value) => {
        const label = options.find((o) => o.value === value)?.label || value
        return (
          <Box margin={0.5} key={value}>
            <Chip label={label} size="small" color="primary" />
          </Box>
        )
      })}
    </div>
  )
  return (
    <div className={className}>
      <FormControl {...rest}>
        {label && <InputLabel>{label}</InputLabel>}
        <Select
          multiple={multiple}
          value={value}
          onChange={(e) => onChangeValues(e.target.value as string[])}
          input={<Input />}
          renderValue={(value: any) => <SelectValue selected={value} />}
        >
          {options.map(({ value, label: itemLabel }) => (
            <MenuItem key={value} value={value}>
              {itemLabel || value}
            </MenuItem>
          ))}
        </Select>
        {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
      </FormControl>
    </div>
  )
}
