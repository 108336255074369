import { Order, OrderProduct } from '@tiary-inc/tiary-shared';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import OrderProductCard from '../OrderProductCard';

interface MasterCastingManufacturerProps {
  order: Order;
  product: OrderProduct;
}

export const MasterCastingManufacturerProduct: React.FunctionComponent<MasterCastingManufacturerProps> = ({
  order,
  product,
}) =>
  order._MasterCasting ? (
    <OrderProductCard
      product={product}
      extraContent={
        <>
          {order._MasterCasting &&
          order._MasterCasting.jobs &&
          order._MasterCasting.jobs[product.id] ? (
            <div>Job Id: {order._MasterCasting.jobs[product.id]}</div>
          ) : (
            <Typography>
              <strong>Submission Failed</strong>
              <br />
              We didn't receive a response when submitting this product. We'll
              try again within the next hour.
            </Typography>
          )}
        </>
      }
      extraActions={
        <>
          <Button
            color="primary"
            href={`https://www.mastercastingandcad.com/mastercasting_backend/projects/project_details/${
              order._MasterCasting.jobs[product.id]
            }`}
            target="_blank"
            rel="noopener noreferrer"
          >
            View on MCC
          </Button>
        </>
      }
    />
  ) : (
    <OrderProductCard
      product={product}
      extraContent={
        <>
          <Typography>
            <strong>No job ID</strong>
          </Typography>
        </>
      }
    />
  );
