import React from 'react';
import { CardProps } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import styled from 'styled-components';
import { Deposit } from '@tiary-inc/tiary-shared';

const StyledDepositRowView = styled(TableRow)`
  cursor: pointer;

  .product-row-view {
    &__renderer {
      margin-right: 24px;
    }
  }
`;

interface DepositRowViewProps {
  deposit: Deposit & { id: string };
}

export const DepositRowView: React.FunctionComponent<
  DepositRowViewProps & CardProps
> = ({ deposit }) => {
  return (
    <StyledDepositRowView hover>
      <TableCell>
        <ListItem>
          <ListItemText
            primary={deposit?.code}
            secondary={deposit.description}
          />
        </ListItem>
      </TableCell>
      <TableCell>
        <ListItem>
          <ListItemText
            primary={`$${deposit.consumed || 0}/$${deposit.total}`}
          />
        </ListItem>
      </TableCell>
    </StyledDepositRowView>
  );
};
