import React from 'react';
import firebase from 'firebase';
import { sortBy, groupBy } from 'lodash';
import { timestampToDate, format, dateToTimestamp } from 'fastis';

import { OrderRowView } from '../../components/Order/RowView';
import { CollectionListTemplate } from '../../lib/templates/ListTemplate/CollectionList';
import { ListTemplateSection } from '../../lib/templates/ListTemplate';
import { DownloadOrdersButton } from '../../components/Order/DownloadOrders';
import { Order } from '@tiary-inc/tiary-shared';
import { OrderRowSection } from '../../components/Order/RowSection';
import { CreateInvoiceModal } from '../../components/Order/modals/CreateInvoice';

/**
 * Orders Page
 * ---
 *
 * This page displays a list of orders that are available for viewing.
 *
 * Orders are generated during checkout by the tiary-functions project when a
 * consumer purchases a set of products.
 */

export const OrdersPage: React.FunctionComponent = () => (
  <CollectionListTemplate
    title="Orders"
    renderItem={(order: Order) => (
      <OrderRowView key={order.id} order={order} onSelect={() => {}} />
    )}
    section={{
      sort: (items) => {
        const sortedOrders = sortBy(items, (orderItem) => {
          return timestampToDate(orderItem.dateCreated);
        });

        const groupedOrders = groupBy(sortedOrders, (item) => {
          const orderDateCreatedDate = timestampToDate(item.dateCreated);
          orderDateCreatedDate.setHours(0, 0, 0, 0);
          return format({
            timestamp: dateToTimestamp(orderDateCreatedDate),
            format: `MMM do yyyy`,
          });
        });

        const sectionedItems: ListTemplateSection[] = [];

        Object.keys(groupedOrders).forEach((key) => {
          const itemsFromKey = groupedOrders[key];
          // Sort the section orders by date, then reverse it so the newest orders are first
          const sortedItems = sortBy(itemsFromKey, (orderItem) => {
            return timestampToDate(orderItem.dateCreated);
          }).reverse();
          const ordersTotalled: number = sortedItems.reduce(
            (prevValue, currentValue) =>
              (prevValue += currentValue.pricing.total),
            0
          );
          sectionedItems.push({
            title: `${key} - $${ordersTotalled.toFixed(2)} USD`,
            items: sortedItems,
          });
        });

        return sectionedItems.reverse();
      },
      renderSection: (listSection: ListTemplateSection) => (
        <React.Fragment key={listSection.title}>
          <OrderRowSection>{listSection.title}</OrderRowSection>
          {listSection.items.map((sectionOrderItem) => (
            <OrderRowView
              key={sectionOrderItem.id}
              order={sectionOrderItem}
              onSelect={() => {}}
            />
          ))}
        </React.Fragment>
      ),
    }}
    controls={
      <>
        <DownloadOrdersButton />
        <CreateInvoiceModal />
      </>
    }
    searchKeys={[
      'id',
      'billingAddress.firstName',
      'billingAddress.lastName',
      'shippingAddress.firstName',
      'shippingAddress.lastName',
      'email',
      'jobIds',
      'status',
    ]}
    getQueryRef={() =>
      firebase.firestore().collection('orders').orderBy('dateCreated', 'desc')
    }
    transformValue={(order) => ({
      ...order,
      jobIds: Object.values(order._MasterCasting?.jobs || {}).join(''),
    })}
    includeIds
  />
);
