
import * as React from 'react'
import styled from 'styled-components'
import { useSearch } from '../../lib/hooks/useSearch';
import { TemplateSearchBar } from '../../lib/components/Template/SearchBar';

const Container = styled.div`
  flex: 1
`

interface SearchableProducts {
  data: any[];
  searchKeys: string[];
  renderItem: (item: any) => React.ReactNode;
}

export const SearchableProducts: React.FC<SearchableProducts> = ({data, searchKeys, renderItem}) => {
  //prepare the data for search

  const search = useSearch({
    data,
    keys: searchKeys || []
  })

  const MAX_ITEMS = 10;

  const renderProducts = () => {
    return search.results.slice(0, MAX_ITEMS).map(renderItem)
  }
  
  return(
    <Container>
      {search.isReady && 
        <TemplateSearchBar                className="search" 
          query={search.query}
          setQuery={search.setQuery}
        />
      }
      {renderProducts()}
    </Container>
  )

}