import React from 'react'
import { CardProps } from '@material-ui/core'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

interface SalesItem {
  id: string,
  percentage?: number;
  sku?: string;
  stack?: boolean;
  code?: string;
}

const StyledSalesRowView = styled(TableRow)`
  cursor: pointer;

  .product-row-view {
    &__renderer {
      margin-right: 24px;
    }
  }
`

interface SaleRowViewProps {
  sale: SalesItem & { id: string }
}

export const SaleRowView: React.FunctionComponent<
  SaleRowViewProps & CardProps
> = ({ sale }) => {
  const history = useHistory()
  return (
    <StyledSalesRowView
      hover
      onClick={() => history.push(`/sales/edit/${sale.id}`)}
    >
      <TableCell>
        <ListItem>
          <ListItemText
            primary={sale?.code}
            secondary={sale?.code}
          />
        </ListItem>
      </TableCell>
    </StyledSalesRowView>
  )
}
