import React from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

const StyledEmptyState = styled(Box)`
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.03);
  text-align: center;
  color: rgba(0, 0, 0, 0.4);
`

interface EmptyStateProps {
  label: string
}

export const EmptyState: React.FunctionComponent<EmptyStateProps> = ({
  label,
}) => (
  <StyledEmptyState padding={3}>
    <Typography variant="body1">{label}</Typography>
  </StyledEmptyState>
)
