import React, { useContext } from 'react'
import uniqWith from 'lodash/uniqWith'
import isEqual from 'lodash/isEqual'
import sortBy from 'lodash/sortBy'
import styled from 'styled-components'

import { FormikInput } from '../../../../lib/components/FormikInput'
import Loading from '../../../../lib/components/Loading'
import { LocaleContext } from '../../../../contexts/Locale'
import { MaterialsContext } from '../../../../contexts/Materials'

interface ProductMetalWeightsFormProps {
  name: string
}

const StyledProductMetalWeightsForm = styled.div`
  .product-metal-weights-form {
    &__label {
      font-size: 16px;
      margin-top: 44px;
      text-transform: uppercase;
      letter-spacing: 0.6px;
    }
    &__inputs {
      display: flex;
      &__input {
        margin-right: 12px;
      }
    }
  }
`

export const ProductMetalWeightsForm: React.FunctionComponent<ProductMetalWeightsFormProps> = ({
  name,
}) => {
  const { localize } = useContext(LocaleContext)
  const { metalMaterials } = useContext(MaterialsContext)

  const metalQualities =
    sortBy(
      uniqWith(
        metalMaterials?.map(({ quality, metal }) => ({ quality, metal })),
        isEqual,
      ),
      'quality',
    ) || []

  return metalMaterials ? (
    <StyledProductMetalWeightsForm>
      <h2 className="product-metal-weights-form__label">Weights</h2>
      <div className="product-metal-weights-form__inputs">
        {metalQualities.map(({ quality, metal }) => {
          const path = `${name}.${metal}.${quality}`
          const description = `${localize(quality)} ${localize(metal)}`
          return (
            <FormikInput
              className="product-metal-weights-form__inputs__input"
              key={path}
              name={path}
              label={description}
              type="number"
              helperText={`The weight of ${description} (dwt).`}
              fullWidth
            />
          )
        })}
      </div>
    </StyledProductMetalWeightsForm>
  ) : (
    <Loading />
  )
}
