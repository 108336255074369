import styled from 'styled-components'
import Modal from '@material-ui/core/Modal'

export const StyledManufacturerFindings = styled.div`
  .manufacturer-findings {
    &__finding {
      display: flex;
      align-items: center;
      padding: 4px 12px;

      &:nth-of-type(2n) {
        background-color: #fafafa;
      }

      &__label {
        flex: 1;
      }
    }
    &__finding-length {
      display: flex;
      align-items: center;
      padding: 8px 12px;

      &:nth-of-type(2n) {
        background-color: #fafafa;
      }

      &__label {
        flex: 1;
      }
    }
  }
`

export const StyledManufacturerFindingsModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;

  .finding-cost-modal {
    &__wrapper {
      max-width: 70%;
      width: 600px;
      padding: 48px;
      background: white;
    }
  }
`
