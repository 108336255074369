import React,{useState,useRef} from "react"

interface EditableTextFieldProps {
    inputName:string;
    defaultValue:any;
    onEdited?:any;
    helpText?:string;
    disabled?:boolean;
    noButton?:boolean;
}
const EditableTextField:React.FC<EditableTextFieldProps> = (
    {
        inputName,
        defaultValue,
        onEdited,
        helpText,
        disabled, 
        noButton,
    }) => {

    const [editMode,setEditMode] = useState(false);

    // const [currentValue,setCurrentValue]=useState(defaultValue); //initialize it to current value
    const currentTextField = useRef<HTMLInputElement | null>(null);
    
    if(defaultValue === null){
        defaultValue = 'entry not in database'
    }
    
    const changeValue=()=>{
    //since currentTextField starts with null, we want to edit value only when its not null
        if (currentTextField.current) {
        //some editable textfields dont have an onEdit function. if it exists, only run then.
            if(onEdited){

                onEdited(currentTextField.current.value);
            }
        }
    }

    const handleClick=()=>{
        
        if(editMode){
            changeValue()
        }
        
        setEditMode(!editMode);
       
    }

    return (
    <div className="EditableTextField">
        
        <div className="textFieldContainer">
            <div className="textFieldHeader">{inputName}</div>
            <div className="inlineTextField">
                {   editMode? 
                    <input name={inputName} placeholder={defaultValue} ref={currentTextField}/>
                    :<label >{defaultValue}</label>
                }
                    {noButton ? 
                        <></> 
                        : <button type="button" disabled={disabled} onClick={handleClick}>
                            {editMode ?
                                "OK"
                                : "Edit"
                            }
                        </button>}
            </div>
        </div>
       
    </div>
  )
}

export default EditableTextField